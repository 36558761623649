//import WeekListArea from "@/pages/comp/edu/month_prescribe/common/week_list";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import LygCalendarFunc from '@/lib/lyg/calendar';
import { default_info } from "@/pages/comp/edu/month_prescribe/common/data/default_data";
import { useSelector, useDispatch } from 'react-redux';
import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import LoadingComponent from "@/pcomponents/common/content/loading";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import ViewPopupContent from '@/pages/comp/edu/month_prescribe/popup/view_content';
import FindPrescribePopup from "@/pages/comp/edu/prescribe/prescribe_check/popup/find";
import TdContentArea from "./td_content";

function WeekListArea(props: any) {
  let myProps = {
    stu_info: null,
    last_daily_report_arr: [],
    last_mprescribe_arr: [],
    info_arr: [],
    set_info_arr: [],
    list: (inData: any) => { },
    set_refresh: () => { },
    select_date: DateFunc.get_date_format(new Date(), "Y-m-01"),
    is_view_mode: false,
    subject_name: "",
    comment_list: [],
    refresh_comment: (inData: any) => { },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  let select_date = myProps.select_date;
  let cal_data: any = LygCalendarFunc.get_cal_data_by_select_date(select_date);
  cal_data.select_month_week_len = Math.ceil((cal_data.select_month_json.first_day_of_week + cal_data.select_month_json.last_day) / 7);

  const [week_info_arr, set_week_info_arr] = useState<any>({});//{0:[],1:[]...}
  const [is_view_mode, set_is_view_mode] = useState(myProps.is_view_mode);
  const [loading_data, set_loading_data] = useState({
    is_display: false,
    text: "저장 되었습니다.",
  });

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "view",//view
    isUpdate: false,
    rowData: {},
    row_num: 0,
    has_header: true,
    title: "팝업",
    width: "800px",
    height: "80%",
  });

  const get_info_arr_by_subject_name = (in_subject_name: string) => {
    if (strFunc.is_empty(in_subject_name)) {
      return [];
    }
    let info_arr_of_subject = [];
    for (let i = 0; i < myProps.info_arr.length; i++) {
      let info = myProps.info_arr[i];
      if (info["a_subject_name"] == in_subject_name) {
        info_arr_of_subject.push(info);
      }
    }
    return info_arr_of_subject;
  };
  let info_arr_of_subject = get_info_arr_by_subject_name(myProps.subject_name);

  const go_write_week_info_arr_by_ajax = (inData: any) => {
    let opt_obj = {
      write_data_arr: [],
      ...inData
    };
    let write_data_arr = opt_obj.write_data_arr;
    if (write_data_arr.length == 0) {
      for (let week_i in week_info_arr) {
        for (let i = 0; i < week_info_arr[week_i].length; i++) {
          write_data_arr.push(week_info_arr[week_i][i]);
        }
      }
    }
    if (write_data_arr.length == 0) {
      alert("데이터 선택이 없습니다.");
      return false;
    }
    // if(!confirm("저장 하시겠습니까?")){
    //   return false;
    // }
    let write_form_json: any = {
      "data_arr": write_data_arr,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/month_prescribe/write', write_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_loading_data({
            is_display: true,
            text: "저장 되었습니다.",
          });
          setTimeout(() => {
            set_loading_data({
              ...loading_data,
              is_display: false,
            });
          }, 1200);
          // set_is_view_mode(true);
          myProps.list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const delete_week_info_by_ajax = (inData: any) => {
    let opt_obj = {
      data_arr: [],
      ...inData
    };

    let del_data_arr = opt_obj["data_arr"];
    for (let i = 0; i < del_data_arr.length; i++) {
      let del_data_row = del_data_arr[i];
      if (strFunc.is_empty(del_data_row["a_seq"])) {
        alert("데이터 키가 없습니다.");
        return false;
      }
    }
    if (del_data_arr.length == 0) {
      alert("데이터가 없습니다.");
      return false;
    }

    if (!confirm("삭제 하시겠습니까?")) {
      return false;
    }
    let write_form_json: any = {
      "data_arr": del_data_arr,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/month_prescribe/delete', write_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_loading_data({
            is_display: true,
            text: "삭제 되었습니다.",
          });
          setTimeout(() => {
            set_loading_data({
              ...loading_data,
              is_display: false,
            });
          }, 1200);
          myProps.list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  let td_data_arr = LygCalendarFunc.get_td_data_arr({
    cal_data: cal_data
  });
  let td_data_arr_len = td_data_arr.length;

  const get_new_month_prescribe_info = (inData: any) => {
    let opt_obj = {
      select_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
      ...inData,
    };
    let new_info = {
      ...default_info,
      a_ymd: DateFunc.get_date_format(new Date(opt_obj.select_date), "Ymd"),
      a_seq: "",
      a_reserve_date: DateFunc.get_date_format(new Date(opt_obj.select_date), "Y-m-d"),
      a_is_write: "1",
      a_write_date: DateFunc.get_date_format(new Date(opt_obj.select_date), "Y-m-d"),
      a_stu_seq: myProps.stu_info.a_seq,
      a_stu_name: myProps.stu_info.a_user_name,
      a_writer_seq: user.user_seq,
      a_writer_name: user.user_name,
      a_subject_name: myProps.subject_name,
      a_progress: "",
      a_create_seq: user.user_seq,
      a_create_date: DateFunc.get_date_format(new Date(), "Y-m-d h:i:s"),
    };
    if (myProps.last_mprescribe_arr && myProps.last_mprescribe_arr.length > 0) {
      for (let i = 0; i < myProps.last_mprescribe_arr.length; i++) {
        let last_mprescribe_info = myProps.last_mprescribe_arr[i];
        if (last_mprescribe_info["a_subject_name"] == new_info["a_subject_name"]
          && last_mprescribe_info["a_writer_seq"] == new_info["a_writer_seq"]) {
          new_info["a_progress_reason"] = last_mprescribe_info["a_progress_reason"];
          new_info["a_progress"] = last_mprescribe_info["a_progress"];
        }
      }
    }
    if (myProps.last_daily_report_arr && myProps.last_daily_report_arr.length > 0) {
      for (let i = 0; i < myProps.last_daily_report_arr.length; i++) {
        let last_report_info = myProps.last_daily_report_arr[i];
        if (last_report_info["a_subject"] == new_info["a_subject_name"]) {
          new_info["a_progress"] = last_report_info["a_textbook"]+" "+last_report_info["a_textbook_study"];
        }
      }
    }

    return new_info;
  };
  let week_td_arr = LygCalendarFunc.get_td_week_data_arr({
    cal_data: cal_data,
    td_data_arr: td_data_arr,
    standard_day_of_week: 1
  });

  const get_week_info_arr_data = () => {
    let info_arr_len = info_arr_of_subject.length;
    let week_info_arr_data: any = {};

    for (let week_i = 0; week_i < week_td_arr.length; week_i++) {
      let week_td = week_td_arr[week_i];
      week_info_arr_data[week_i] = [];
      for (let td_i = 0; td_i < week_td["row_td_data_arr"].length; td_i++) {
        let td_data = week_td["row_td_data_arr"][td_i];
        for (let i = 0; i < info_arr_len; i++) {
          let info = info_arr_of_subject[i];
          if (td_data["date_str"] == info["a_reserve_date"]) {
            week_info_arr_data[week_i].push(info);
          }
        }
      }
    }

    for (let week_i = 0; week_i < week_td_arr.length; week_i++) {
      let week_td = week_td_arr[week_i];

      //기본 1개는 추가.
      if (week_info_arr_data[week_i] != undefined) {
        if (week_info_arr_data[week_i].length == 0) {
          //오늘날짜가 포함이라면 오늘 날짜로 추가.
          let start_date_num = parseInt(strFunc.getNumber2(week_td["start_date"]));
          let end_date_num = parseInt(strFunc.getNumber2(week_td["end_date"]));
          let now_date_num = parseInt(strFunc.getNumber2(cal_data.now_date_str));
          let select_date_str = week_td["start_date"];
          if (start_date_num <= now_date_num && now_date_num <= end_date_num) {
            select_date_str = cal_data.now_date_str;
          } else {
            if (week_td["start_day_of_week"] == 0) {
              //월요일은피하자.
              select_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(select_date_str), 'day', +1), "Y-m-d");
            }
          }
          let add_new_info = get_new_month_prescribe_info({
            select_date: select_date_str
          });
          week_info_arr_data[week_i].push(add_new_info);
        }
      }
    }

    return week_info_arr_data;
  };

  useEffect(() => {
    let week_info_arr_data = get_week_info_arr_data();
    set_week_info_arr(week_info_arr_data);
  }, [myProps.info_arr]);

  const get_week_colgroup_tags = () => {
    let col_per_num = 100;
    if (week_td_arr.length > 0) {
      col_per_num = Math.floor(100 / week_td_arr.length);
    }
    let colgroup_tags = week_td_arr.map((item: any, idx: number) => {
      return (
        <col key={idx} width={col_per_num} />
      );
    });

    return colgroup_tags;
  };

  const on_change_week_info = (inData: any) => {
    let opt_obj = {
      name: "",
      value: "",
      week_idx: 0,
      ...inData,
    };
    let name = opt_obj.name;
    let value = opt_obj.value;
    let change_week_info_arr = { ...week_info_arr };
    if (change_week_info_arr[opt_obj.week_idx] == undefined) {
      return false;
    }
    if (change_week_info_arr[opt_obj.week_idx].length == 0) {
      return false;
    }
    if (name == "a_content" && !strFunc.is_empty(value) && change_week_info_arr[opt_obj.week_idx][0]["a_is_success"] != "1") {
      change_week_info_arr[opt_obj.week_idx][0]["a_is_success"] = "1";
      change_week_info_arr[opt_obj.week_idx][0]["a_success_date"] = DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
      change_week_info_arr[opt_obj.week_idx][0]["a_success_writer_seq"] = user.user_seq;
      change_week_info_arr[opt_obj.week_idx][0]["a_success_writer_name"] = user.user_name;
    }
    if (name == "a_is_success" && value == "1") {
      if (change_week_info_arr[opt_obj.week_idx][0][name] != "1") {
        change_week_info_arr[opt_obj.week_idx][0]["a_success_date"] = DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
        change_week_info_arr[opt_obj.week_idx][0]["a_success_writer_seq"] = user.user_seq;
        change_week_info_arr[opt_obj.week_idx][0]["a_success_writer_name"] = user.user_name;
      }
    }
    if (name == "a_subject_name") {
      update_subject_name_of_writer_by_ajax({
        subject_name: value
      });
    }
    change_week_info_arr[opt_obj.week_idx][0][name] = value;

    set_week_info_arr(change_week_info_arr);
  };

  const update_subject_name_of_writer_by_ajax = (inData: any) => {
    let opt_obj = {
      subject_name: "",
      ...inData
    };
    if (strFunc.is_empty(opt_obj["subject_name"])) {
      return false;
    }
    if (strFunc.is_empty(user.user_seq)) {
      return false;
    }
    let up_row_data = {
      a_seq: user.user_seq,
      a_main_subject: opt_obj["subject_name"],
    };
    let formJsonData = {
      "data_arr": [up_row_data],
      "is_update": "1"
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/write', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {

        } else {

        }
      });
  };

  const openOnePopup = (inData: any) => {
    let opt_obj = {
      sort: "view",
      isUpdate: false,
      rowData: {},
      week_i: "",
      ...inData
    };
    let isUpdate = opt_obj["isUpdate"];
    let sort = opt_obj["sort"];
    let pop_title = "등록";
    let tmp_rowData = opt_obj["rowData"];
    if (sort == "view") {
      pop_title = "보기";
    }
    tmp_rowData.month_num = cal_data.select_month_json.m;
    tmp_rowData.week_i = opt_obj.week_i;
    setPopupData({
      ...popupData,
      "isOpen": true,
      "isUpdate": isUpdate,
      "rowData": tmp_rowData,
      "title": pop_title,
      "sort": sort,
      width: "800px",
      height: "80%",
    });
  };

  const openFindPrescribePopup = (inData: any) => {
    let opt_obj = {
      sort: "find_pres",
      row_num: 0,
      ...inData,
    };
    let pop_title = "찾기";
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": pop_title,
      "sort": opt_obj.sort,
      row_num: opt_obj.row_num,
      width: "800px",
      height: "80%",
    });
  };

  const callBackDataFindPrescribe = (inData: any) => {
    let opt_obj = {
      cate_info: null,
      info_arr: [],
      select_check_idx: 0,
      select_cate_idx: 0,
      row_num: 0,
      key_str: "",
      pri_val: "",
      ...inData,
    };
    if (opt_obj["cate_info"] == null) {
      return false;
    }
    let week_info: any = null;
    let row_num = opt_obj["row_num"];
    if (week_td_arr[row_num] && week_info_arr[row_num]) {
      week_info = week_info_arr[row_num];
    }
    if (week_info == null) {
      return false;
    }
    let check_info_arr = opt_obj["info_arr"];
    if (check_info_arr.length == 0) {
      return false;
    }
    let check_info = check_info_arr[0];
    let value_str = (opt_obj["select_cate_idx"] + 1) + ") " + opt_obj["cate_info"]["a_title"];
    value_str += "-" + check_info["a_title"];//(opt_obj["select_check_idx"]+1)
    on_change_week_info({
      name: "a_grow",
      value: value_str,
      week_idx: row_num,
    });
  };

  const get_week_tr_td_tags = (inData: any) => {
    let opt_obj = {
      name: "",
      ...inData
    };
    let week_td_tags = week_td_arr.map((item: any, idx: number) => {
      let week_info: any = {};
      if (week_info_arr[idx] != undefined && week_info_arr[idx].length > 0) {
        week_info = week_info_arr[idx][0];
      }
      return (
        <td key={idx} className="text-center" style={{ border: "1px solid #ccc", verticalAlign: "top" }}>
          {opt_obj["name"] == "" &&
            <div>
              {item.idx + 1} 주차
              <span className="ml-1" style={{ color: "gray" }}>
                ({item.start_date.substring(8, 10)}일
                ~)
                {/* {item.end_date.substring(8, 10)}일) */}
              </span>
              {is_view_mode == false &&
                <button className="btn-s btn-sky ml-1" onClick={() => { go_write_week_info_arr_by_ajax({ write_data_arr: [week_info] }); }}>저장</button>
              }
              {(is_view_mode == false && !strFunc.is_empty(week_info["a_seq"])) &&
                <button className="btn-s btn-red ml-1" onClick={() => { delete_week_info_by_ajax({ data_arr: [week_info] }); }}>삭제</button>
              }

              <button className="btn-s btn-dark ml-1" onClick={() => { set_is_view_mode(!is_view_mode); }}>
                {is_view_mode ? "수정모드" : "보기모드"}
              </button>

              {(is_view_mode && !strFunc.is_empty(week_info["a_seq"])) &&
                <button className="btn-s btn-dark ml-1"
                  onClick={() => { openOnePopup({ sort: "view", rowData: week_info, week_i: item.idx }); }} >복사</button>
              }

              {!strFunc.is_empty(week_info.a_seq) &&
                <div>
                  작성: {week_info.a_writer_name}
                </div>
              }
            </div>
          }
          {opt_obj["name"] != "" &&
            <TdContentArea
              name={opt_obj["name"]}
              week_info={week_info}
              week_idx={idx}
              on_change_week_info={on_change_week_info}
              is_view_mode={is_view_mode}
              openFindPrescribePopup={openFindPrescribePopup}
              comment_list={myProps.comment_list}
              refresh_comment={(inData:any)=>{
                myProps.refresh_comment(inData);
                myProps.list();
              }}
            ></TdContentArea>
          }
        </td>
      );
    });

    return week_td_tags;
  };

  return (
    <div>
      <div style={{ fontSize: "16px", fontWeight: "bold" }}>
        {myProps.subject_name}
        {/* <button className="btn btn-dark ml-1" onClick={()=>{go_write_week_info_arr_by_ajax({});}}>저장</button> */}
      </div>
      <div className="write_table_small mt-1">
        <table>
          <colgroup>
            {get_week_colgroup_tags()}
          </colgroup>
          <tbody>
            <tr>
              {get_week_tr_td_tags({})}
            </tr>
            <tr>
              {get_week_tr_td_tags({ name: "a_progress_reason" })}
            </tr>
            <tr>
              {get_week_tr_td_tags({ name: "a_progress" })}
            </tr>
            <tr>
              {get_week_tr_td_tags({ name: "a_grow" })}
            </tr>
            <tr>
              {get_week_tr_td_tags({ name: "a_lack" })}
            </tr>
            <tr>
              {get_week_tr_td_tags({ name: "a_content" })}
            </tr>
          </tbody>
        </table>
      </div>
      {loading_data.is_display &&
        <LoadingComponent text={loading_data.text}></LoadingComponent>
      }
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} has_header={popupData.has_header} >
          {popupData.sort === "view" &&
            <ViewPopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData}
              callback={() => { myProps.set_refresh({}); }}
              is_view_write_btn={false}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></ViewPopupContent>
          }
          {popupData.sort == "find_pres" &&
            <FindPrescribePopup
              row_num={popupData.row_num}
              callBackData={callBackDataFindPrescribe}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></FindPrescribePopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default WeekListArea;