import strFunc from '@/lib/lyg/string';
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";

function ViewDeskPopup(props: any) {
  const myProps = {
    closePopup: () => { },
    room_info: null,
    desk_info: null,
    desku_info: null,
    now_ymdhis: "",
    now_lecture_data: null,
    callBackData: () => { },
    ...props
  };

  useEffect(() => {
    
  }, []);

  const get_use_time_str = () => {
    if (myProps.desku_info == null) {
      return "";
    }
    let st_time = strFunc.str_pad({ "str": myProps.desku_info["a_start_h"], "pad_length": 2 }) + ":"
      + strFunc.str_pad({ "str": myProps.desku_info["a_start_m"], "pad_length": 2 });
    let end_time = strFunc.str_pad({ "str": myProps.desku_info["a_end_h"], "pad_length": 2 }) + ":"
      + strFunc.str_pad({ "str": myProps.desku_info["a_end_m"], "pad_length": 2 });
    let use_time_str = (
      <div>
        <span>{st_time}</span>
        <span className="mx-2">~</span>
        <span>{end_time}</span>
      </div>
    );
    return use_time_str;
  };

  const cancel_desk_use = () => {
    if (myProps.desku_info == null) {
      return "";
    }
    if (!confirm(myProps.desku_info.a_user_name + "님의 사용을 취소 하시겠습니까?")) {
      return false;
    }

    let del_row = {
      "a_ymd": myProps.desku_info["a_ymd"],
      "a_seq": myProps.desku_info["a_seq"],
    };
    let del_desku_form_json = {
      "data_arr": [del_row],
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/desku/delete', del_desku_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("사용취소 되었습니다.");
          myProps.callBackData();
          myProps.closePopup();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <div>
      <div className="view-table-div">
        <table>
          <colgroup>
            <col width="100px"></col>
            <col width="*"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>강의실</th>
              <td>
                {myProps.room_info ? myProps.room_info.a_name : ""}
              </td>
            </tr>
            <tr>
              <th>책상</th>
              <td>
                {myProps.desk_info ? myProps.desk_info.a_name : ""}
              </td>
            </tr>
            <tr>
              <th>사용자</th>
              <td>
                {myProps.desku_info ? myProps.desku_info.a_user_name : ""}
              </td>
            </tr>
            <tr>
              <th>사용시간</th>
              <td>
                {myProps.desku_info ?
                  <span>
                    {get_use_time_str()}
                  </span>
                  : ""}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-1">
        {myProps.desku_info != null &&
          <button className="btn-m btn-line-gray" onClick={() => { cancel_desk_use(); }}>사용취소</button>
        }
        <button className="btn-m btn-line-gray" onClick={() => { myProps.closePopup(); }}>닫기</button>
      </div>
    </div>
  );
}

export default ViewDeskPopup;