import TopStuInfoArea from "./area/top_stu_info";
import CounselBox from "./area/counsel_box";
import SubInfoArea from "./area/sub_info";

function CardBox(props:any){
   let myProps={
    Style:{},
    row_num:0,
    info:{},
    xColumnArr:{},
    show_detail_view:(inData:any)=>{},
    openCounselWritePopup:(inData:any)=>{},
    ...props
  };
  let Style=myProps.Style;
  let info=myProps.info;
  
  return (
    <div className={Style.card_box}>
      <div className={Style.card_box_back}></div>
      <div className={Style.card_box_con}>
        <TopStuInfoArea
          info={info}
          Style={Style}
          xColumnArr={myProps.xColumnArr}
        ></TopStuInfoArea>
        <SubInfoArea
          info={info}
          Style={Style}
        ></SubInfoArea>
        <CounselBox
          info={info}
          Style={Style}
          openCounselWritePopup={myProps.openCounselWritePopup}
        ></CounselBox>
        <div className={Style.detail_btn_wrap} >
          <button className={Style.detail_btn}
            onClick={()=>{myProps.show_detail_view(myProps.row_num);}}>+상세보기</button>
        </div>
      </div>
    </div>
  );
};
export default CardBox;