import CompLayout from "@/pcomponents/comp_stu/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback,forwardRef } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import { Link,useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {removeUser,setUser } from '@/store/user/user';
import person_img from "@/img/ico/person_ico.png";
import DateFunc from '@/lib/lyg/date_func';
import popupFunc from "@/lib/lyg/popup";
import strFunc from '@/lib/lyg/string';
import DarkMode from "@/pcomponents/common/dark";
import CoolenMoveBtn from "@/pages/comp/plan/coolen/move/area/move_btn";

function MyPage(){
  let user=useSelector((state:any) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileInput = useRef(null);
  const writeFileForm = useRef(null);

  const [rowData,setRowData]=useState({
    "a_seq":user.user_seq,
    "a_user_id":"",
    "a_user_pw":"",
    "a_user_name":"",
    "a_user_phone":"",
    "a_stu_num":"",
    "profile_thum_url":"",
    "profile_url":"",
    "profile_arr":[],
  });

  useEffect(()=>{
    
    getListOne();
  },[user]);

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(name=="a_user_phone"){
      value=strFunc.autoHypenPhone(value);
    }

    setRowData({
      ...rowData,
      ...{[name]: value}
    });
  };

  const getListOne=useCallback(()=>{
    let formJsonData={
      "s_pri_arr":[user.user_seq],
      "s_addon_profile":"1",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/list',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        if(response.data["data"]["info_arr"].length>0){
          let user_info=response.data["data"]["info_arr"][0];
          let tmpRowData={...rowData};
          tmpRowData["a_seq"]=user_info["a_seq"];
          tmpRowData["a_user_id"]=user_info["a_user_id"];
          tmpRowData["a_user_name"]=user_info["a_user_name"];
          tmpRowData["a_user_phone"]=user_info["a_user_phone"];
          tmpRowData["a_stu_num"]=user_info["a_stu_num"];
          if(user_info["profile_arr"]){
            tmpRowData["profile_arr"]=user_info["profile_arr"];
          }else{
            tmpRowData["profile_arr"]=[];
          }
          if(user_info["profile_arr"]&&user_info["profile_arr"].length>0){
            tmpRowData["profile_thum_url"]=user_info["profile_arr"][0]["thum_img_url"];
            tmpRowData["profile_url"]=user_info["profile_arr"][0]["img_url"];
          }else{
            tmpRowData["profile_thum_url"]="";
            tmpRowData["profile_url"]="";
          }
          setRowData(tmpRowData);
        }
      }
    });
  },[rowData,user]);

  const goWrite=(e:any) => {
    if(rowData.a_user_id==""){
      alert("아이디 입력이 필요합니다.");
      return false;
    }else if(rowData.a_user_name==""){
      alert("이름 입력이 필요합니다.");
      return false;
    }
    if(!confirm("수정 하시겠습니까?")){
      return false;
    }

    let formJsonData={
      "data_arr":[rowData],
      "is_update":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/write',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        let user_info=response.data["data"][0];
        if(fileInput.current&&(fileInput.current as HTMLInputElement).value!=""){
          goWriteProfileImg(user_info);
        }else{
          refreshToken();
        }
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const goWriteProfileImg=(user_info:any)=>{
    if(writeFileForm.current==null){
      refreshToken();
      return false;
    }
    var form = writeFileForm.current as HTMLFormElement;
    var form_json_data= new FormData(form);
    let file_data_row={
      "a_ymd":DateFunc.get_date_format(new Date(),"Ymd"),
      "a_seq":"",
      "a_comp_seq":user.comp_seq,
      "a_par_id":"user",
      "a_par_seq":user_info.a_seq,
      "a_sort1":"profile",
      "a_sort2":"",
      "a_sort3":"",
      "a_writer":user_info.a_user_name,
      "a_create_seq":user_info.a_seq,
    };
    form_json_data.append("data_arr",JSON.stringify([file_data_row]));
    form_json_data.append("authorization",localStorage.intoyou_token);

    my_axios.post( process.env.REACT_APP_FILE_SERVER_URL+'/api/comp/basic/file/file/receive',form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        if(fileInput.current){
          (fileInput.current as HTMLInputElement).value="";
        }
        refreshToken();
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const refreshToken=() => {
    let formJsonData={
      "token_id":localStorage.intoyou_token
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/login/refresh_token',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        localStorage.intoyou_token=response.data["data"]["token_id"];
        var client_info=response.data.data["client_info"];
        dispatch(setUser(client_info));
        alert("저장됨.");
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const goDeleteProFile=(fileInfo:any)=>{
    if(!confirm("프로필사진을 삭제 하시겠습니까?")){
      return false;
    }
    let form_json_data={
      "data_arr":[fileInfo],
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/file/file/delete',form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        refreshToken();
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const goLogOut=()=>{
    if(!confirm("로그아웃 하시겠습니까?")){
      return false;
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/login/logout',{ 
      token_id: localStorage.intoyou_token,
    },get_axios_data()).then((response) => {
      dispatch(removeUser());
      localStorage.removeItem("intoyou_token");
      navigate("/login");
    });
  };

  return (
    <CompLayout>
      <div className="stu_con_wrap">
        <div className="write-table-div mt-1">
          <table>
            <colgroup>
              <col width="110px"></col>
              <col width="*"></col>
            </colgroup>
            <tbody>
              <tr>
                <th>사진</th>
                <td>
                <div className="text-center">
                    <a onClick={()=>{
                        popupFunc.openPopup({'url':rowData.profile_url,'width':700,'height':420,'pop_name':'name','auto_size':false});
                      }}>
                      <img src={rowData["profile_thum_url"]?rowData["profile_thum_url"]:person_img} alt="사람" style={{width:100}} />
                    </a>
                    {
                      rowData.profile_arr.length>0 && 
                      <button className="btn-s btn-gray" onClick={()=>{goDeleteProFile(rowData.profile_arr[0]);}}>X</button>
                    }
                    <form id="write_form" method="post" ref={writeFileForm} >
                      <input type="file" id="files" className="row-input mt-1" name="input_file[]" ref={fileInput} style={{width:200}} />
                    </form>
                  </div>
                </td>
              </tr>
              <tr>
                <th>아이디</th>
                <td>
                  {rowData.a_user_id}
                </td>
              </tr>
              <tr>
                <th>비밀번호</th>
                <td>
                  <input type="password" className="row-input" name="a_user_pw" value={rowData.a_user_pw} onChange={handleInputChange} placeholder="비밀번호" />
                </td>
              </tr>
              <tr>
                <th>이름</th>
                <td>
                  {rowData.a_user_name}
                </td>
              </tr>
              <tr>
                <th>핸드폰</th>
                <td>
                  {rowData.a_user_phone}
                </td>
              </tr>
              <tr>
                <th>출결번호</th>
                <td>
                  {strFunc.str_pad({"str":rowData.a_stu_num,"pad_length":user.stu_num_ciphers})}
                </td>
              </tr>
              <tr>
                <th>다크모드</th>
                <td>
                  <button className="btn btn-dark"><DarkMode></DarkMode></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="my-1 text-center">
          <CoolenMoveBtn></CoolenMoveBtn>
          <span className="ml-1">
            <CoolenMoveBtn coolen_sort={"bank"}></CoolenMoveBtn>
          </span>
        </div>
        <div className="btn-box-center mt-2">
          <button className="btn btn-line-gray" onClick={goWrite}>저장</button>
          <button className="btn btn-line-gray" onClick={goLogOut}>로그아웃</button>
        </div>
      </div>
    </CompLayout>
  );
}

export default MyPage;