import strFunc from "@/lib/lyg/string";
import DailyDataFunc from "../func/data_func";
import TodoStateFunc from "@/pcomponents/common/content/todo/state/func/state_func";

function DailyAmountTd(props:any){
   let myProps={
    info:{},
    lecture_info:null,
    last_daily_info:{},
    select_date: "",
    pre_date_str:"",
    lecture_time_str:"",
    is_sub:false,
    open_write_td_data_popup: (inData: any) => { },
    ...props
  };

  let info=myProps.info;
  let lecture_info=myProps.lecture_info;
  let td_sort="amount";//sub_amount
  if(myProps.is_sub){
    td_sort="sub_amount";
  }
  let daily_info=DailyDataFunc.get_daily_info_of_date({
    date_str:myProps["select_date"],
    pre_date_str:myProps["pre_date_str"],
    is_now_date:true,
    last_daily_info:myProps.last_daily_info,
    lecture_time_str:myProps.lecture_time_str,
    info:info,
    lecture_info:lecture_info,
  });

  let daily_percent=DailyDataFunc.get_daily_percent({
    daily_info:daily_info,
    is_sub:myProps.is_sub
  });
  let color_json=TodoStateFunc.get_color_of_percent(daily_percent);
  let amount=daily_info["a_todo_amount"];
  let success_amount=daily_info["a_todo_success_amount"];
  if(myProps.is_sub){
    amount=daily_info["a_todo_sub_amount"];
    success_amount=daily_info["a_todo_sub_success_amount"];
  }
  amount=strFunc.uncomma(amount);
  success_amount=strFunc.uncomma(success_amount);
  
  return (
    <td onClick={()=>{
      myProps.open_write_td_data_popup({
        daily_report:daily_info,
        td_sort:td_sort
      });
    }} style={{cursor:"pointer",background:color_json.wrap}}>
      {amount}
      /
      {success_amount}
    </td>
  );
};
export default DailyAmountTd;