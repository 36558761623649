import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { useState, useRef, useEffect } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';
import Style from "../css/style.module.css";
import { Link, useNavigate } from 'react-router-dom';
import DataTableArea from "./area/data_table";

const RADIAN = Math.PI / 180;

const TodoChartArea = () => {
  const navigate = useNavigate();
  let user = useSelector((state: any) => state.user);
  const [is_load, set_is_load] = useState(false);
  const [chart_data, set_chart_data] = useState([
    { name: '완료', value: 0, color: '#AEBE00', state: "complete" },
    { name: '미완료', value: 0, color: '#889242', state: "ready" },
    { name: '하는중', value: 0, color: '#28dcde', state: "ing" },
    { name: '중지', value: 0, color: '#505050', state: "stop" },
  ]);
  const [chart_data_today, set_chart_data_today] = useState([
    { name: '완료', value: 0, color: '#AEBE00', state: "complete" },
    { name: '미완료', value: 0, color: '#889242', state: "ready" },
    { name: '하는중', value: 0, color: '#28dcde', state: "ing" },
    { name: '중지', value: 0, color: '#505050', state: "stop" },
  ]);

  useEffect(() => {
    
    get_chart_data();
  }, []);

  const get_chart_data = () => {
    let json_form_data: any = {
      "s_date_type": "a_reserve_start_date",
      "s_start_date": DateFunc.get_date_format(DateFunc.get_change_date(new Date(), 'day', -1), "Y-m-d"),
      "s_end_date": DateFunc.get_date_format(DateFunc.get_change_date(new Date(), 'day', -1), "Y-m-d"),
      "group_id": "a_state",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    if (strFunc.is_empty(user.comp_seq)) {
      return false;
    }
    json_form_data.s_stu_mcomp_seq = user.comp_seq;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/todo/todo/get_front_chart_data', json_form_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          //어제
          let pre1_day_arr = response.data["data"]["pre1_day_arr"];
          
          let todo_arr_len = pre1_day_arr.length;
          let tmp_chart_data = chart_data;
          for (let todo_i = 0; todo_i < todo_arr_len; todo_i++) {
            let todo_info = pre1_day_arr[todo_i];
            for (let i = 0; i < tmp_chart_data.length; i++) {
              if (tmp_chart_data[i]["state"] == todo_info["a_state"]) {
                tmp_chart_data[i]["value"] = todo_info["row_tot"];
              }
            }
          }
          let tmp_chart_data2: any = [];
          //없는것제거
          for (let i = 0; i < tmp_chart_data.length; i++) {
            if (tmp_chart_data[i]["value"]) {
              tmp_chart_data2.push(tmp_chart_data[i]);
            }
          }
          if (tmp_chart_data2.length == 0) {
            tmp_chart_data2.push({ name: '학습현황없음', value: 1, color: '#889242', state: "stop" });
          }
          set_chart_data(tmp_chart_data2);

          //오늘
          let today_arr = response.data["data"]["today_arr"];
          let today_arr_len = today_arr.length;
          let tmp_chart_data_today = chart_data_today;
          for (let todo_i = 0; todo_i < today_arr_len; todo_i++) {
            let todo_info = today_arr[todo_i];
            for (let i = 0; i < tmp_chart_data_today.length; i++) {
              if (tmp_chart_data_today[i]["state"] == todo_info["a_state"]) {
                tmp_chart_data_today[i]["value"] = todo_info["row_tot"];
              }
            }
          }
          let tmp_chart_data_today2: any = [];
          //없는것제거
          for (let i = 0; i < tmp_chart_data_today.length; i++) {
            if (tmp_chart_data_today[i]["value"]) {
              tmp_chart_data_today2.push(tmp_chart_data_today[i]);
            }
          }
          if (tmp_chart_data_today2.length == 0) {
            tmp_chart_data_today2.push({ name: '학습현황없음', value: 1, color: '#889242', state: "stop" });
          }
          set_chart_data_today(tmp_chart_data_today2);
        } else {

        }
        set_is_load(true);
      });
  };

  return (
    <div>
      <div className={Style.box_title_wrap}>
        학습현황
        <a className={Style.box_title_right_link} onClick={() => { navigate("/comp/plan/todo/stu_card_list"); }} >
          바로가기{">"}
        </a>
      </div>
      <div style={{ height: 350, width: "100%" }} >
        {is_load &&
          <div className={Style.todo_chart_row_box}>
            <table style={{ width: "100%" }}>
              <colgroup>
                <col width={"35%"}></col>
                <col width={"65%"}></col>
              </colgroup>
              <tbody>
                <tr>
                  <td>
                    <div style={{ height: 170 }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={300} height={300}>
                          <Pie
                            dataKey="value"
                            isAnimationActive={true}
                            data={chart_data}
                            outerRadius={60}
                            fill="#8884d8"
                            cx="50%"
                            cy="50%"
                          >
                            {chart_data.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </td>
                  <td>
                    <DataTableArea
                      title={"어제의 학습현황"}
                      chart_data={chart_data}
                    ></DataTableArea>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={{ height: 170 }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={300} height={300}>
                          <Pie
                            dataKey="value"
                            isAnimationActive={true}
                            data={chart_data_today}
                            outerRadius={60}
                            fill="#8884d8"
                            cx="50%"
                            cy="50%"
                          >
                            {chart_data_today.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </td>
                  <td>
                    <DataTableArea
                      title={"오늘의 학습현황"}
                      chart_data={chart_data_today}
                    ></DataTableArea>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        }
      </div>
    </div>
  );
};
export default TodoChartArea;