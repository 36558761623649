import strFunc from "@/lib/lyg/string";
import DetailListArea from "./detail";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useEffect } from 'react';

function StudyBox(props: any) {
  let myProps = {
    study_title_info: {},
    study_use_arr: [],
    set_study_use_arr: (inData: any) => { },
    show_detail_title_seq_arr: [],
    set_show_detail_title_seq_arr: (inData: any) => { },
    student_info: {},
    select_daily_study_title: (inData: any) => { },
    go_write_study_use_by_ajax: (inData: any) => { },
    refresh_data: (inData: any) => { },
    set_refresh: () => { },
    ...props
  };
  let study_title_info = myProps.study_title_info;
  let study_use_arr = myProps.study_use_arr;
  let student_info = myProps.student_info;
  let study_use: any = null;
  let study_use_row_num = -1;
  for (let i = 0; i < study_use_arr.length; i++) {
    if (study_use_arr[i]["a_study_title_seq"] == study_title_info["a_seq"]) {
      study_use = study_use_arr[i];
      study_use_row_num = i;
    }
  }

  let study_total_cnt = 0;
  let study_use_success_cnt = 0;
  if (study_title_info["study_arr"] && study_title_info["study_arr"].length > 0) {
    study_total_cnt = study_title_info["study_arr"].length;
  }
  if (study_use != null && study_use.detail_arr) {
    for (let i = 0; i < study_use.detail_arr.length; i++) {
      if (study_use.detail_arr[i]["a_is_success"] == "1") {
        study_use_success_cnt++;
      }
    }
  }


  let is_show_detail_study = false;
  if (strFunc.str_in_array(study_title_info["a_seq"], myProps.show_detail_title_seq_arr) != -1) {
    is_show_detail_study = true;
  }

  useEffect(() => {
    
  }, []);

  const on_change_use_input = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name == "a_is_main" || name == "a_is_sub") {
      value = "";
      if (e.target.checked) {
        value = "1";
      }
    }

    if (study_use == null) {
      return false;
    }
    if (study_use_row_num == -1) {
      return false;
    }

    let change_row_data = { ...study_use };
    change_row_data[name] = value;
    study_use_arr[study_use_row_num] = change_row_data;
    myProps.set_study_use_arr([...study_use_arr]);

    myProps.go_write_study_use_by_ajax({
      is_confirm: false,
      is_refresh: true,
    });
  };

  const add_study_use_by_ajax = (inData: any) => {
    let opt_obj = {
      ...inData
    };

    if (strFunc.is_empty(student_info["a_seq"])) {
      alert("학생정보 없음.");
      return false;
    }
    if (strFunc.is_empty(study_title_info["a_seq"])) {
      alert("교재정보 없음.");
      return false;
    }

    if (!confirm("저장 하시겠습니까?")) {
      return false;
    }

    let default_use_info = {};
    if (study_use != null) {
      default_use_info = { ...study_use };
    }
    let w_study_use_row = {
      ...default_use_info,
      a_stu_seq: student_info["a_seq"],
      a_study_title_seq: study_title_info["a_seq"],
      a_stu_name: student_info["a_user_name"],
    };

    let form_json_data = {
      "data_arr": [w_study_use_row],
      "is_write_detail": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_use/write', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("저장 되었습니다.");
          myProps.refresh_data();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_del_study_use_by_ajax = () => {
    if (study_use == null) {
      return false;
    }

    if (strFunc.is_empty(study_use["a_stu_seq"]) || strFunc.is_empty(study_use["a_study_title_seq"])) {
      alert("삭제 할 기록 없음.");
      return false;
    }

    if (!confirm("사용기록 삭제를 하시겠습니까?")) {
      return false;
    }

    let del_study_use_row = {
      a_stu_seq: study_use["a_stu_seq"],
      a_study_title_seq: study_use["a_study_title_seq"],
    };

    let form_json_data = {
      "data_arr": [del_study_use_row],
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_use/delete', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("삭제 되었습니다.");
          myProps.refresh_data();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const show_detail_study_toggle = () => {
    let tmp_show_detail_title_seq_arr = [...myProps.show_detail_title_seq_arr];
    if (is_show_detail_study) {
      tmp_show_detail_title_seq_arr = [];
      for (let i = 0; i < myProps.show_detail_title_seq_arr.length; i++) {
        let row_title_seq = myProps.show_detail_title_seq_arr[i];
        if (row_title_seq == study_title_info["a_seq"]) {

        } else {
          tmp_show_detail_title_seq_arr.push(row_title_seq);
        }
      }
    } else {
      if (!strFunc.is_empty(study_title_info["a_seq"])) {
        tmp_show_detail_title_seq_arr.push(study_title_info["a_seq"]);
        myProps.set_show_detail_title_seq_arr(tmp_show_detail_title_seq_arr);
      }
    }

    if (study_use_row_num != -1) {
      let change_row_data = { ...study_use };
      change_row_data["a_is_open"] = is_show_detail_study ? "" : "1";
      study_use_arr[study_use_row_num] = change_row_data;
      myProps.set_study_use_arr([...study_use_arr]);

      myProps.go_write_study_use_by_ajax({
        is_confirm: false,
        is_refresh: true,
      });
    }

    myProps.set_show_detail_title_seq_arr(tmp_show_detail_title_seq_arr);
  };

  const get_section_tags = () => {
    let section_tags = "";
    if (study_title_info["section_arr"] && study_title_info["section_arr"].length > 0) {
      section_tags = study_title_info["section_arr"].map((section_info: any, idx: number) => {
        return (
          <div key={idx} className="ml-2 mb-2">
            <div>
              {idx + 1}.
              <span className="ml-1" style={{ color: "blue" }} >
                {section_info["a_title"]}
              </span>
            </div>
            <div>
              <DetailListArea
                study_title_info={study_title_info}
                section_row_num={idx + 1}
                section_info={section_info}
                student_info={student_info}
                study_use={study_use}
                set_study_use={(inData: any) => {
                  let change_row_data = {
                    ...inData,
                    a_stu_seq: student_info["a_seq"],
                    a_study_title_seq: study_title_info["a_seq"]
                  };
                  if (study_use_row_num == -1) {
                    for (let i = 0; i < study_use_arr.length; i++) {
                      if (study_use_arr[i]["a_study_title_seq"] == study_title_info["a_seq"]) {
                        study_use = study_use_arr[i];
                        study_use_row_num = i;
                      }
                    }
                  }
                  study_use_arr[study_use_row_num] = change_row_data;
                  myProps.set_study_use_arr([...study_use_arr]);
                  myProps.go_write_study_use_by_ajax({
                    is_confirm: false,
                    is_refresh: false,
                  });
                }}
                select_daily_study_title={myProps.select_daily_study_title}
              ></DetailListArea>
            </div>
          </div>
        );
      });
    }

    return section_tags;
  };

  return (
    <div>
      <div style={{ fontSize: 15, background: "#ffe", border: "1px solid #ddd", position: "relative", lineHeight: "35px" }}>
        {study_title_info["a_title"]}
        <span className="ml-1">
          ({study_use_success_cnt}/{study_total_cnt})
        </span>
        <span style={{ position: "absolute", right: 5 }}>
          {study_use != null ?
            <span>
              <span className="ml-1" style={{ color: "blue" }}
                onClick={go_del_study_use_by_ajax}>사용</span>
              <label className="ml-2">
                <input type="checkbox" checked={study_use["a_is_main"] == "1"} name="a_is_main"
                  onChange={on_change_use_input} />메인
              </label>
              <label className="ml-1">
                <input type="checkbox" checked={study_use["a_is_sub"] == "1"} name="a_is_sub"
                  onChange={on_change_use_input} />서브
              </label>
            </span>
            :
            <span className="ml-1" style={{ color: "orange", cursor: "pointer" }}
              onClick={add_study_use_by_ajax}>미사용</span>
          }
          <button className="btn btn-dark ml-2" onClick={show_detail_study_toggle}>
            {is_show_detail_study ? "닫기" : "열기"}
          </button>
        </span>
      </div>
      {is_show_detail_study &&
        <div>
          {get_section_tags()}
        </div>
      }
    </div>
  );
};
export default StudyBox;