import strFunc from "@/lib/lyg/string";
import CircleArea from "./circle_area";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

function StuArea(props:any){
  let myProps={
    style:{},
    stu_info:{},
    td_sort_data_arr:[],
    ...props
  };
  let Style=myProps.style;

  const get_average_per_by_data=()=>{
    let aver_per=0;
    let per_sum_num=0;
    let per_sum_count=0;;
    for(let i=0;i<myProps.td_sort_data_arr.length;i++){
      let row_per_num=parseInt(strFunc.uncomma(myProps.td_sort_data_arr[i]["percent"]));
      per_sum_num+=row_per_num;
      per_sum_count++;
    }
    if(per_sum_count!=0&&per_sum_num!=0){
      aver_per=Math.floor(per_sum_num/per_sum_count);
    }
    return aver_per;
  };
  let aver_per=get_average_per_by_data();

  const get_color_style_of_sum_tag=(percent:number)=>{
    let color="#F48282";
    if(percent==0){
      color="#F48282";
    }else if(percent>0){
      color="#EBBD70";
    }
    if(percent>=100){
      color="#B7C528";
    }
    return color;
  };
  let per_color=get_color_style_of_sum_tag(aver_per);

  let a_stu_grade_str=myProps.stu_info.a_stu_grade;
  let select_option_arr=XcolumnFunc.getSortByTableKey({'table':'user','key':'a_stu_grade'});
  for(let i=0;i<select_option_arr.length;i++){
    if(select_option_arr[i]["value"]==a_stu_grade_str){
      a_stu_grade_str=select_option_arr[i]["text"];
    }
  }


  return (
    <div className={Style.stu_top_wrap}>
      <div className={Style.stu_circle_wrap}>
        <CircleArea
          style={myProps.style}
          per_num={aver_per}
          back_color={per_color}
          color={per_color}
        ></CircleArea>
        <p className={Style.total_per_text}>달성률</p>
        <p className={Style.total_per_text_num} style={{color:per_color}}>{aver_per}%</p>
      </div>
      <h4 className={Style.stu_top_su_name}>
        {a_stu_grade_str+" "} {myProps.stu_info.a_user_name}
      </h4>
    </div>
  );
};
export default StuArea;