import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

function ReportLastList(props: any) {
  let myProps = {
    info: {},
    set_info: (inData: any) => { },
    study_title_arr: [],
    lecture_arr: [],
    last_daily_arr: [],
    on_change_daily_input: (inData: any) => { },
    save_last_daily_arr_by_ajax: (inData: any) => { },
    delete_last_daily_arr_by_ajax: (inData: any) => { },
    open_find_study_title_popup: (inData: any) => { },
    ...props
  };
  let info = myProps.info;
  let study_title_arr = myProps.study_title_arr;
  let lecture_arr = myProps.lecture_arr;
  let last_daily_arr = myProps.last_daily_arr;
  let select_subject_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'study_title', 'key': 'a_subject_sort' });

  const get_daily_last_tr_tags = () => {
    let daily_last_tr_tags: any = last_daily_arr.map((daily_last: any, daily_last_idx: number) => {
      let row_pri_val = daily_last["a_stu_seq"] + "," + daily_last["a_writer_seq"] + "," + daily_last["a_lecture_seq"];
      return (
        <tr key={daily_last_idx}>
          <td>
            <select name="a_lecture_seq" value={daily_last.a_lecture_seq} style={{ width: "100%" }}
              onChange={(e: any) => {
                myProps.on_change_daily_input({
                  pri_val: row_pri_val,
                  name: "a_lecture_seq",
                  value: e.target.value,
                });
              }}>
              <option value="">선택없음</option>
              {
                lecture_arr.map((item: any, idx: number) => {
                  return (
                    <option key={idx} value={item.a_seq}>{item.a_name}</option>
                  );
                })
              }
            </select>
          </td>
          <td className="text-center">
            {daily_last.a_writer}
          </td>
          <td className="text-center">
            <select name="a_subject" value={daily_last.a_subject} style={{ width: "100%" }}
              onChange={(e: any) => {
                myProps.on_change_daily_input({
                  pri_val: row_pri_val,
                  name: "a_subject",
                  value: e.target.value,
                });
              }}>
              <option value="">과목</option>
              {
                select_subject_option_arr.map((item: any, idx: number) => {
                  return (
                    <option key={idx} value={item.value}>{item.text}</option>
                  );
                })
              }
            </select>
          </td>
          <td>
            <input type="text" name="a_textbook" value={daily_last.a_textbook} style={{ width: "60%" }}
              onChange={(e: any) => {
                myProps.on_change_daily_input({
                  pri_val: row_pri_val,
                  name: "a_textbook",
                  value: e.target.value,
                });
              }} />
            <button className="btn-s btn-dark ml-1"
              onClick={() => {
                myProps.open_find_study_title_popup({
                  row_num: row_pri_val,
                  key: "a_textbook_seq",
                });
              }}>찾기</button>
          </td>
          <td>
            <input type="text" name="a_textbook_study" value={daily_last.a_textbook_study} style={{ width: "100%" }}
              onChange={(e: any) => {
                myProps.on_change_daily_input({
                  pri_val: row_pri_val,
                  name: "a_textbook_study",
                  value: e.target.value,
                });
              }} />
          </td>
          <td>
            <input type="text" name="a_subtextbook" value={daily_last.a_subtextbook} style={{ width: "60%" }}
              onChange={(e: any) => {
                myProps.on_change_daily_input({
                  pri_val: row_pri_val,
                  name: "a_subtextbook",
                  value: e.target.value,
                });
              }} />
            <button className="btn-s btn-dark ml-1"
              onClick={() => {
                myProps.open_find_study_title_popup({
                  row_num: row_pri_val,
                  key: "a_subtextbook_seq",
                });
              }}>찾기</button>
          </td>
          <td>
            <input type="text" name="a_subtextbook_study" value={daily_last.a_subtextbook_study} style={{ width: "100%" }}
              onChange={(e: any) => {
                myProps.on_change_daily_input({
                  pri_val: row_pri_val,
                  name: "a_subtextbook_study",
                  value: e.target.value,
                });
              }} />
          </td>
          <td>
            <input type="text" name="a_homework" value={daily_last.a_homework} style={{ width: "100%" }}
              onChange={(e: any) => {
                myProps.on_change_daily_input({
                  pri_val: row_pri_val,
                  name: "a_homework",
                  value: e.target.value,
                });
              }} />
          </td>
          <td className="text-center">
            <input type="text" name="a_grade_score" value={daily_last.a_grade_score} style={{ width: "100%", textAlign: "center" }}
              onChange={(e: any) => {
                myProps.on_change_daily_input({
                  pri_val: row_pri_val,
                  name: "a_grade_score",
                  value: e.target.value,
                });
              }} />
          </td>
          <td className="text-center">
            <button className="btn-s btn-dark" onClick={() => { myProps.save_last_daily_arr_by_ajax({ "data_arr": [daily_last] }); }} >저장</button>
            <button className="btn-s btn-red ml-1" onClick={() => { myProps.delete_last_daily_arr_by_ajax({ "data_arr": [daily_last] }); }} >삭제</button>
          </td>
        </tr>
      );
    });
    if (strFunc.is_empty(daily_last_tr_tags)) {
      daily_last_tr_tags = (
        <tr>
          <td colSpan={9}>
            <div className="text-center" style={{ lineHeight: "25px", color: "#777" }}>
              내용이 없습니다.
            </div>
          </td>
        </tr>
      );
    }
    return daily_last_tr_tags;
  };

  return (
    <div className="write_table_small mt-1">
      <table>
        <colgroup>
          <col width={"10%"}></col>
          <col width={"5%"}></col>
          <col width={"5%"}></col>
          <col width={"20%"}></col>
          <col width={"10%"}></col>
          <col width={"20%"}></col>
          <col width={"10%"}></col>
          <col width={"10%"}></col>
          <col width={"5%"}></col>
          <col width={"10%"}></col>
        </colgroup>
        <thead>
          <tr>
            <th>수업</th>
            <th>선생님</th>
            <th>과목</th>
            <th>본교재</th>
            <th>진도</th>
            <th>부교재</th>
            <th>진도</th>
            <th>숙제</th>
            <th>점수</th>
            <th>관리</th>
          </tr>
        </thead>
        <tbody>
          {get_daily_last_tr_tags()}
        </tbody>
      </table>
    </div>
  );
};
export default ReportLastList;