export interface HeadMenuRow {
  key: string;
  name: string;
  url: string;
  msg: string;
  isShow: boolean;
  ico?:string;
  ico_active?:string;
}
export interface SubMenuRow {
  key: string;
  name: string;
  url: string;
  msg: string;
  hmenu:string;
  isShow: boolean;
}

export const hmenu:HeadMenuRow[]=[
  {key:"h_student",name:"원생관리",url:"/comp/basic/student/list",msg:"",isShow:true},
  {key:"h_eval",name:"수업평가",url:"/comp/edu/eval/card_list",msg:"",isShow:false},
  {key:"h_prescribe",name:"학습처방",url:"/comp/edu/month_prescribe/list",msg:"",isShow:true},
  {key:"h_plan",name:"맞춤플랜",url:"/comp/plan/todo/stu_card_list",msg:"",isShow:true},
  {key:"h_video",name:"동영상강의",url:"/comp/plan/video/corse/list",msg:"",isShow:true},
  {key:"h_pmath",name:"PlanMath",url:"/comp/plan_math/basic/make_list",msg:"",isShow:false},
  {key:"h_attend",name:"출결관리",url:"/comp/edu/attend/list_card",msg:"",isShow:false},
  {key:"h_desk",name:"시간표",url:"/comp/edu/lecture/list_one_day",msg:"",isShow:true},
  {key:"h_supply",name:"수납관리",url:"/comp/supply/main",msg:"",isShow:false},
  {key:"h_sms",name:"문자&알림톡",url:"/comp/basic/sms/list",msg:"",isShow:false},
  {key:"h_setting",name:"환경설정",url:"/comp/basic/corp/main",msg:"",isShow:true},
];
export const smenu:SubMenuRow[]=[
  {key:"basic_main",name:"메인",url:"/comp",msg:"",hmenu:"main",isShow:false},
  
  {key:"init_test_main",name:"진단테스트",url:"/comp/init_test/main",msg:"",hmenu:"h_init_test",isShow:true},

  {key:"student_list_new",name:"신규원생관리",url:"/comp/basic/student/list_new",msg:"",hmenu:"h_student",isShow:true},
  {key:"student_list",name:"원생관리",url:"/comp/basic/student/list",msg:"",hmenu:"h_student",isShow:true},
  {key:"counsel_career",name:"RUTA상담",url:"/comp/basic/counsel/career",msg:"",hmenu:"h_student",isShow:true},
  // {key:"class_stu_list",name:"클래스별학생",url:"/comp/edu/class_stu/list",msg:"",hmenu:"h_student",isShow:true},
  {key:"counsel_calendar",name:"상담달력",url:"/comp/basic/counsel/calendar",msg:"",hmenu:"h_student",isShow:true},
  {key:"counsel_list",name:"상담관리",url:"/comp/basic/counsel/list",msg:"",hmenu:"h_student",isShow:false},

  {key:"sms_list",name:"문자",url:"/comp/basic/sms/list",msg:"",hmenu:"h_sms",isShow:true},
  {key:"sms_sender_list",name:"발신번호",url:"/comp/basic/sms_sender/list",msg:"",hmenu:"h_sms",isShow:true},
  {key:"sms_sender_help",name:"도움말",url:"/comp/basic/sms_sender/help",msg:"",hmenu:"h_sms",isShow:true},
  {key:"push_list",name:"푸시이력",url:"/comp/basic/push/list",msg:"",hmenu:"h_sms",isShow:true},
  {key:"par_notice_list",name:"알림장",url:"/comp/board/par_notice/list",msg:"",hmenu:"h_sms",isShow:true},

  {key:"eval_card_list",name:"수업평가서",url:"/comp/edu/eval/card_list",msg:"",hmenu:"h_eval",isShow:true},
  {key:"eval_form_list",name:"평가폼작성",url:"/comp/edu/eval_from/list",msg:"",hmenu:"h_eval",isShow:false},
  {key:"eval_history_list",name:"수업평가이력",url:"/comp/edu/eval_history/list",msg:"",hmenu:"h_eval",isShow:true},
  
  {key:"month_prescribe_list",name:"월간학습처방",url:"/comp/edu/month_prescribe/list",msg:"",hmenu:"h_prescribe",isShow:true},
  {key:"month_prescribe_calendar",name:"학습처방달력",url:"/comp/edu/month_prescribe/calendar",msg:"",hmenu:"h_prescribe",isShow:true},
  {key:"study_diary_list",name:"디스커버리",url:"/comp/basic/study_diary/list",msg:"",hmenu:"h_prescribe",isShow:true},
  {key:"daily_report_card_list",name:"일일보고서",url:"/comp/edu/daily_report/card_list",msg:"",hmenu:"h_prescribe",isShow:true},
  {key:"daily_report_history",name:"일일보고서이력",url:"/comp/edu/daily_report/history_list",msg:"",hmenu:"h_prescribe",isShow:false},

  {key:"study_prescribe_card_list",name:"학습처방",url:"/comp/edu/study_prescribe/card_list",msg:"",hmenu:"h_prescribe",isShow:false},
  {key:"study_prescribe_his_list",name:"학습처방이력",url:"/comp/edu/study_prescribe/history_list",msg:"",hmenu:"h_prescribe",isShow:false},

  {key:"todo_stu_card",name:"학생플랜",url:"/comp/plan/todo/stu_card_list",msg:"",hmenu:"h_plan",isShow:true},
  {key:"plan_main",name:"맞춤플랜",url:"/comp/plan/calendar",msg:"",hmenu:"h_plan",isShow:true},
  {key:"plan_make_todo",name:"맞춤플랜작성",url:"/comp/plan/todo/make_todo",msg:"",hmenu:"h_plan",isShow:true},
  {key:"stu_todo_list",name:"학생플랜이력",url:"/comp/plan/todo/stu_todo/list",msg:"",hmenu:"h_plan",isShow:true},
  //{key:"routin_list",name:"루틴관리",url:"/comp/plan/study/routine/list",msg:"",hmenu:"h_plan",isShow:true},
  {key:"study_list",name:"교재관리",url:"/comp/plan/study/study/list",msg:"",hmenu:"h_plan",isShow:true},
  // {key:"study_subject_list",name:"과목구분",url:"/comp/plan/study/subject/list",msg:"",hmenu:"h_plan",isShow:true},
  {key:"study_share_list",name:"교재공유",url:"/comp/plan/study/share/list",msg:"",hmenu:"h_plan",isShow:true},
  {key:"coolen_move",name:"페어링잉글리쉬",url:"/comp/plan/coolen/move_page",msg:"",hmenu:"h_plan",isShow:true},

  {key:"plan_video_corse_list",name:"동영상강의",url:"/comp/plan/video/corse/list",msg:"",hmenu:"h_video",isShow:true},
  // {key:"plan_video_category_list",name:"카테고리",url:"/comp/plan/video/category/list",msg:"",hmenu:"h_video",isShow:true},
  {key:"plan_video_request_list",name:"신청보기",url:"/comp/plan/video/request/list",msg:"",hmenu:"h_video",isShow:true},
  {key:"video_use_detail_history",name:"사용이력",url:"/comp/plan/video/use_detail_history/list",msg:"",hmenu:"h_video",isShow:true},
  {key:"plan_video_share_corse",name:"공유동영상강의",url:"/comp/plan/video/corse_share/list",msg:"",hmenu:"h_video",isShow:true},

  {key:"plan_math_basic_make",name:"문제생성",url:"/comp/plan_math/basic/make_list",msg:"",hmenu:"h_pmath",isShow:true},

  {key:"attend_card",name:"출결지도",url:"/comp/edu/attend/list_card",msg:"",hmenu:"h_attend",isShow:true},
  {key:"attend_main",name:"출석이력",url:"/comp/edu/attend/main",msg:"",hmenu:"h_attend",isShow:true},
  {key:"attend_stat",name:"월별통계",url:"/comp/edu/attend/stat_month",msg:"",hmenu:"h_attend",isShow:true},

  {key:"supply_main",name:"수납관리",url:"/comp/supply/main",msg:"",hmenu:"h_supply",isShow:true},
  {key:"supply_multy_add",name:"수납생성",url:"/comp/supply/multy_add",msg:"",hmenu:"h_supply",isShow:true},
  {key:"pos_card_list",name:"카드결제내역",url:"/comp/supply/pos/card/list",msg:"",hmenu:"h_supply",isShow:true},
  {key:"cash_receipt_list",name:"현금영수증내역",url:"/comp/supply/pos/cash_receipt/list",msg:"",hmenu:"h_supply",isShow:true},
  {key:"simple_trade_list",name:"거래내역(원생)",url:"/comp/supply/simple_trade/list",msg:"",hmenu:"h_supply",isShow:true},

  {key:"lecture_one_day",name:"시간표",url:"/comp/edu/lecture/list_one_day",msg:"",hmenu:"h_desk",isShow:true},
  {key:"list_one_day2",name:"시간표",url:"/comp/edu/lecture/list_one_day2",msg:"",hmenu:"h_desk",isShow:false},
  {key:"one_week_by_stu",name:"시간표(학생)",url:"/comp/edu/lecture/view_time_table/one_week_by_stu",msg:"",hmenu:"h_desk",isShow:true},
  {key:"desk_main",name:"좌석설정",url:"/comp/edu/desk/main",msg:"",hmenu:"h_desk",isShow:true},
  //{key:"view_time_table_teacher",name:"시간표(학생)",url:"/comp/edu/lecture/view_time_table/one_week_by_teacher",msg:"",hmenu:"h_desk",isShow:true},
  {key:"lecture_list",name:"수업관리",url:"/comp/edu/lecture/list",msg:"",hmenu:"h_desk",isShow:true},
  {key:"lecture_stu_list",name:"수업수강생",url:"/comp/edu/lecture/lecture_user_time",msg:"",hmenu:"h_desk",isShow:true},
  {key:"eroom_list",name:"강의실관리",url:"/comp/edu/room/list",msg:"",hmenu:"h_desk",isShow:true},
  {key:"desk_use_history",name:"좌석 사용이력",url:"/comp/edu/desk/use_list",msg:"",hmenu:"h_desk",isShow:true},
  {key:"desk_room_view_popup",name:"강의실책상보기팝업",url:"/comp/edu/desk/popup/view_room_popup",msg:"",hmenu:"h_desk",isShow:false},

  {key:"corp_main",name:"학원정보",url:"/comp/basic/corp/main",msg:"",hmenu:"h_setting",isShow:true},
  {key:"notice_list",name:"공지",url:"/comp/board/notice/list",msg:"",hmenu:"h_setting",isShow:true},
  {key:"user_staff_list",name:"직원관리",url:"/comp/basic/user_staff/list",msg:"",hmenu:"h_setting",isShow:true},
  {key:"class_list",name:"클래스관리",url:"/comp/edu/class/list",msg:"",hmenu:"h_setting",isShow:true},
  {key:"student_list_multy",name:"원생일괄수정",url:"/comp/basic/student/list_multy",msg:"",hmenu:"h_setting",isShow:true},
  {key:"stu_up_grade",name:"학년진급",url:"/comp/basic/student/stu_up_grade",msg:"",hmenu:"h_setting",isShow:true},
  {key:"friend_sites",name:"관련 사이트",url:"/comp/edu/site/main",msg:"",hmenu:"h_setting",isShow:true},
  {key:"parent_list",name:"학부모",url:"/comp/basic/parent/list",msg:"",hmenu:"h_setting",isShow:true},
  {key:"log_login_list",name:"로그인로그",url:"/comp/basic/log_login/list",msg:"",hmenu:"h_setting",isShow:true},
  {key:"user_removed_list",name:"삭제회원이력",url:"/comp/basic/user_removed/list",msg:"",hmenu:"h_setting",isShow:true},
  {key:"sort_custom_list",name:"구분",url:"/comp/basic/sort_custom/list",msg:"",hmenu:"h_setting",isShow:true},
  {key:"mypage",name:"마이페이지",url:"/comp/mypage",msg:"",hmenu:"main",isShow:false},
  {key:"site_map",name:"사이트맵",url:"/comp/site_map",msg:"",hmenu:"main",isShow:false},
];
export const hmenu_master_arr:string[]=[
  "h_supply"
];
export const smenu_master_arr:string[]=[
  "supply_main",
  "supply_multy_add",
  "pos_card_list",
  "cash_receipt_list",
  "simple_trade_list",
  "sort_custom_list",
  "user_staff_list",
  "log_login_list",
  "user_removed_list",
  
  "lecture_list",
  "lecture_stu_list",
  "eroom_list",
];