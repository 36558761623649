//import TodoStateFunc from "@/pcomponents/common/content/todo/state/func/state_func";
import strFunc from '@/lib/lyg/string';

class TodoStateFunc {
  static get_sort_str_by_todo_info(todo_info: any) {
    let sort_str = "";//할일구분(homework:숙제,study:학습,video:강의)
    if (todo_info["a_sort"] == "homework") {
      sort_str = "숙제";
    } else if (todo_info["a_sort"] == "study") {
      sort_str = "학습";
    } else if (todo_info["a_sort"] == "video") {
      sort_str = "강의";
    } else if (todo_info["a_sort"] == "etc") {
      sort_str = "기타";
    }
    return sort_str;
  }
  static get_state_str_by_todo_info(todo_info: any) {
    let state_str = "";
    if (todo_info["a_state"] == "ready") {
      state_str = "준비";
    } else if (todo_info["a_state"] == "ing") {
      state_str = "진행중";
    } else if (todo_info["a_state"] == "stop") {
      state_str = "중지";
    } else if (todo_info["a_state"] == "complete") {
      state_str = "완료";
    }
    return state_str;
  }
  static get_percent_by_todo_info(todo_info: any) {
    let stat_data=this.get_stat_data_by_todo_info(todo_info);
    let percent = stat_data.percent;
    return percent;
  }
  static get_stat_data_by_todo_info(todo_info: any) {
    let percent = 0;
    let max_amout = 0;
    let ing_amout = 0;
    if (todo_info.a_success_sort == "time") {
      max_amout = parseInt(strFunc.uncomma(todo_info.a_success_time_sec));
      ing_amout = parseInt(strFunc.uncomma(todo_info.a_time_sec));
    } else if (todo_info.a_success_sort == "amount") {
      max_amout = parseInt(strFunc.uncomma(todo_info.a_success_amount));
      ing_amout = parseInt(strFunc.uncomma(todo_info.a_amount_num));
    }
    if (max_amout != 0 && ing_amout != 0) {
      percent = (ing_amout / max_amout) * 100;
      percent = Math.floor(percent);
    }
    if (todo_info["a_par_id"] == "video_list") {
      if (todo_info.video_info) {
        let complete_sec = parseInt(strFunc.uncomma(todo_info.video_info.a_complete_sec));
        let watch_sec = parseInt(strFunc.uncomma(todo_info.a_time_sec));
        if (watch_sec != 0 && complete_sec != 0) {
          percent = Math.ceil((watch_sec / complete_sec) * 100);
        }
      }
    }

    let time_his_json=strFunc.secondsToTimeJson(parseInt(strFunc.uncomma(todo_info["a_time_sec"])));
    let time_str=strFunc.timeJsonToTimeStr(time_his_json);

    return {
      percent:percent,
      max_amout:max_amout,
      ing_amout:ing_amout,
      time_his_json:time_his_json,
      time_str:time_str
    };
  }
  static get_color_of_percent(percent: number) {
    let con_wrap: any = "#FAD5CC";
    let con_bar: any = "#F48282";
    let per_span: any = "#E18685";
    if (percent == 0) {
      con_wrap = "#FAD5CC";
      con_bar = "#F48282";
      per_span = "#E18685";
    } else if (percent > 0) {
      con_wrap = "#FAF1E0";
      con_bar = "#EBBD70";
      per_span = "#E0BA7B";
    }
    if (percent >= 100) {
      con_wrap = "#EFF3D0";
      con_bar = "#B7C528";
      per_span = "#B0BA3F";
    }
    return {
      wrap: con_wrap,
      bar: con_bar,
      per: per_span
    };
  }
  static get_num_arr_percent(num_arr: number[]) {
    let num_arr_len = num_arr.length;
    let num_sum = 0;
    for (let i = 0; i < num_arr_len; i++) {
      num_sum += num_arr[i];
    }
    let percent = 0;
    if (num_sum != 0 && num_arr_len != 0) {
      percent = Math.round(num_sum / num_arr_len);
    }
    return percent;
  };
  static get_sort_percent_by_user_info(user_info: any) {
    let todo_stat_data: any = {
      "homework": { percent: 0, percent_arr: [], todo_info_arr: [], count: 0, success_cnt: 0 },
      "study": { percent: 0, percent_arr: [], todo_info_arr: [], count: 0, success_cnt: 0 },
      "video": { percent: 0, percent_arr: [], todo_info_arr: [], count: 0, success_cnt: 0 },
      "etc": { percent: 0, percent_arr: [], todo_info_arr: [], count: 0, success_cnt: 0 },
      "total": { percent: 0, percent_arr: [], todo_info_arr: [], count: 0, success_cnt: 0 },
    };
    if (user_info.todo_arr && user_info.todo_arr.length > 0) {
      let todo_len = user_info.todo_arr.length;
      for (let i = 0; i < todo_len; i++) {
        let todo_info = user_info.todo_arr[i];
        let row_per_num = 0;
        if (todo_info["a_success_sort"] == "amount") {
          let a_amount_num = parseInt(strFunc.uncomma(todo_info["a_amount_num"]));
          let a_success_amount = parseInt(strFunc.uncomma(todo_info["a_success_amount"]));
          if (a_amount_num != 0 && a_success_amount != 0) {
            row_per_num = Math.round((a_amount_num / a_success_amount) * 100);
          }
        } else {
          let a_time_sec = parseInt(strFunc.uncomma(todo_info["a_time_sec"]));
          let a_success_time_sec = parseInt(strFunc.uncomma(todo_info["a_success_time_sec"]));
          if (a_time_sec != 0 && a_success_time_sec != 0) {
            row_per_num = Math.round((a_time_sec / a_success_time_sec) * 100);
          }
        }
        todo_stat_data["total"].percent_arr.push(row_per_num);
        todo_stat_data["total"]["count"]++;
        if (todo_info["a_is_success"] == "1") {
          todo_stat_data["total"]["success_cnt"]++;
        }

        let a_sort = todo_info["a_sort"];
        if (todo_stat_data[a_sort] == undefined) {
          todo_stat_data[a_sort] = { percent: 0, percent_arr: [], todo_info_arr: [], count: 0, success_cnt: 0 };
        }
        todo_stat_data[a_sort].percent_arr.push(row_per_num);
        todo_stat_data[a_sort].count++;
        if (todo_info["a_is_success"] == "1") {
          todo_stat_data[a_sort].success_cnt++;
        }
      }
      //total합계퍼센트
      for (let key in todo_stat_data) {
        todo_stat_data[key]["percent"] = this.get_num_arr_percent(todo_stat_data[key]["percent_arr"]);
      }
    }
    return todo_stat_data;
  }
  static get_file_cnt_by_user_info(inData:any){
    let opt_obj={
      info:{},
      date_sort:"pre",//pre,now
      select_date:"",
      pre_date_str:"",
      ...inData
    };
    let info=opt_obj["info"];
    let date_sort=opt_obj["date_sort"];
    let select_date=opt_obj["select_date"];
    let pre_date_str=opt_obj["pre_date_str"];
    let row_file_cnt = 0;
    if (info.home_work_file_arr && info.home_work_file_arr.length > 0) {
      for (let i = 0; i < info.home_work_file_arr.length; i++) {
        if (date_sort == "pre") {
          if (info.home_work_file_arr[i]["a_create_date"].substring(0, 10) >= pre_date_str
            && info.home_work_file_arr[i]["a_create_date"].substring(0, 10) < select_date) {
            row_file_cnt++;
          }
        } else if (date_sort == "now") {
          if (info.home_work_file_arr[i]["a_create_date"].substring(0, 10) == select_date) {
            row_file_cnt++;
          }
        }
      }
    }
    return row_file_cnt;
  };
}
export default TodoStateFunc;