import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"user",
    list_id:"make_todo_stu_list1",
    x_column_list_arr:[
      {"key":"a_user_name","name":"이름","width":"70","is_show":"1"},
      {"key":"row_view_class_name","name":"클래스","width":"120","is_show":"1"},
      {"key":"row_view_school_grade","name":"학교/학년","width":"90","is_show":"1"},
      {"key":"row_view_more","name":"더보기","width":"55","is_show":"1"},
      {"key":"row_view_add","name":"추가","width":"40","is_show":"1"},
      // {"key":"a_school_name","name":"학교","width":"100","is_show":"1"},
      // {"key":"a_stu_grade","name":"학년","width":"60","is_show":"1"},
      // {"key":"a_user_id","name":"아이디","width":"70","is_show":"1"},
      // {"key":"row_view_par_phone","name":"학부모연락처","width":"145","is_show":"1"},
      // {"key":"a_user_phone","name":"학생연락처","width":"140","is_show":""},
      // {"key":"row_view_school_grade","name":"학교/학년","width":"60","is_show":""},
      // {"key":"row_view_attend","name":"출석","width":"100","is_show":"1"},
      // {"key":"a_stu_state","name":"현황","width":"60","is_show":"1"},
      // {"key":"row_view_recent_councel","name":"상담일","width":"70","is_show":"1"},
      // {"key":"row_view_manage","name":"관리","width":"80","is_show":"1"},
      
      // {"key":"a_stu_num","name":"출결번호","width":"110","is_show":"1"},
      // {"key":"a_join_date","name":"입학일","width":"110","is_show":"1"},
      // {"key":"invite_code","name":"초대코드","width":"110","is_show":"1"},
      // {"key":"row_view_is_app","name":"어플","width":"50","is_show":"1"},
      // {"key":"a_last_login_date","name":"마지막 로그인","width":"160","is_show":""},
      // {"key":"a_create_date","name":"작성일","width":"180","is_show":""},
      // {"key":"a_update_date","name":"수정일","width":"180","is_show":""},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':300,
      'order_id':'a_create_date DESC',
      'order_type':'',
      's_date_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
      "s_class_seq":"",
      "s_school_name":"",
      "s_stu_grade":"",
      "s_stu_state":"now",
      "s_is_par_join":"",
      "s_search_text_type":"name",
      "s_search_text":"",
      "s_user_name_like":"",
      "s_phone_like":"",
      "s_stu_num_like":"",
      "s_school_name_like":"",
      "s_class_name_like":"",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);
  xColumnArr.date_col_arr=["a_join_date"];

  return xColumnArr;
};

export default getXcolumnJson;