import Style from "./css/style.module.css";
import strFunc from '@/lib/lyg/string';
import { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import TopInfoArea from "./area/top_info";
import TopStuInfoArea from "./area/top_stu_info";
import ParentArea from "./area/parent/parent_area";
import CounselInfoTopArea from "./area/counsel_info_top";
import CounselInfoArea from "./area/counsel_info";
import SupplyRecentListArea from "./tab_area/supply_recent";
import SelectTab from "@/pcomponents/common/crud/write/select_tab";
import TopMoveButton from "@/pcomponents/common/content/list/top_button";
import CounselTodoArea from "./tab_area/counsel_todo";
import StudyDiary from "./tab_area/study_diary";
import StudyAndCounselArea from "./tab_area/study_and_counsel2";
import CounselCareerArea from "./tab_area/counsel_career";
import MonthPrescribeTabArea from "./tab_area/month_prescribe";
import DailyReportLastArea from "./area/daily_report_last";

const WriteArea = forwardRef((props: any, ref) => {
  let myProps = {
    info: {},
    set_info: (inData: any) => { },
    add_memo_info: {},
    set_add_memo_info: (inData: any) => { },
    parentArr: [],
    setParentArr: (inData: any) => { },
    refresh_data: () => { },
    openModifyClassPopup: () => { },
    is_update: false,
    xColumnArr: {},
    is_view_stu_info: true,
    is_view_parent: true,
    is_view_supply: true,
    is_view_basic: false,
    is_view_mode: false,
    tab_id: "monthly_prescribe",
    is_first_tab_scroll:false,
    counsel_s_select_date: "",
    counsel_s_start_date: "",
    counsel_s_end_date: "",
    ...props
  };

  let info = myProps.info;
  let add_memo_info = myProps.add_memo_info;
  const TopStuInfoAreaRef = useRef<any>(null);
  const ParentAreaRef = useRef<any>(null);
  const StudyAndCounselAreaRef = useRef<any>(null);
  const StudyDiaryRef = useRef<any>(null);
  const CounselCareerAreaRef = useRef<any>(null);
  const [tab_id, set_tab_id] = useState(myProps.tab_id);
  const [is_show_basic, set_is_show_basic] = useState(myProps.is_view_basic);
  const [is_first_tab_scroll, set_is_first_tab_scroll] = useState(myProps.is_first_tab_scroll);
  const select_tab_wrap_ref = useRef<any>(null);
  let tab_id_arr = [
    //{ "text": "기본정보", "value": "basic" },
    { "text": "월별학습처방", "value": "monthly_prescribe" },
    { "text": "학습&상담", "value": "study_and_counsel" },
    { "text": "RUTA상담", "value": "counsel_career" },
    //{ "text": "학습일지", "value": "study_diary" },
    { "text": "청구서", "value": "supply" },
    { "text": "모두보기", "value": "all" },
  ];

  const setInitData = (inData: any) => {
    let opt_obj = {
      info: {},
      counsel_s_select_date: "",
      counsel_s_start_date: "",
      counsel_s_end_date: "",
      ...inData
    };
    if (StudyAndCounselAreaRef.current) {
      StudyAndCounselAreaRef.current.setInitData({
        stu_info: opt_obj.info,
        s_select_date: opt_obj.counsel_s_select_date,
        s_start_date: opt_obj.counsel_s_start_date,
        s_end_date: opt_obj.counsel_s_end_date,
      });
    }
    if (StudyDiaryRef.current) {
      StudyDiaryRef.current.setInitData({
        stu_info: opt_obj.info,
      });
    }
    if (CounselCareerAreaRef.current) {
      CounselCareerAreaRef.current.setInitData({
        stu_info: opt_obj.info,
      });
    }
    if (is_first_tab_scroll) {
      set_is_first_tab_scroll(false);
      setTimeout(() => {
        select_tab_wrap_ref.current?.scrollIntoView({});//behavior: 'smooth'
      }, 100);
    }
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData
  }));

  return (
    <div className={Style.detail_wrap}>
      <div className={Style.detail_wrap_con}>
        <TopInfoArea
          info={info}
          set_info={myProps.set_info}
          add_memo_info={add_memo_info}
          set_add_memo_info={myProps.set_add_memo_info}
          refresh_data={myProps.refresh_data}
          openModifyClassPopup={myProps.openModifyClassPopup}
          is_update={myProps.is_update}
          Style={Style}
          is_view_mode={myProps.is_view_mode}
        ></TopInfoArea>
        {(is_show_basic == false
          && (myProps.is_view_stu_info || myProps.is_view_parent)) &&
          <div className="text-right mt-1">
            <button className="btn btn-gray" onClick={() => { set_is_show_basic(true); }}>학생 기본정보 보기</button>
          </div>
        }
        {is_show_basic &&
          <div>
            {myProps.is_view_stu_info &&
              <TopStuInfoArea
                ref={TopStuInfoAreaRef}
                info={info}
                set_info={myProps.set_info}
                refresh_data={myProps.refresh_data}
                is_update={myProps.is_update}
                xColumnArr={myProps.xColumnArr}
                Style={Style}
                set_is_show_basic={set_is_show_basic}
                is_view_mode={myProps.is_view_mode}
              ></TopStuInfoArea>
            }
            {myProps.is_view_parent &&
              <ParentArea
                ref={ParentAreaRef}
                is_update={myProps.is_update}
                parentArr={myProps.parentArr}
                setParentArr={myProps.setParentArr}
                on_change_parent_hp={(inData: any) => {
                  if (myProps.isUpdate == false && !strFunc.is_empty(inData)) {
                    let tmp_tel_str = strFunc.str_replace("-", "", inData);
                    tmp_tel_str = tmp_tel_str.substring(tmp_tel_str.length - 4);
                    myProps.set_info({
                      ...info,
                      "a_stu_num": tmp_tel_str
                    });
                    if (TopStuInfoAreaRef.current) {
                      TopStuInfoAreaRef.current.checkStuNumOfChildUser(tmp_tel_str);
                    }
                  }
                }}
              ></ParentArea>
            }
          </div>
        }
        <div className="mt-1">
          <CounselInfoArea
            info={info}
            add_memo_info={add_memo_info}
            set_add_memo_info={myProps.set_add_memo_info}
            is_update={myProps.is_update}
            is_view_mode={myProps.is_view_mode}
            Style={Style}
            refresh_data={myProps.refresh_data}
          ></CounselInfoArea>
        </div>
        <CounselInfoTopArea
          info={info}
          add_memo_info={add_memo_info}
          set_add_memo_info={myProps.set_add_memo_info}
          is_update={myProps.is_update}
          is_view_mode={myProps.is_view_mode}
          Style={Style}
          refresh_data={myProps.refresh_data}
        ></CounselInfoTopArea>
        {myProps.is_update &&
          <DailyReportLastArea
            info={info}
            set_info={myProps.set_info}
            refresh_data={myProps.refresh_data}
          ></DailyReportLastArea>
        }
        <div className="mt-1" ref={select_tab_wrap_ref}>
          <SelectTab
            select_arr={tab_id_arr}
            value={tab_id}
            on_select={(inData: any) => { set_tab_id(inData); }}
          ></SelectTab>
          {(tab_id == "monthly_prescribe" || tab_id == "all") &&
            <div>
              <MonthPrescribeTabArea
                stu_info={info}
                is_view_mode={myProps.is_view_mode}
                s_select_date={myProps.counsel_s_select_date}
                refresh_data={myProps.refresh_data}
              ></MonthPrescribeTabArea>
            </div>
          }
          {(tab_id == "supply" || tab_id == "all") &&
            <div>
              {(myProps.is_update && myProps.is_view_supply) &&
                <SupplyRecentListArea
                  rowData={info}
                ></SupplyRecentListArea>
              }
            </div>
          }
          {(tab_id == "counsel" || tab_id == "all") &&
            <div>
              {myProps.is_update &&
                <CounselTodoArea
                  stu_info={info}
                  is_view_mode={myProps.is_view_mode}
                ></CounselTodoArea>
              }
            </div>
          }
          {(tab_id == "study_and_counsel" || tab_id == "all") &&
            <div>
              {myProps.is_update &&
                <StudyAndCounselArea
                  ref={StudyAndCounselAreaRef}
                  stu_info={info}
                  is_view_mode={myProps.is_view_mode}
                  s_select_date={myProps.counsel_s_select_date}
                  s_start_date={myProps.counsel_s_start_date}
                  s_end_date={myProps.counsel_s_end_date}
                  refresh_data={myProps.refresh_data}
                ></StudyAndCounselArea>
              }
            </div>
          }
          {(tab_id == "study_diary" || tab_id == "all") &&
            <div>
              {myProps.is_update &&
                <StudyDiary
                  ref={StudyDiaryRef}
                  stu_info={info}
                  is_view_mode={myProps.is_view_mode}
                  refresh_data={myProps.refresh_data}
                ></StudyDiary>
              }
            </div>
          }
          {(tab_id == "counsel_career" || tab_id == "all") &&
            <div>
              {myProps.is_update &&
                <CounselCareerArea
                  ref={CounselCareerAreaRef}
                  stu_info={info}
                  is_view_mode={myProps.is_view_mode}
                ></CounselCareerArea>
              }
            </div>
          }
        </div>
      </div>
      <TopMoveButton></TopMoveButton>
    </div>
  );
});
export default WriteArea;