export const default_info={
  "a_seq":"",
  "a_corp_seq":"1",
  "a_user_id":"",
  "a_user_pw":"",
  "a_user_grade":"student",
  "a_is_login":"1",
  "a_user_name":"",
  "a_user_phone":"",

  "a_stu_state":"now",
  "a_stu_num":"",
  "a_class_seq":"",
  "a_gender":"",
  "a_school_name":"",
  "a_stu_grade":"",
  "a_join_date":"",
  "a_join_counsel_date":"",
  "a_stu_birth_date":"",
  "a_stu_supply_day":"10",
  "a_stu_supply_discount":"0",
  "a_memo":"",
  "a_stu_end_date":"",
  "a_stu_end_memo":"",
  "invite_code":"",
  "a_makeedu_num":"",
  "a_makeedu_class":"",
  "a_cash_receipt_sort":"",
  "a_cash_receipt_num":"",

  "class_arr":[],
  "link_user_arr":[],
  "profile_arr":[],

  "person_thum_img":"",
  "person_img":"",

  "comp_link_info":undefined
};
export const default_add_memo_info={
  a_user_seq:"",
  a_user_name:"",
  a_stu_think_type:"",
  a_parent_sort:"",
  a_parent_edu_type:"",
  a_attitude:"",
  a_ability:"",
  a_wish_student:"",
  a_wish_parent:"",
  a_pre_exam_name:"",
  a_pre_en_exam_score:"",
  a_pre_language_exam_score:"",
  a_pre_science_exam_score:"",
  a_pre_math_exam_score:"",
  a_pre_society_exam_score:"",
  a_pre_edu_history:"",
  a_matter_condition:"",
  a_counsel_content:"",
  a_english_test_nelt:"",
  a_english_test_nelt_etc:"",
  a_math_test_score:"",
  a_math_test_score_etc:"",
  a_en_issue:"",
  a_en_plan:"",
  a_en_action:"",
  a_en_attitude:"",
  a_en_ability:"",
  a_math_issue:"",
  a_math_plan:"",
  a_math_action:"",
  a_math_attitude:"",
  a_math_ability:"",
  a_appeal_from_parent:"",
  a_appeal_from_student:"",
};
export const add_memo_select_arr={
  a_stu_think_type:[
    { "value": "R", "text": "R" },
    { "value": "U", "text": "U" },
    { "value": "T", "text": "T" },
    { "value": "A", "text": "A" },
  ],
  a_parent_edu_type:[
    { "value": "민주", "text": "민주" },
    { "value": "독재", "text": "독재" },
    { "value": "과잉", "text": "과잉" },
    { "value": "방임", "text": "방임" },
  ],
  a_attitude:[
    { "value": "60", "text": "1.거부",title:"Lv.1 거부" },
    { "value": "70", "text": "2.수용",title:"Lv.2 수용"},
    { "value": "80", "text": "3.인정",title:"Lv.3 인정"},
    { "value": "90", "text": "4.의문",title:"Lv.4 의문"},
    { "value": "100", "text": "5.주도",title:"Lv.5 자기주도학습"},
  ],
  a_ability:[
    { "value": "100", "text": "A" ,title:"100"},
    { "value": "80", "text": "B" ,title:"80"},
    { "value": "60", "text": "C" ,title:"60"},
    { "value": "40", "text": "D" ,title:"40"},
    { "value": "20", "text": "E" ,title:"학년미달"},
  ],
  a_stu_total_code:[
    { "value": "T1", "text": "T1" },
    { "value": "T2", "text": "T2" },
    { "value": "T3", "text": "T3" },
    { "value": "T4", "text": "T4" },
    { "value": "T4+", "text": "T4+" },
    { "value": "Ts", "text": "Ts" },
  ],
}