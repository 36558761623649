import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import StudyTitleListArea from "./area/list";

function StuStudyUseArea(props: any) {
  let myProps = {
    stu_seq: {},
    list: (inData: any) => { },
    select_daily_study_title: (inData: any) => { },
    closePopup: () => { },
    ...props
  };
  const [study_title_search_opt, set_study_title_search_opt] = useState({
    "s_subject_seq": "",
    "s_subject_sort": "",
    "s_addon_section": "1",
    "s_addon_study": "1",
    "s_addon_use_stu": "",
    "s_addon_use": "",
    "s_addon_use_s_stu_seq": "",
    "is_need_count": "",
    "is_need_info_arr": "1",
    "is_no_limit": "1",
  });
  const [study_title_arr, set_study_title_arr] = useState([]);
  const [study_use_arr, set_study_use_arr] = useState([]);
  const [show_detail_title_seq_arr, set_show_detail_title_seq_arr] = useState<any>([]);
  const [student_info, set_student_info] = useState({});
  const [is_show_only_use, set_is_show_only_use] = useState(true);
  const [subject_arr, set_subject_arr] = useState<any>([]);
  let subject_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'study_title', 'key': 'a_subject_sort' });
  const [refresh, set_refresh] = useState(false);

  useEffect(() => {
    
    refresh_data();
    get_student_by_ajax();
    list_corse_subject();
  }, []);

  const refresh_data = () => {
    get_study_title_list_by_ajax({});
    get_study_use_list_by_ajax();
  };

  const get_study_title_list_by_ajax = (inData: any) => {
    let form_json_data = {
      ...study_title_search_opt,
      ...inData,
    };
    set_study_title_search_opt(form_json_data);
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_title/list', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_study_title_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };
  const get_study_use_list_by_ajax = () => {
    let form_json_data = {
      "s_stu_seq": myProps["stu_seq"],
      "s_addon_detail": "1",
      "order_id": "a_is_main DESC, a_is_sub DESC, a_stu_name",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_use/list', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_study_use_arr(response.data["data"]["info_arr"]);
          let tmp_show_detail_title_seq_arr = [];
          if (response.data["data"]["info_arr"].length > 0) {
            for (let i = 0; i < response.data["data"]["info_arr"].length; i++) {
              if (response.data["data"]["info_arr"][i]["a_is_open"] == "1") {
                tmp_show_detail_title_seq_arr.push(response.data["data"]["info_arr"][i]["a_study_title_seq"]);
              }
            }
          }
          set_show_detail_title_seq_arr(tmp_show_detail_title_seq_arr);
        } else {

        }
      });
  };
  const list_corse_subject = () => {
    let subject_list_form_data = {
      "order_id": "a_order_num, a_title",
      "s_is_use": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_subject/list', 
      subject_list_form_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_subject_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const go_write_study_use_by_ajax = (inData: any) => {
    let opt_obj = {
      is_confirm: true,
      is_refresh: true,
      ...inData
    };
    if (opt_obj["is_confirm"]) {
      if (!confirm("저장 하시겠습니까?")) {
        return false;
      }
    }
    let form_json_data = {
      "data_arr": study_use_arr,
      "is_write_detail": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_use/write', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (opt_obj["is_confirm"]) {
            alert("저장 되었습니다.");
          }
          if (opt_obj["is_refresh"]) {
            refresh_data();
          }
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const get_student_by_ajax = () => {
    let form_json_data = {
      "s_pri_arr": [myProps["stu_seq"]],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            set_student_info(response.data["data"]["info_arr"][0]);
          }
          if (response.data["data"]["info_arr"].length == 0) {
            alert("학생 정보가 없습니다.");
            myProps.closePopup();
          }
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <div>
      <div className="text-center mt-3" style={{ position: "relative", borderTop: "1px solid #bfbfbf", lineHeight: "28px" }}>
        학습교재
        {/* <button className="btn btn-sky ml-2" onClick={go_write_study_use_by_ajax} 
        style={{position:"absolute",right:5,top:2}}>저장</button> */}
        <span style={{ position: "absolute", right: 5, top: 1 }} >
          <select 
            value={study_title_search_opt.s_subject_seq} name={"s_subject_seq"}
            style={{ border: "1px solid #ddd", width: 60 }}
            onChange={(e: any) => {
              get_study_title_list_by_ajax({
                s_subject_seq: e.target.value
              });
            }}>
            <option value="">구분</option>
            {
              subject_arr.map((item: any, idx: number) => {
                return (
                  <option key={idx} value={item["a_seq"]} >{item["a_title"]}</option>
                );
              })
            }
          </select>
          <select className="ml-1"
            value={study_title_search_opt.s_subject_sort} name={"s_subject_sort"}
            style={{ border: "1px solid #ddd", width: 60 }}
            onChange={(e: any) => {
              get_study_title_list_by_ajax({
                s_subject_sort: e.target.value
              });
            }}>
            <option value="">과목</option>
            {
              subject_option_arr.map((item: any, idx: number) => {
                return (
                  <option key={idx} value={item["value"]} >{item["text"]}</option>
                );
              })
            }
          </select>
          <label className="ml-1">
            <input type="checkbox" checked={is_show_only_use} value="1"
              onChange={(e: any) => {
                set_is_show_only_use(e.target.checked);
              }} />
            사용중만 표시
          </label>
        </span>
      </div>
      <StudyTitleListArea
        study_title_arr={study_title_arr}
        study_use_arr={study_use_arr}
        set_study_use_arr={set_study_use_arr}
        show_detail_title_seq_arr={show_detail_title_seq_arr}
        set_show_detail_title_seq_arr={set_show_detail_title_seq_arr}
        student_info={student_info}
        select_daily_study_title={myProps.select_daily_study_title}
        go_write_study_use_by_ajax={go_write_study_use_by_ajax}
        refresh_data={refresh_data}
        is_show_only_use={is_show_only_use}
        set_refresh={() => { set_refresh(!refresh); }}
      ></StudyTitleListArea>
    </div>
  );
};
export default StuStudyUseArea;