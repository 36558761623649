import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import SearchOrderRadio from "@/pcomponents/common/crud/write/radio/radio_arr";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import SearchArea from '@/pages/comp/basic/student/list/area/search_area';

function FindStudentPopup(props:any){
  const myProps={
    closePopup:()=>{},
    listOpt:{},
    "desk_info":null,
    "now_ymdhis":"",
    "now_lecture_data":null,
    callBackData:(data:any)=>{
      let optObj={
        info_arr:[],
        "desk_info":null,
        "now_ymdhis":"",
      };
    },//data = { info_arr:[],row_num:0,key:"" };
    ...props
  };
  const xColumnArr=getXcolumnJson();
  const listAggridRef= useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      s_grade:["student"],
      "s_stu_bookmark":strFunc.get_storage("s_stu_bookmark"),
      s_addon_class_user:"1",
      s_addon_link_user:"1",
      s_except_user_seq:"",
      ...myProps.listOpt
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});
  let now_date_json=DateFunc.get_date_json(new Date(myProps.now_ymdhis));
  let now_sec=strFunc.timeToSeconds(now_date_json.h,now_date_json.i,now_date_json.s);
  let now_next_sec=now_sec+(60*60);//+1시간
  let now_next_hi_json=strFunc.secondsToTimeJson(now_next_sec);
  let default_select_time={
    "start_h":now_date_json.h,
    "start_m":now_date_json.i,
    "end_h":now_next_hi_json.h,
    "end_m":now_next_hi_json.i,
  };
  if(!strFunc.is_empty(myProps.now_lecture_data)){
    now_next_sec=myProps.now_lecture_data.minute_num*60;
    now_next_hi_json=strFunc.secondsToTimeJson(now_next_sec);
    default_select_time={
      "start_h":myProps.now_lecture_data.start_h,
      "start_m":myProps.now_lecture_data.start_m,
      "end_h":now_next_hi_json.h,
      "end_m":now_next_hi_json.i,
    }
  }
  const [select_time,set_select_time]=useState(default_select_time);
  let select_time_term_minute=
    strFunc.timeToSeconds(select_time.end_h,select_time.end_m,0)
    -strFunc.timeToSeconds(select_time.start_h,select_time.start_m,0);
  if(select_time_term_minute!=0){
    select_time_term_minute=select_time_term_minute/60;
  }

  useEffect(() => {
    
  }, []);

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/list',pageData.listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const onChangeTimeRowInput=(key:string,val:string)=>{
    let time_col_arr=[
      "start_h","start_m",
      "end_h","end_m"
    ];
    if(strFunc.str_in_array(key,time_col_arr)!=-1){
      val=strFunc.comma(val);
      val=val.substring(0,2);
      if(key=="start_h"||key=="end_h"){
        if(parseInt(val)>=24){
          val="23";
        }
      }else{
        if(parseInt(val)>=60){
          val="59";
        }
      }
      if(parseInt(val)<0){
        val="0";
      }
    }
    set_select_time({
      ...select_time,
      [key]:val
    });
  };

  const goCallBackData=()=>{
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }else if(selected_row.length!=1){
      alert("한명만 선택해주세요.");
      return false;
    }

    myProps.callBackData({
      info_arr:selected_row,
      "desk_info":myProps.desk_info,
      "now_ymdhis":myProps.now_ymdhis,
      "select_time":select_time,
    });
    myProps.closePopup();
  };

  const cellClickedListener = useCallback( (params:any) => {
    var key=params.colDef.field;
    if(key=="a_user_name"||key=="a_user_id"){
      //openOnePopup("view",true);
    }
  }, []);

  const cellRenderer= (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;
    if(key=="row_view_class_name"){
      if(params.data.class_arr){
        let class_name_arr=[];
        for(let i=0;i<params.data.class_arr.length;i++){
          class_name_arr.push(params.data.class_arr[i]["class_name"]);
        }
        render_str=class_name_arr.join(" / ");;
      }
    }else if(key=="row_view_par_phone"){
      let a_user_phone=strFunc.autoHypenPhone(params.data.a_user_phone);
      let par_phone_num="학부모 미등록";
      if(params.data.link_user_arr&&params.data.link_user_arr[0]&&params.data.link_user_arr[0]["par_info"]){
        par_phone_num=params.data.link_user_arr[0]["par_info"]["a_user_phone"];
      }
      render_str=(
      <div>
        <div className="leading-5">{par_phone_num}</div>
        <div className="leading-5">{a_user_phone}</div>
      </div>);
    }
    return render_str;
  };
  const getRowHeight= useCallback( (params:any) => {
    return 45;
  }, []);

  return (
      <div>
        <SearchArea 
          listOpt={{...pageData.listOpt}}
          xColumnArr={{...xColumnArr}}
          list={list}
          ></SearchArea>
        <div className="btn-box-left relative h-8">
          <b>총 <span className="text-blue-600">{countInfo.tot}</span> 명</b>
          <span className="px-2">|</span>
          <SearchOrderRadio
            valueTextArr={[
              {"text":"등록순","value":"a_create_date DESC"},
              {"text":"이름순","value":"a_user_name"},
            ]}
            value={pageData.listOpt.order_id}
            handleInputChange={(e:any)=>{
              list({
                "now_page":1,
                "order_id":e.target.value
              });
            }}
          ></SearchOrderRadio>
          <div className="absolute right-0 top-0 btn-box-right">
            <button className="btn btn-dark" onClick={()=>{goCallBackData();}}>선택</button>
            <button className="btn btn-line-gray" onClick={()=>{myProps.closePopup();}}>닫기</button>
          </div>
        </div>
        <div className="text-center mt-1">
          할당시간
          <span className="mx-2">
            <input type="text" className="text-center" name="start_h" value={strFunc.str_pad({"str":select_time.start_h,"pad_length":2})} 
                onChange={(e:any)=>{onChangeTimeRowInput(e.target.name,e.target.value);}} style={{width:30}} />:
            <input type="text" className="text-center" name="start_m" value={strFunc.str_pad({"str":select_time.start_m,"pad_length":2})} 
              onChange={(e:any)=>{onChangeTimeRowInput(e.target.name,e.target.value);}} style={{width:30}} />
          </span>
          ~
          <span className="mx-2">
            <input type="text" className="text-center" name="end_h" value={strFunc.str_pad({"str":select_time.end_h,"pad_length":2})} 
              onChange={(e:any)=>{onChangeTimeRowInput(e.target.name,e.target.value);}} style={{width:30}} />:
            <input type="text" className="text-center" name="end_m" value={strFunc.str_pad({"str":select_time.end_m,"pad_length":2})} 
              onChange={(e:any)=>{onChangeTimeRowInput(e.target.name,e.target.value);}} style={{width:30}} />
          </span>
          ({select_time_term_minute}분)
        </div>
        <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{...xColumnArr}}
        list={list}
        gridOpt={{
          fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num:true,
          is_add_checkbox:false,
          floatingFilter:false,
          onGridReady:()=>{
            list({});
          },
          onCellClicked:cellClickedListener,
          cellRenderer:cellRenderer,
          getRowHeight:getRowHeight
        }}
        ></ListAggrid>
        <Paging now_page={pageData.listOpt.now_page}
          num_per_page={pageData.listOpt.num_per_page}
          total_rec={countInfo.tot} 
          onChangePage={(now_page:number)=>{list({now_page:now_page});}}
          onChangeNumPerPage={(num_per_page:number)=>{list({now_page:1,num_per_page:num_per_page});}}></Paging>
      </div>
  );
}

export default FindStudentPopup;