import Style from "../../css/style.module.css";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState, useCallback } from "react";
import RoomBoxArea from "./area/room_box";

const RoomListArea = forwardRef((props: any, ref) => {
  const myProps: any = {
    room_info_arr: [],
    now_ymdhis: "",
    open_find_stu_popup:(inData:any)=>{},
    open_view_desk_popup:(inData:any)=>{},
    is_detal_popup:true,
    ...props
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언

  }));

  let room_box_tags = myProps.room_info_arr.map((room_info: any, idx: number) => {
    return (
      <RoomBoxArea key={idx}
        room_info={room_info}
        now_ymdhis={myProps.now_ymdhis}
        open_find_stu_popup={myProps.open_find_stu_popup}
        open_view_desk_popup={myProps.open_view_desk_popup}
        is_detal_popup={myProps.is_detal_popup}
      ></RoomBoxArea>
    );
  });

  return (
    <div>
      <div className={Style.room_wrap} >
        {room_box_tags}
      </div>
    </div>
  );
});

export default RoomListArea;