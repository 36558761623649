import Style from "../css/style.module.css";
import { useState,useRef,useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import getXcolumnJson from "./xcolumn/list";
import { useSelector, useDispatch } from 'react-redux';
import strFunc from "@/lib/lyg/string";
import DateFunc from '@/lib/lyg/date_func';

const TodoListArea=()=>{
  const navigate = useNavigate();
  const xColumnArr=getXcolumnJson();
  const ListComponentRef=useRef<any>(null);
  let user=useSelector((state:any) => state.user);

  useEffect(()=>{
    
    list();
  },[]);

  const list = ()=>{
    let list_form_json:any={
      'order_id':'a_create_date DESC',
      "s_addon_comp_user":"1",
      "s_is_mcomp_public":"1",
      "max_limit_num":"5",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    list_form_json.s_stu_mcomp_seq=user.comp_seq;

    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/list',list_form_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        if(ListComponentRef.current){
          ListComponentRef.current.setInfoArr(response.data["data"]["info_arr"]);
        }
      }else{
        
      }
    });
  };

  const customCellRenderer=(params:any)=>{
    /*
    {
      "rowData":rowData,
      "idx":idx,
      "key":key,
      "value":row_val,
      "origin_val":origin_val,
    }
    */
    let render_str=params.value;
    if(params.key=="a_start_date"||params.key=="a_end_date"||params.key=="a_reserve_start_date"){
      render_str=render_str.substring(0,10);
    }else if(params.key=="row_view_state"){
      let state_str=params.rowData.a_state;
      if(xColumnArr.select_arr["a_state"]){
        for(let i=0;i<xColumnArr.select_arr["a_state"].length;i++){
          let tmp_option_row=xColumnArr.select_arr["a_state"][i];
          if(tmp_option_row["value"]==state_str){
            state_str=tmp_option_row["text"];
          }
        }
      }
      render_str=(
        <div>
          {state_str}
        </div>
      );
    }else if(params.key=="row_view_per"){
      let per_str=0;
      if(params.rowData.a_success_sort=="time"){
        let a_time_sec=parseInt(params.rowData.a_time_sec);
        let a_success_time_sec=parseInt(params.rowData.a_success_time_sec);
        if(a_time_sec!=0&&a_success_time_sec!=0){
          per_str=Math.round(a_time_sec/a_success_time_sec);
        }
      }else{
        let a_amount_num=parseInt(params.rowData.a_amount_num);
        let a_success_amount=parseInt(params.rowData.a_success_amount);
        if(a_amount_num!=0&&a_success_amount!=0){
          per_str=Math.round(a_amount_num/a_success_amount);
        }
      }
      if(params.rowData.a_is_success=="1"){
        per_str=100;
      }
      let per_div_style:any={};
      if(per_str>0){
        per_div_style["color"]="#979705";
      }
      if(per_str>=100){
        per_div_style["color"]="blue";
      }
      render_str=(
        <div style={per_div_style}>
          {per_str} %
        </div>
      );
    }else if(params.key=="row_view_class"){
      if(params.rowData.stu_info&&params.rowData.stu_info.class_arr){
        let class_name_arr=[];
        let tmp_class_arr_len=params.rowData.stu_info.class_arr.length;
        for(let i=0;i<tmp_class_arr_len;i++){
          class_name_arr.push(params.rowData.stu_info.class_arr[i].class_name);
        }
        render_str=class_name_arr.join(",");
      }
    }else if(params.key=="row_view_school"){
      if(params.rowData.stu_info){
        render_str=params.rowData.stu_info["a_school_name"];
      }
    }else if(params.key=="a_stu_name"){
      let tmp_stu_name_style={
        color:"#3FAC96",
        textDecoration : "underline",
        fontSize:15
      };
      render_str=(
        <span style={tmp_stu_name_style} >{render_str}</span>
      );
    }else if(params.key=="row_view_start_time"){
      render_str="";
      if(params.rowData.a_start_date){
        render_str=DateFunc.get_date_format(new Date(params.rowData.a_start_date),"h:i");
      }
    }else if(params.key=="row_view_end_time"){
      render_str="";
      if(params.rowData.a_end_date){
        render_str=DateFunc.get_date_format(new Date(params.rowData.a_end_date),"h:i");
      }
    }
    return render_str;
  };

  return (
    <div>
      <div className={Style.box_title_wrap}>
        학습현황
        <a className={Style.box_title_right_link} onClick={()=>{navigate("/comp/plan/todo/stu_todo/list");}} >
          바로가기{">"}
        </a>
      </div>
      <div className="p-1">
        <ListComponent
          ref={ListComponentRef}
          xColumnArr={xColumnArr}
          gridOpt={{
            is_add_idx_num:false,
            is_add_checkbox:false,
            width:"99%",
            height:"25vh",
            td_align:"center",
            customCellRenderer:customCellRenderer,
            class_name_json:{
              table_div:"dashboard_list_div",
              td_div:"row-col-div",
              input_check:"chk_row",
              row_input:"row-input",
              data_row_tr:"data_row_tr",
              active_row:"active",
              empty_con_div:"list_empty_content_div",
            }
          }}
        ></ListComponent>
      </div>
    </div>
  );
};
export default TodoListArea;