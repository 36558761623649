//import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";
import strFunc from "@/lib/lyg/string";

function SelectBoxBar(props:any){
   let myProps={
    valueTextArr:[],//[{"value":"","text":""}]
    value:"",
    name:"",
    on_change:(inData:any)=>{},//{name:"",value:"",text:""}
    is_arr_value:false,
    value_split_str:",",
    style_type:"",
    box_style:{},
    ...props
  };

  let value_arr:any=[];
  if(myProps.is_arr_value){
    let is_empty_val=strFunc.is_empty(myProps.value);
    if(is_empty_val){
      if(myProps.value=="0"){
        is_empty_val=false;
      }
    }
    if(is_empty_val==false){
      value_arr=myProps.value.split(myProps.value_split_str);
    }
  }else{
    value_arr=[myProps.value];
  }

  const on_change=(inData:any)=>{
    let opt_obj={
      value:"",
      text:"",
      ...inData
    };

    let change_value=opt_obj.value;
    if(myProps.is_arr_value==false){
      if(change_value==myProps.value){
        change_value="";
      }
    }else{
      //있다면 없앤다.
      if(strFunc.str_in_array(change_value,value_arr)!=-1){
        let new_value_arr=[];
        for(let i=0;i<value_arr.length;i++){
          if(value_arr[i]==change_value){
            
          }else{
            new_value_arr.push(value_arr[i]);
          }
        }
        new_value_arr.sort();
        change_value=new_value_arr.join(myProps.value_split_str);
      }else{
        //없다면 추가.
        let new_value_arr=[...value_arr];
        new_value_arr.push(change_value);
        new_value_arr.sort();
        change_value=new_value_arr.join(myProps.value_split_str);
      }
    }
    myProps.on_change({
      name:myProps.name,
      value:change_value,
      text:opt_obj.text,
    });
  };

  let class_name_json={
    wrap:"select_box_bar",
    item:"select_box_bar_item",
    active:"active",
  };
  if(myProps.style_type=="small"){
    class_name_json={
      wrap:"select_box_bar_small",
      item:"select_box_bar_item_small",
      active:"active",
    };
  }
  let init_box_style:any={
    ...myProps.box_style
  };

  const get_box_tags=()=>{
    let box_tags=myProps.valueTextArr.map((item:any,idx:number)=>{
      let box_class_name=class_name_json.item;
      
      if(strFunc.str_in_array(item.value,value_arr)!=-1){
        box_class_name+=" "+class_name_json.active;
      }
      return (
        <div key={idx} className={box_class_name} 
          onClick={()=>{
            on_change({
              value:item.value,
              text:item.text
            });
          }}
          style={init_box_style}>
          {item.text}
        </div>
      );
    });
    return box_tags;
  };
  
  return (
  <div className={class_name_json.wrap}>
    {get_box_tags()}
  </div>
  );
};
export default SelectBoxBar;