import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"month_prescribe",
    list_id:"month_prescribe_list",
    x_column_list_arr:[
      {"key":"a_subject_name","name":"과목","width":"70","is_show":"1"},
      {"key":"a_stu_name","name":"원생명","width":"100","is_show":"1"},
      {"key":"a_writer_name","name":"작성자","width":"100","is_show":"1"},
      {"key":"a_is_success","name":"처방","width":"100","is_show":"1"},
      {"key":"row_view_manage","name":"상세","width":"110","is_show":"1"},
      {"key":"row_view_date","name":"날짜","width":"140","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':100,
      'order_id':'a_is_success, a_reserve_date DESC, a_create_date DESC',
      'order_type':'',
      's_date_type':'a_reserve_date',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
      "s_addon_user":"1",
      "s_stu_seq":"",
      "s_stu_name_like":"",
      "s_stu_phone_like":"",
      "s_stu_school_name_like":"",
      "s_title_like":"",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_ymd","a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;