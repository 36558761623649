import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';
import strFunc from '@/lib/lyg/string';
import RadioListArea from "@/pcomponents/common/crud/write/radio/radio_arr";
import { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";

const TopStuInfoArea = forwardRef((props: any, ref) => {
  let myProps = {
    info: {},
    set_info: (inData: any) => { },
    refresh_data: () => { },
    is_view_mode: false,
    is_update: false,
    xColumnArr: {},
    Style: {},
    set_is_show_basic: (inData: any) => { },
    ...props
  };
  let info = myProps.info;
  let is_view_mode = myProps.is_view_mode;
  let Style = myProps.Style;

  let user = useSelector((state: any) => state.user);
  const [stu_num_msg_data, set_stu_num_msg_data] = useState({
    is_show: false,
    msg: "사용가능",
    color: "green",
  });
  const [check_id_msg, set_check_id_msg] = useState<any>({
    "msg": "",
    "is_able": true,
  });
  const [check_phone_msg, set_check_phone_msg] = useState<any>({
    "msg": "",
    "is_able": true,
  });

  useEffect(() => {

  }, []);
  useImperativeHandle(ref, () => ({
    checkStuNumOfChildUser
  }));

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    myProps.set_info({
      ...info,
      ...{ [key]: date_str }
    });
  };
  const handleInputChange = (inData: any) => {
    let opt_obj = {
      name: "",
      value: "",
      ...inData
    };
    let value = opt_obj.value;
    const name = opt_obj.name;

    if (name == "a_user_phone") {
      value = strFunc.autoHypenPhone(value);
    } else if (name == "a_stu_num") {
      value = strFunc.getNumber2(value);
    }
    myProps.set_info({
      ...info,
      ...{ [name]: value }
    });

    if (name == "a_user_phone") {
      checkPhoneOfChildUser(value);
    } else if (name == "a_user_id") {
      checkUserIdByAjax(value);
    }
  };

  const checkStuNumOfChildUser = (stu_num: string) => {
    if (stu_num == "" || stu_num == "0") {
      return false;
    }
    stu_num = strFunc.getNumber2(stu_num);

    //이미 있는지 체크
    let checkFormData = {
      "s_stu_num_now": stu_num,
      "s_except_user_seq": info.a_seq,
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', checkFormData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length != 0) {
            let u_info = response.data["data"]["info_arr"][0];
            set_stu_num_msg_data({
              is_show: true,
              msg: "해당 출결번호는 이미있는 있습니다. " + u_info["a_user_name"] + "",
              color: "#ff5757",
            });
          } else {
            set_stu_num_msg_data({
              is_show: true,
              msg: "사용가능",
              color: "green",
            });
          }
        }
      });
  };

  const makeInviteCode = () => {
    if (info.a_seq == "") {
      alert("유저키 없음.");
      return false;
    }
    if (!confirm("초대코드를 생성 하시겠습니까?")) {
      return false;
    }
    let inivte_make_code_from_data = {
      "mcomp_seq": "",
      "user_seq": info.a_seq,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/basic/invite_code/make_code',
      inivte_make_code_from_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          myProps.set_info({
            ...info,
            ...{ "invite_code": response.data["data"]["invite_code"] }
          });
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const checkUserIdByAjax = (user_id: string) => {
    if (strFunc.is_empty(user_id)) {
      set_check_id_msg({
        is_able: false,
        msg: (
          <div style={{ "color": "red" }}>입력필요.</div>
        )
      });
      return false;
    }
    //이미 있는지 체크
    let check_user_id_form_json = {
      "s_id": user_id,
      "s_except_user_seq": info.a_seq,
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', check_user_id_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length != 0) {
            let u_info = response.data["data"]["info_arr"][0];
            set_check_id_msg({
              is_able: false,
              msg: (
                <div style={{ "color": "red" }}>이미있는 아이디 입니다.</div>
              )
            });
          } else {
            set_check_id_msg({
              is_able: true,
              msg: (
                <div style={{ "color": "green" }}>사용가능.</div>
              )
            });
          }
        }
      });
  };

  const checkPhoneOfChildUser = (phone: string) => {
    if (phone.length != 13) {
      return false;
    }
    //이미 있는지 체크
    let checkPhoneFormData = {
      "s_phone": phone,
      "s_except_user_seq": info.a_seq,
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', checkPhoneFormData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length != 0) {
            let u_info = response.data["data"]["info_arr"][0];
            set_check_phone_msg({
              is_able: false,
              msg: (
                <div style={{ "color": "red" }}>중복된 이미있는 핸드폰 번호가 있습니다.</div>
              )
            });
            myProps.set_info({
              ...info,
              "a_user_phone": ""
            });
          } else {
            set_check_phone_msg({
              is_able: true,
              msg: (
                <div style={{ "color": "green" }}>사용가능.</div>
              )
            });
          }
        }
      });
  };

  const delete_on_lecture_time_table = () => {
    if (strFunc.is_empty(info.a_seq)) {
      alert("학생키가 없습니다.");
      return false;
    }
    if (!confirm("해당 학생의 시간표를 삭제하시겠습니까?")) {
      return false;
    }
    let del_time_form_data = {
      "user_seq_arr": [info.a_seq],
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/delete_user_time', del_time_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          myProps.refresh_data();
          alert(response.data["msg"]);
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const supply_d_arr: number[] = [];
  for (let i = 1; i <= 30; i++) {
    supply_d_arr.push(i);
  }
  const supply_d_options = supply_d_arr.map((item: any, idx: number) => {
    return (
      <option key={idx} value={item}>{item}</option>
    );
  });

  let stu_state_str = "";
  if (myProps.xColumnArr.select_arr) {
    for (let i = 0; i < myProps.xColumnArr.select_arr.a_stu_state.length; i++) {
      if (myProps.xColumnArr.select_arr.a_stu_state[i]["value"] == info.a_stu_state) {
        stu_state_str = myProps.xColumnArr.select_arr.a_stu_state[i]["text"];
      }
    }
  }

  return (
    <div className="mt-1">
      <div className="text-right">
        {(user.user_grade == "master" && myProps.is_update) &&
          <button className="btn btn-red" onClick={() => { delete_on_lecture_time_table(); }}>
            시간표제거
            {(info.lecture_arr && info.lecture_arr.length > 0) ? "(" + info.lecture_arr.length + ")" : ""}
          </button>
        }
        <button className="btn btn-gray ml-1" onClick={() => { myProps.set_is_show_basic(false); }}>기본정보 안보이게</button>
      </div>
      <div className="write_table_small mt-1">
        <table>
          <colgroup>
            <col width={"10%"}></col>
            <col width={"15%"}></col>
            <col width={"10%"}></col>
            <col width={"15%"}></col>
            <col width={"10%"}></col>
            <col width={"15%"}></col>
            <col width={"10%"}></col>
            <col width={"15%"}></col>
          </colgroup>
          <tbody>
            <tr>
              <th>
                아이디
                <span className="emphasis_star">*</span>
              </th>
              <td>
                {is_view_mode ?
                  info.a_user_id
                  :
                  <>
                    <input type="text" className="row-input" value={info.a_user_id}
                      onChange={(e: any) => { handleInputChange({ name: "a_user_id", value: e.target.value }); }}
                      placeholder="아이디" />
                    {check_id_msg.msg}
                  </>
                }
              </td>
              <th>
                비밀번호
                <span className="emphasis_star">*</span>
              </th>
              <td>
                {is_view_mode ?
                  <span></span>
                  :
                  <input type="password" className="row-input" value={info.a_user_pw ? info.a_user_pw : ""}
                    onChange={(e: any) => { handleInputChange({ name: "a_user_pw", value: e.target.value }); }}
                    placeholder="비밀번호" />
                }
              </td>
              <th>핸드폰</th>
              <td>
                {is_view_mode ?
                  info.a_user_phone
                  :
                  <>
                    <input type="text" className="row-input" value={info.a_user_phone}
                      onChange={(e: any) => { handleInputChange({ name: "a_user_phone", value: e.target.value }); }}
                      placeholder="000-0000-0000" />
                    {check_phone_msg.msg}
                  </>
                }
              </td>
              <th>상태</th>
              <td>
                {is_view_mode ?
                  stu_state_str
                  :
                  <select className="row-input" value={info.a_stu_state}
                    onChange={(e: any) => { handleInputChange({ name: "a_stu_state", value: e.target.value }); }} >

                    {myProps.xColumnArr.select_arr.a_stu_state.map((item: any, idx: number) => {
                      return (
                        <option key={idx} value={item.value}>{item.text}</option>
                      );
                    })}
                  </select>
                }
              </td>
            </tr>
            <tr>
              <th>로그인허용</th>
              <td>
                {is_view_mode ?
                  <span>{info.a_is_login == "1" ? "예" : "아니오"}</span>
                  :
                  <RadioListArea
                    valueTextArr={myProps.xColumnArr.select_arr.a_is_login}
                    value={info.a_is_login}
                    name="a_is_login"
                    handleInputChange={(e: any) => {
                      handleInputChange({ "name": "a_is_login", value: e.target.value });
                    }}
                  ></RadioListArea>
                }
              </td>
              <th>휴/퇴원일</th>
              <td>
                {is_view_mode ?
                  info.a_stu_end_date
                  :
                  <DatePicker
                    selected={info.a_stu_end_date != "" ? new Date(info.a_stu_end_date) : null}
                    onChange={(date: Date) => {
                      onChangeDatePicker("a_stu_end_date", date);
                    }}
                    locale={ko}
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomInputWrite />}
                    renderCustomHeader={customHeaderFunc}
                  />
                }
              </td>
              <th>휴/퇴원메모</th>
              <td colSpan={3}>
                {is_view_mode ?
                  info.a_stu_end_memo
                  :
                  <input type="text" className="row-input" name="a_stu_end_memo" value={info.a_stu_end_memo}
                    onChange={(e: any) => { handleInputChange({ name: "a_stu_end_memo", value: e.target.value }); }}
                    placeholder="메모" />
                }
              </td>
            </tr>
            <tr>
              <th>출결번호</th>
              <td>
                {is_view_mode ?
                  strFunc.getNumber2(info.a_stu_num)
                  :
                  <>
                    <input type="text" className="row-input" name="a_stu_num" value={strFunc.getNumber2(info.a_stu_num)}
                      onChange={(e: any) => {
                        handleInputChange({ name: "a_stu_num", value: e.target.value });
                        if (e.target.value != "") {
                          checkStuNumOfChildUser(e.target.value);
                        }
                      }}
                      placeholder="출결번호" style={{ width: 120 }} />
                    <button className="btn-m3 btn-line-yellow-green ml-1" onClick={() => { checkStuNumOfChildUser(info.a_stu_num); }} >
                      중복체크
                    </button>

                    {stu_num_msg_data.is_show &&
                      <div>
                        <span style={{ color: stu_num_msg_data.color }}>{stu_num_msg_data.msg}</span>
                      </div>
                    }
                  </>
                }
              </td>
              <th>성별</th>
              <td>
                <RadioListArea
                  valueTextArr={props.xColumnArr.select_arr.a_gender}
                  value={info.a_gender}
                  name="a_gender"
                  handleInputChange={(e: any) => {
                    if (is_view_mode == false) {
                      handleInputChange({ name: "a_gender", value: e.target.value });
                    }
                  }}
                ></RadioListArea>
              </td>
              <th>청구일</th>
              <td>
                {is_view_mode ?
                  info.a_stu_supply_day + "일"
                  :
                  <select className="row-input" name="a_stu_supply_day" value={info.a_stu_supply_day}
                    onChange={(e: any) => { handleInputChange({ name: "a_stu_supply_day", value: e.target.value }); }} >
                    <option value="">선택없음</option>
                    {supply_d_options}
                  </select>
                }
              </td>
              <th>수납기본할인</th>
              <td>
                {is_view_mode ?
                  strFunc.comma(info.a_stu_supply_discount) + "원"
                  :
                  <>
                    <input type="text" className="row-input" name="a_stu_supply_discount"
                      value={info.a_stu_supply_discount}
                      onChange={(e: any) => { handleInputChange({ name: "a_stu_supply_discount", value: e.target.value }); }}
                      placeholder="할인가"
                      style={{ textAlign: "center", width: 130 }} /> 원
                  </>
                }
              </td>
            </tr>
            <tr>
              <th>생년월일</th>
              <td>
                {is_view_mode ?
                  info.a_stu_birth_date
                  :
                  <DatePicker
                    selected={info.a_stu_birth_date != "" ? new Date(info.a_stu_birth_date) : null}
                    onChange={(date: Date) => {
                      onChangeDatePicker("a_stu_birth_date", date);
                    }}
                    locale={ko}
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomInputWrite />}
                    renderCustomHeader={customHeaderFunc}
                  />
                }
              </td>
              <th style={{ fontSize: "12px" }}>현금영수증구분</th>
              <td>
                {is_view_mode ?
                  info.a_cash_receipt_sort
                  :
                  <input type="text" className="row-input" name="a_cash_receipt_sort"
                    value={info.a_cash_receipt_sort}
                    onChange={(e: any) => { handleInputChange({ name: "a_cash_receipt_sort", value: e.target.value }); }}
                    placeholder="현금영수증구분"
                  />
                }
              </td>
              <th style={{ fontSize: "11px" }}>현금영수증발급번호</th>
              <td>
                {is_view_mode ?
                  info.a_cash_receipt_num
                  :
                  <input type="text" className="row-input" name="a_cash_receipt_num"
                    value={info.a_cash_receipt_num}
                    onChange={(e: any) => { handleInputChange({ name: "a_cash_receipt_num", value: e.target.value }); }}
                    placeholder="현금영수증발급번호"
                  />
                }
              </td>
              <th>초대코드</th>
              <td colSpan={3}>
                {info["invite_code"]}
                {info["comp_link_info"] &&
                  <span style={{ color: "blue", marginLeft: 5 }}>
                    (가입됨, {info["comp_link_info"]["a_main_user_name"]})
                  </span>
                }
                {myProps.is_update &&
                  <span>
                    {strFunc.is_empty(info["invite_code"]) &&
                      <button className="btn btn-dark ml-2" onClick={() => { makeInviteCode(); }}>생성</button>
                    }
                  </span>
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
});
export default TopStuInfoArea;