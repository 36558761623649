import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

class TdDataFunc {
  static setting_data_at_td_data_arr = (inData: any) => {
    let opt_obj = {
      td_data_arr: [],
      mprescribe_arr:[],
      Style:{},
      is_show_counseler:false,
      open_stu_layer_popup:(inData:any)=>{},
      open_write_prescribe_popup:(inData:any)=>{},
      ...inData,
    };
    let td_data_arr = opt_obj["td_data_arr"];
    let td_data_arr_len=td_data_arr.length;
    let mprescribe_arr=opt_obj["mprescribe_arr"];
    let Style=opt_obj["Style"];
    let is_show_counseler=opt_obj["is_show_counseler"];

    let select_stu_grade_option_arr=XcolumnFunc.getSortByTableKey({'table':'user','key':'a_stu_grade'});

    for(let td_i=0;td_i<td_data_arr_len;td_i++){
      let td_data=td_data_arr[td_i];
      let td_mprescribe_arr=[];
      for(let i=0;i<mprescribe_arr.length;i++){
        let mprescribe_info=mprescribe_arr[i];
        if(mprescribe_info["a_reserve_date"]==td_data["date_str"]){
          td_mprescribe_arr.push(mprescribe_info);
        }
      }
      td_data_arr[td_i]["mprescribe_arr"]=td_mprescribe_arr;
      td_data_arr[td_i]["content"]="";
      let row_success_cnt=0;
      for(let i=0;i<td_mprescribe_arr.length;i++){
        if(td_mprescribe_arr[i]["a_is_success"]=="1"){
          row_success_cnt++;
        }
      }
      let td_title_str="";
      let content_td_list:any="";
      if(td_mprescribe_arr.length>0){
        td_title_str=row_success_cnt+"/"+td_mprescribe_arr.length;
        let td_data_row_tags=td_mprescribe_arr.map((item:any,idx:number)=>{
          let comment_cnt=0;
          if(item.comment_arr&&item.comment_arr.length>0){
            comment_cnt=item.comment_arr.length;
          }
          let content_row_style:any={};
          if(comment_cnt>0){
            content_row_style["background"]="#ed3a3a";
            let comment_last_idx=item.comment_arr.length-1;
            if(item.comment_arr[comment_last_idx]["a_qna_sort"]=="a"){
              content_row_style["background"]="#de8003";
            }
          }
          if(item["a_is_success"]=="1"){
            content_row_style["background"]="#a4b75d";
          }
          let content_row_sub_text:any="";
          if(is_show_counseler){
            content_row_sub_text=strFunc.cut_str(item.a_writer_name,3,"");
          }
          let stu_name_str=item.a_stu_name;
          let stu_grade_str="";
          let stu_school_name="";
          if(item.stu_info){
            stu_name_str=item.stu_info["a_user_name"];
            stu_grade_str=item.stu_info["a_stu_grade"];
            stu_school_name=item.stu_info["a_school_name"];
            if(select_stu_grade_option_arr){
              for(let i=0;i<select_stu_grade_option_arr.length;i++){
                if(select_stu_grade_option_arr[i]["value"]==stu_grade_str){
                  stu_grade_str=select_stu_grade_option_arr[i]["text"];
                }
              }
            }
          }

          let subject_span_style:any={};
          if(item.a_subject_name=="수학"){
            subject_span_style["color"]="#a4d8ff";
          }

          let content_title_str=stu_name_str+" "+stu_school_name+" "+stu_grade_str+"\n";
          content_title_str+= "1.진도전략:\n";
          content_title_str+=item.a_progress_reason+"\n\n";
          content_title_str += "2.현재진도:\n";
          content_title_str+=item.a_progress+"\n\n";
          content_title_str += "3.성장한부분:\n";
          content_title_str+=item.a_grow+"\n\n";
          content_title_str += "4.발견:\n";
          content_title_str+=item.a_lack+"\n\n";
          content_title_str += "5.학습처방:\n";
          content_title_str+=item.a_content+"\n\n";
          content_title_str += "작성일:"+item.a_create_date+" "+item.a_writer_name+"\n";
          if (item.a_is_success == "1") {
            content_title_str += "처방일:"+item.a_success_date+"\n";
          }

          if(comment_cnt>0){
            content_title_str+="질문\n";
            for(let i=0;i<comment_cnt;i++){
              let comment_idx=i+1;
              let comment_info=item.comment_arr[i];
              content_title_str+=comment_idx+"."+comment_info["a_responser"]+" "+comment_info["a_create_date"]+"\n";
              content_title_str+="  "+comment_info["a_message"]+"\n";
            }
          }
          
          return (
            <div key={idx} className={Style.content_td_row}
              style={content_row_style} 
              onClick={()=>{
                opt_obj.open_stu_layer_popup({
                  mprescribe_info:item
                });
              }}
              title={content_title_str} >
              <span>{strFunc.cut_str(stu_name_str,4,"")}</span>
              {comment_cnt>0&&
                <span>({comment_cnt})</span>
              }
              <span className={Style.content_td_row_sub_text} style={subject_span_style}>
                {item.a_subject_name}
              </span>
              <div className={Style.content_td_row_state_box}>
                {content_row_sub_text}
              </div>
            </div>
          );
        });
        content_td_list=(
          <div className={Style.content_td_list}>
            {td_data_row_tags}
          </div>
        );
      }
      td_data_arr[td_i]["content"]=(
        <div className={Style.content_td_wrap}>
          <button className={Style.content_td_center_plus_btn}
            onClick={(e:any)=>{
              opt_obj.open_write_prescribe_popup({
                select_date:td_data["date_str"],
              });
            }}>+</button>
          <div className={Style.content_td_title_wrap}>
            {td_title_str}
          </div>
          {content_td_list}
        </div>
      );
    }

    return td_data_arr;
  };
}
export default TdDataFunc;