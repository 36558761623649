import { useState, useRef, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import LygCalendarFunc from '@/lib/lyg/calendar';
import TopYearMonthArea from "./area/top_year_month";
import DataListArea from "./area/list";
import Style from "./css/style.module.css";
import TdDataFunc from "./func/td_data_func";
import { useNavigate } from 'react-router-dom';

const CalendarTable = forwardRef((props: any, ref) => {
  const myProps = {
    "listOpt": {},
    "select_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
    teacher_info_arr: [],
    "onSearchDateChange": (inData: any) => { },
    go_search_user_list: (inData: any) => { },
    show_write_counsel_todo: (inData: any) => { },
    open_view_counsel_todo: (inData: any) => { },
    show_write_diary_page: (inData: any) => { },
    list: (inData: any) => { },
    ...props
  };
  const navigate = useNavigate();
  const [select_date, set_select_date] = useState(myProps.select_date);
  const [stu_info_arr, set_stu_info_arr] = useState<any>([]);
  const [counsel_todo_arr, set_counsel_todo_arr] = useState([]);
  const [study_diary_arr, set_study_diary_arr] = useState<any>([]);
  const [is_show_list, set_is_show_list] = useState(false);
  const [is_show_counseler, set_is_show_counseler] = useState(false);
  const [is_show_sdiary, set_is_show_sdiary] = useState(true);
  const DataListAreaRef = useRef<any>(null);

  const now_date_json = DateFunc.get_date_json(new Date());
  let day_num_list = [0, 1, 2, 3, 4, 5, 6];
  let day_name_list = ["일", "월", "화", "수", "목", "금", "토"];
  let cal_data=LygCalendarFunc.get_cal_data_by_select_date(select_date);
  let pre_month_date = cal_data["pre_month_date"];
  let after_month_date = cal_data["after_month_date"];

  const setInitData = (inOptObj: any) => {
    if (inOptObj == undefined) {
      inOptObj = {};
    }
    let optObj = {
      select_date: myProps.select_date,
      stu_info_arr: [],
      ...inOptObj
    };
    set_select_date(optObj.select_date);
    set_stu_info_arr(optObj.stu_info_arr);
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData, set_stu_info_arr, set_counsel_todo_arr,
    set_study_diary_arr
  }));

  const handleDateChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    let change_searchDate = select_date;
    let tmp_year = select_date.substring(0, 4);
    let tmp_month = select_date.substring(5, 7);
    let tmp_day = select_date.substring(8, 10);
    let tmp_date_json: any = DateFunc.get_date_json(new Date(select_date));
    if (name == "s_month") {
      tmp_month = value;
    } else if (name == "s_year") {
      tmp_year = value;
    }
    if (parseInt(tmp_day) > tmp_date_json.last_day) {
      tmp_day = strFunc.str_pad({ "str": tmp_date_json.last_day, "pad_length": 2 });
    }
    change_searchDate = tmp_year + "-" + tmp_month + "-" + tmp_day;

    set_select_date(change_searchDate);
    if (myProps.onSearchDateChange) {
      myProps.onSearchDateChange(change_searchDate);
    }
  };

  const get_col_tags = () => {
    let col_tags = day_num_list.map((item: any, idx: number) => {
      return (
        <col key={idx} width="14%"></col>
      );
    });
    return col_tags;
  };

  const get_th_tags = () => {
    let th_tags = day_num_list.map((item: number, idx: number) => {
      let th_title = day_name_list[item];
      let th_text_class_name = "th_text";
      if (idx == 0) {
        th_text_class_name += " th_text_sun";
      } else if (idx == 6) {
        th_text_class_name += " th_text_sat";
      }
      return (
        <th key={idx}>
          <div className={th_text_class_name}>{th_title}</div>
        </th>
      );
    });
    return th_tags;
  };

  //counsel_todo_arr 날짜별로 분리
  let ctodo_split_day_data = TdDataFunc.get_split_day_data_of_counsel_todo_arr({
    counsel_todo_arr: counsel_todo_arr,
    study_diary_arr: study_diary_arr
  });

  const get_td_tags = (inData: any) => {
    let opt_obj = {
      "td_week_data_arr": [],
      ...inData
    };

    let td_tags = opt_obj["td_week_data_arr"].map((item: any, idx: number) => {
      let td_tag_class_name = "";
      let td_class_name = "td_title";
      if (item.is_this_month) {
        td_class_name += " td_title_select_month";
      }
      if (item.day_of_week == 0) {
        td_class_name += " td_title_sun";
      } else if (item.day_of_week == 6) {
        td_class_name += " td_title_sat";
      }
      if (item.is_this_month
        && cal_data.select_month_json.m == now_date_json.m
        && cal_data.select_month_json.Y == now_date_json.Y
        && item.day == parseInt(now_date_json.d)) {

        td_tag_class_name = "td_title_now_date";
      }

      let select_date_str = item["year"] + "-" + DateFunc.get_digit_str(item["month"]) + "-" + DateFunc.get_digit_str(item["day"]);

      return (
        <td key={idx} className={td_tag_class_name}>
          <div className={td_class_name} >
            {item.day}
          </div>
          <div className="td_content_wrap">
            {item.content}
          </div>
        </td>
      );
    });
    return td_tags;
  };

  const get_tr_tags = () => {
    let td_data_arr = LygCalendarFunc.get_td_data_arr({cal_data:cal_data});
    td_data_arr = TdDataFunc.setting_data_at_td_data_arr({
      td_data_arr: td_data_arr,
      ctodo_split_day_data: ctodo_split_day_data,
      listOpt: myProps.listOpt,
      Style: Style,
      open_view_counsel_todo: myProps.open_view_counsel_todo,
      show_write_counsel_todo: myProps.show_write_counsel_todo,
      show_write_diary_page:myProps.show_write_diary_page,
      is_show_counseler: is_show_counseler,
      is_show_sdiary: is_show_sdiary,
    });
    let td_week_arr =LygCalendarFunc.get_td_week_arr_by_td_data_arr({
      cal_data:cal_data,
      td_data_arr:td_data_arr,
    });
    let tr_tags = td_week_arr.map((item: any, idx: number) => {
      let td_tags = get_td_tags({
        "td_week_data_arr": item
      });
      return (
        <tr key={idx}>
          {td_tags}
        </tr>
      );
    });
    return tr_tags;
  };

  return (
    <div>
      <TopYearMonthArea
        select_date={select_date}
        set_select_date={set_select_date}
        onSearchDateChange={myProps.onSearchDateChange}
        handleDateChange={handleDateChange}
        pre_month_date={pre_month_date}
        after_month_date={after_month_date}
      ></TopYearMonthArea>
      <div className="basic_cal_middle_wrap">
        <div className="basic_cal_middle_tab_wrap">
          <button className="btn-m btn-yellowish2 mr-1" onClick={() => { set_is_show_list(!is_show_list); }}>
            {is_show_list ? "달력" : "리스트"}
          </button>
          <select className="search-input ml-2" value={myProps.listOpt.s_counseler_seq}
            style={{ width: 80 }}
            onChange={(e: any) => {
              myProps.list({ s_counseler_seq: e.target.value });
            }}>
            <option value="">상담자</option>
            {myProps.teacher_info_arr.map((item: any, idx: number) => {
              return (
                <option key={idx} value={item.a_seq} >{item.a_user_name}</option>
              );
            })}
          </select>

          <span className="ml-2">
            <input type="text" className="search-input" value={myProps.listOpt.s_stu_name_like} onChange={(e: any) => {
              myProps.list({ s_stu_name_like: e.target.value });
            }} style={{ width: 70 }} placeholder="학생" />
          </span>
          <span className="ml-2">
            <select className="search-input" value={myProps.listOpt.s_is_success} onChange={(e: any) => {
              myProps.list({ s_is_success: e.target.value });
            }} style={{ width: 58 }} >
              <option value="" >상태</option>
              <option value="1" >완료</option>
              <option value="empty" >미완료</option>
            </select>
          </span>
          <span className="basic_search_item_book_mark"
            onClick={() => {
              let change_s_stu_bookmark = myProps.listOpt.s_stu_bookmark;
              if (change_s_stu_bookmark == "1") {
                change_s_stu_bookmark = "";
              } else {
                change_s_stu_bookmark = "1";
              }
              strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
              myProps.list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
              myProps.go_search_user_list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
            }}>
            {myProps.listOpt.s_stu_bookmark == "1" ? "★" : "☆"}
          </span>
          <span className="ml-2">
            <label>
              <input type="checkbox" checked={is_show_counseler} onChange={(e: any) => {
                set_is_show_counseler(e.target.checked);
              }} /> 상담자표시
            </label>
          </span>
          <span className="ml-2">
            학습일지:
            <select className="search-input ml-2" value={myProps.listOpt.study_diary_s_writer_seq}
              style={{ width: 80 }}
              onChange={(e: any) => {
                myProps.list({ study_diary_s_writer_seq: e.target.value });
              }}>
              <option value="">상담자</option>
              {myProps.teacher_info_arr.map((item: any, idx: number) => {
                return (
                  <option key={idx} value={item.a_seq} >{item.a_user_name}</option>
                );
              })}
            </select>
            <span className="ml-2">
              <label>
                <input type="checkbox" checked={is_show_sdiary} onChange={(e: any) => {
                  set_is_show_sdiary(e.target.checked);
                }} /> 학습일지표시
              </label>
            </span>
          </span>
        </div>
        <div className="basic_cal_middle_right">
          <span>
            <button className="btn btn-gray mr-1" onClick={()=>{navigate("/comp/basic/counsel/list");}}>상담관리</button>
          </span>
          {is_show_list == false ? <>
            <span className="basic_cal_middle_right_state_span">
              완료 O
            </span>
            <span className="basic_cal_middle_right_state_span ml-2" style={{ "background": "#EAB358" }}>
              준비 △
            </span>
            <span className="basic_cal_middle_right_state_span ml-2" style={{ "background": "#DB6C58" }}>
              미완료 X
            </span>
          </> :
            <>
              <span style={{ color: "#379d89", fontSize: "13px" }}>총 {counsel_todo_arr.length} 건</span>
              <button className="btn-m2 btn-gray ml-2" onClick={() => { DataListAreaRef.current.goExcelDown(); }} >엑셀</button>
              <button className="btn-m2 btn-gray ml-2" onClick={() => { DataListAreaRef.current.change_state_by_ajax("complete"); }} >완료처리</button>
              <button className="btn-m2 btn-gray ml-1" onClick={() => { DataListAreaRef.current.change_state_by_ajax("ready"); }} >완료취소</button>
              <button className="btn-m2 btn-red ml-1" onClick={() => { DataListAreaRef.current.go_del_counsel_row(); }} >삭제</button>
            </>
          }
        </div>
      </div>
      {is_show_list == false ?
        <div className="basic_cal_wrap">
          <table>
            <colgroup>
              {get_col_tags()}
            </colgroup>
            <thead>
              <tr>
                {get_th_tags()}
              </tr>
            </thead>
            <tbody>
              {get_tr_tags()}
            </tbody>
          </table>
        </div>
        :
        <DataListArea
          ref={DataListAreaRef}
          info_arr={counsel_todo_arr}
          list={(inData: any) => {
            myProps.list(inData);
            myProps.go_search_user_list({});
          }}
          listOpt={myProps.listOpt}
        ></DataListArea>
      }
    </div>
  );
});

export default CalendarTable;
