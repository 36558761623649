import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import SearchOrderRadio from "@/pcomponents/common/crud/write/radio/radio_arr";
import strFunc from '@/lib/lyg/string';
import SearchArea from '@/pages/comp/basic/student/list/area/search_area';
import SearchEval from "./search_eval";

function FindMultyStudentPopup(props:any){
  const myProps={
    closePopup:()=>{},
    listOpt:{},
    row_num:0,
    key:"",
    callBackData:(data:any)=>{
      let optObj={
        info_arr:[],
        row_num:0,
        key:"",
      };
    },//data = { info_arr:[],row_num:0,key:"" };
    ...props
  };
  const xColumnArr=getXcolumnJson();
  const listAggridRef= useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      s_grade:["student"],
      s_addon_class_user:"1",
      s_addon_link_user:"1",
      
      s_addon_eval:"1",
      s_addon_eval_s_form_seq:"",
      s_addon_eval_s_start_date:"",
      s_addon_eval_s_end_date:"",
      s_is_except_eval_user:"",
      s_except_user_seq:"",
      ...myProps.listOpt
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});

  useEffect(() => {
    
  }, []);

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/list',pageData.listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const goCallBackData=()=>{
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }

    myProps.callBackData({
      info_arr:selected_row,
      row_num:myProps.row_num,
      key:myProps.key
    });
    myProps.closePopup();
  };

  const cellClickedListener = useCallback( (params:any) => {
    var key=params.colDef.field;
    if(key=="a_user_name"||key=="a_user_id"){
      //openOnePopup("view",true);
    }
  }, []);

  const cellRenderer= (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;
    if(key=="row_view_class_name"){
      if(params.data.class_arr){
        let class_name_arr=[];
        for(let i=0;i<params.data.class_arr.length;i++){
          class_name_arr.push(params.data.class_arr[i]["class_name"]);
        }
        render_str=class_name_arr.join(" / ");;
      }
    }else if(key=="row_view_par_phone"){
      let a_user_phone=strFunc.autoHypenPhone(params.data.a_user_phone);
      let par_phone_num="학부모 미등록";
      if(params.data.link_user_arr&&params.data.link_user_arr[0]&&params.data.link_user_arr[0]["par_info"]){
        par_phone_num=params.data.link_user_arr[0]["par_info"]["a_user_phone"];
      }
      render_str=(
      <div>
        <div className="leading-5">{par_phone_num}</div>
        <div className="leading-5">{a_user_phone}</div>
      </div>);
    }else if(key=="row_view_eval_num"){
      render_str="0";
      if(params.data.eval_arr&&params.data.eval_arr.length>0){
        render_str=params.data.eval_arr.length;
      }
    }
    return render_str;
  };
  const getRowHeight= useCallback( (params:any) => {
    return 45;
  }, []);

  return (
      <div>
        <SearchArea 
          listOpt={{...pageData.listOpt}}
          xColumnArr={{...xColumnArr}}
          list={list}
          ></SearchArea>
        <SearchEval
          listOpt={{...pageData.listOpt}}
          xColumnArr={{...xColumnArr}}
          list={list}
        ></SearchEval>
        <div className="btn-box-left relative h-8">
          <b>총 <span className="text-blue-600">{countInfo.tot}</span> 명</b>
          <span className="px-2">|</span>
          <SearchOrderRadio
            valueTextArr={[
              {"text":"등록순","value":"a_create_date DESC"},
              {"text":"이름순","value":"a_user_name"},
            ]}
            value={pageData.listOpt.order_id}
            handleInputChange={(e:any)=>{
              list({
                "now_page":1,
                "order_id":e.target.value
              });
            }}
          ></SearchOrderRadio>
          <div className="absolute right-0 top-0 btn-box-right">
            <button className="btn btn-dark" onClick={()=>{goCallBackData();}}>선택</button>
            <button className="btn btn-line-gray" onClick={()=>{myProps.closePopup();}}>닫기</button>
          </div>
        </div>
        <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{...xColumnArr}}
        list={list}
        gridOpt={{
          fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num:true,
          is_add_checkbox:true,
          floatingFilter:false,
          onGridReady:()=>{
            list({});
          },
          onCellClicked:cellClickedListener,
          cellRenderer:cellRenderer,
          getRowHeight:getRowHeight
        }}
        ></ListAggrid>
        <Paging now_page={pageData.listOpt.now_page}
          num_per_page={pageData.listOpt.num_per_page}
          total_rec={countInfo.tot} 
          onChangePage={(now_page:number)=>{list({now_page:now_page});}}
          onChangeNumPerPage={(num_per_page:number)=>{list({now_page:1,num_per_page:num_per_page});}}></Paging>
      </div>
  );
}

export default FindMultyStudentPopup;