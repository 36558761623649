import strFunc from '@/lib/lyg/string';
import StudyBox from "./study_box";

function StudyTitleListArea(props: any) {
  let myProps = {
    study_title_arr: [],
    study_use_arr: [],
    set_study_use_arr:(inData: any) => { },
    show_detail_title_seq_arr: [],
    set_show_detail_title_seq_arr: (inData: any) => { },
    student_info:{},
    select_daily_study_title:(inData: any) => { },
    go_write_study_use_by_ajax:(inData: any) => { },
    refresh_data: (inData: any) => { },
    is_show_only_use:true,
    set_refresh: () => { },
    ...props
  };
  let study_title_arr = myProps.study_title_arr;
  let study_use_arr = myProps.study_use_arr;
  let show_detail_title_seq_arr = myProps.show_detail_title_seq_arr;

  const get_order_list_by_main = () => {
    let main_title_seq_arr = [];
    let main_title_arr = [];
    let etc_title_arr = [];
    for (let i = 0; i < study_use_arr.length; i++) {
      let use_info = study_use_arr[i];
      for (let j = 0; j < study_title_arr.length; j++) {
        let study_info = study_title_arr[j];
        if (use_info["a_study_title_seq"] == study_info["a_seq"]) {
          if (strFunc.str_in_array(study_info["a_seq"], main_title_seq_arr) == -1) {
            main_title_seq_arr.push(study_info["a_seq"]);
            main_title_arr.push(study_info);
          }
        }
      }
    }

    if(myProps.is_show_only_use==false){
      for (let i = 0; i < study_title_arr.length; i++) {
        let study_info = study_title_arr[i];
        if (strFunc.str_in_array(study_info["a_seq"], main_title_seq_arr) == -1) {
          etc_title_arr.push(study_info);
        }
      }
    }

    let ordered_list_arr = [
      ...main_title_arr,
      ...etc_title_arr
    ];

    return ordered_list_arr;
  };

  const get_study_group_tags = () => {
    let ordered_list_arr = get_order_list_by_main();
    let study_group_tags = ordered_list_arr.map((study_title_info: any, idx: number) => {
      return (
        <StudyBox key={idx}
          study_title_info={study_title_info}
          study_use_arr={study_use_arr}
          set_study_use_arr={myProps.set_study_use_arr}
          show_detail_title_seq_arr={show_detail_title_seq_arr}
          set_show_detail_title_seq_arr={myProps.set_show_detail_title_seq_arr}
          student_info={myProps.student_info}
          select_daily_study_title={myProps.select_daily_study_title}
          go_write_study_use_by_ajax={myProps.go_write_study_use_by_ajax}
          refresh_data={myProps.refresh_data}
          set_refresh={myProps.set_refresh}
        ></StudyBox>
      );
    });
    return study_group_tags;
  };

  return (
    <div>
      {get_study_group_tags()}
    </div>
  );
};
export default StudyTitleListArea;