import { useState, useRef, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import DateFunc from '@/lib/lyg/date_func';
import CalendarTable from "./area/calendar_table";
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';

const CalendarArea = forwardRef((props: any, ref) => {
  const myProps = {
    onSearchDateChange: (inData: any) => { },
    go_search_user_list: (inData: any) => { },
    show_write_counsel_todo: (inData: any) => { },
    open_view_counsel_todo: (inData: any) => { },
    show_write_diary_page: (inData: any) => { },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  const CalendarTableRef = useRef<any>(null);
  const [listOpt, setListOpt] = useState({
    "is_need_count": "",
    "is_need_info_arr": "1",
    "is_no_limit": "1",
    "order_id": "a_reserve_date",
    "s_stu_bookmark": strFunc.get_storage("s_stu_bookmark"),
    "s_date_type": "a_reserve_date",
    "s_date_str": DateFunc.get_date_format(new Date(), "Y-m-d"),
    "s_stu_seq": "",
    "s_stu_name_like": "",
    "s_counseler_seq": "",
    "s_is_success": "",
    "s_writer_seq": "",
    "study_diary_s_writer_seq":"",
    "s_addon_user": "1",
    // "s_addon_study_diary":"1",
  });
  const [counsel_todo_arr, set_counsel_todo_arr] = useState([]);
  const [teacher_info_arr, set_teacher_info_arr] = useState<any>([]);
  const [study_diary_arr,set_study_diary_arr]= useState<any>([]);

  const setInitData = (inOptObj: any) => {
    if (inOptObj == undefined) {
      inOptObj = {};
    }
    let optObj = {
      ...inOptObj
    };
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData, list
  }));

  useEffect(() => {
    
    list({});
    get_teacher_arr_by_ajax();
  }, []);

  const list = (inOptObj: any) => {
    let list_form_json: any = {
      ...listOpt,
      ...inOptObj
    };
    setListOpt(list_form_json);

    if (!strFunc.is_empty(list_form_json.s_date_str)) {
      let pre_month_obj = DateFunc.get_change_date(new Date(list_form_json.s_date_str), 'month', -1);
      let after_month_obj = DateFunc.get_change_date(new Date(list_form_json.s_date_str), 'month', +1);
      list_form_json.s_start_date = DateFunc.get_date_format(pre_month_obj, "Y-01-01");
      list_form_json.s_reserve_start_date = DateFunc.get_date_format(pre_month_obj, "Y-m-01");
      list_form_json.s_reserve_end_date = DateFunc.get_date_format(after_month_obj, "Y-m-t");
    }
    get_study_diary_by_ajax({
      s_start_date: list_form_json.s_reserve_start_date,
      s_end_date: list_form_json.s_reserve_end_date,
      study_diary_s_writer_seq:list_form_json.study_diary_s_writer_seq,
    });
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/counsel_todo/list', list_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_counsel_todo_arr(response.data["data"]["info_arr"]);
          CalendarTableRef.current.set_counsel_todo_arr(response.data["data"]["info_arr"]);
          CalendarTableRef.current.set_stu_info_arr(list_form_json.s_stu_info_arr);
        } else {

        }
      });
  };

  const get_study_diary_by_ajax = (inData: any) => {
    let opt_obj = {
      s_start_date: "",
      s_end_date: "",
      study_diary_s_writer_seq:"",
      ...inData
    };
    let list_form_json:any={
      s_stu_seq: listOpt.s_stu_seq,
      s_writer_seq: opt_obj.study_diary_s_writer_seq,
      order_id:"a_study_date DESC, a_create_date DESC",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
      ...opt_obj,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/study_diary/list', list_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_study_diary_arr(response.data["data"]["info_arr"]);
          CalendarTableRef.current.set_study_diary_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const get_teacher_arr_by_ajax = () => {
    if (user.user_grade != "master") {
      set_teacher_info_arr([{
        "a_seq": user.user_seq,
        "a_user_name": user.user_name,
      }]);
    } else {
      let formJsonData = {
        "s_grade": ["teacher", "master", "staff"],
        "s_is_login": "1",
        "is_need_count": "1",
        "is_need_info_arr": "1",
        "is_no_limit": "",
      };
      my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', formJsonData,get_axios_data())
        .then((response) => {
          if (response.data["result"] === "true") {
            set_teacher_info_arr(response.data["data"]["info_arr"]);
          }
        });
    }
  };

  return (
    <div>
      <CalendarTable
        ref={CalendarTableRef}
        listOpt={listOpt}
        select_date={listOpt.s_date_str}
        list={list}
        teacher_info_arr={teacher_info_arr}
        onSearchDateChange={(inData: any) => {
          list({ s_date_str: inData });
          myProps.onSearchDateChange(inData);
        }}
        go_search_user_list={myProps.go_search_user_list}
        show_write_counsel_todo={myProps.show_write_counsel_todo}
        open_view_counsel_todo={myProps.open_view_counsel_todo}
        show_write_diary_page={myProps.show_write_diary_page}
      ></CalendarTable>
    </div>
  );
});

export default CalendarArea;
