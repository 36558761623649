//import ListComponentView from '@/pcomponents/common/crud/list/list_only_view';
import { useRef,forwardRef,useImperativeHandle  } from 'react';
import strFunc from '@/lib/lyg/string';
import TableHeadArea from "./area/head";
import TableBodyArea from "./area/body";

const ListComponent=forwardRef((props:any, ref) => {
  let myProps:any={
    "infoArr":[],//[{"a_ymd":"20221212","a_seq":"1","a_filename":"파일명","is_active":false}]
    active_row_arr:[],
    set_active_row_arr:(inData:any)=>{},
    "xColumnArr":{
      "x_column_list_arr":[]//[{"key":"a_ymd","name":"board_ymd","width":"100","is_show":"1"}]
    },
    "gridOpt":{
      
    },
    ...props
  };
  var win_w=(window.innerWidth || document.documentElement.clientWidth);
  const listTableRef=useRef<any>(null);
  const xColumnArr=myProps.xColumnArr;
  const max_width= myProps.gridOpt.max_width?myProps.gridOpt.max_width:win_w;
  const gridOpt={
    isGrid:false,
    is_add_idx_num:true,
    is_add_checkbox:true,
    is_fix_width:false,
    width:"100%",
    height:"60vh",
    max_width:max_width,
    is_auto_max_width:false,
    td_align:"left",//center,right
    table_align:"left",//center,right
    onGridReady:()=>{},
    rowOnClick:(rowData:any,idx:number,e:any)=>{},
    onSelectChange:(select_info_arr:any)=>{},
    onClickTh:(key:string,e:any)=>{},
    class_name_json:{
      table_div:"list-table-div",
      td_div:"row-col-div",
      input_check:"chk_row",
      row_input:"row-input",
      data_row_tr:"data_row_tr",
      active_row:"active",
      empty_con_div:"list_empty_content_div",
    },
    "onChangeInput":(params:any)=>{
      /*
      {
        "rowData":rowData,
        "row_num":row_num,
        "key":key,
        "value":row_val,
        "pre_value":"",
      }
      */
     return params;
    },
    "customCellRenderer":(params:any)=>{
      /*
      {
        "rowData":rowData,
        "idx":idx,
        "key":key,
        "value":row_val,
        "origin_val":origin_val,
      }
      */
      return params.value;
    },
    "customGridCellRenderer":(params:any)=>{
      /*
      {
        "rowData":rowData,
        "idx":idx,
        "key":key,
        "value":row_val,
        "origin_val":origin_val,
        "input_tag":input_tag,
        "onChangeInput":onChangeInput,
      }
      */
      return params.input_tag;
    },
    "customThCellRenderer":(params:any)=>{
      /*
      {
        "key":key,
        "name":name,
        "head_str":head_str,
      }
      */
      return params.head_str;
    },
    ...myProps.gridOpt
  };
  const isGrid=gridOpt.isGrid;
  const TableHeadAreaRef=useRef<any>(null);
  const TableBodyAreaRef=useRef<any>(null);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    getSelectedRows,getRows,
    getInitActiveArrByInfoArrLength
  }));

  const getSelectedRows=()=>{
    return TableBodyAreaRef.current.getSelectedRows();
  };
  const getRows=()=>{
    return TableBodyAreaRef.current.getRows();
  };

  const checkAll=(e:any)=>{
    TableBodyAreaRef.current.checkAll(e.target.checked);
  };

  const getInitActiveArrByInfoArrLength=(init_info_arr_length:number)=>{
    let tmp_active_row_arr=[];
    for(let i=0;i<init_info_arr_length;i++){
      tmp_active_row_arr.push(false);
    }
    return tmp_active_row_arr;
  };

  const getListColumnDefs=()=>{
    let tmpColDefs=[];
    if(gridOpt.is_add_checkbox){
      tmpColDefs.push({"key":"checkbox",
        "name":"checkbox",
        "width":"30","is_show":"1"});
    }
    if(gridOpt.is_add_idx_num){
      tmpColDefs.push({"key":"idx_num","name":"No.","width":"60","is_show":"1"});
    }
    let addon_defs_list=xColumnArr.x_column_list_arr;
    for(let i=0;i<addon_defs_list.length;i++){
      if(addon_defs_list[i]["is_show"]==undefined){
        addon_defs_list[i]["is_show"]=true;
      }
    }
    tmpColDefs=[
      ...tmpColDefs,
      ...addon_defs_list
    ];
    return tmpColDefs;
  };

  const onscrollLeft=(left_num:number)=>{
    TableHeadAreaRef.current.setScrollLeft(left_num);
  };

  const updateColumnMaxWidth=(col_max_width_arr:[])=>{
    TableBodyAreaRef.current.setColumnMaxWidth(col_max_width_arr);
  };

  let colDefs=getListColumnDefs();

  return (
    <div className={gridOpt.class_name_json.table_div} ref={listTableRef} style={{maxWidth:gridOpt.max_width}} >
      <TableHeadArea
        ref={TableHeadAreaRef}
        gridOpt={gridOpt}
        colDefs={colDefs}
        checkAll={checkAll}
        customThCellRenderer={gridOpt.customThCellRenderer}
        updateColumnMaxWidth={updateColumnMaxWidth}
      ></TableHeadArea>
      <TableBodyArea
        ref={TableBodyAreaRef}
        gridOpt={gridOpt}
        colDefs={colDefs}
        infoArr={myProps.infoArr}
        active_row_arr={myProps.active_row_arr}
        set_active_row_arr={myProps.set_active_row_arr}
        isGrid={isGrid}
        xColumnArr={xColumnArr}
        onscrollLeft={onscrollLeft}
        onChangeInput={gridOpt.onChangeInput}
        customCellRenderer={gridOpt.customCellRenderer}
        customGridCellRenderer={gridOpt.customGridCellRenderer}
      ></TableBodyArea>
    </div>
  );
});

export default ListComponent;
