import strFunc from '@/lib/lyg/string';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';

function DetailListArea(props: any) {
  let myProps = {
    study_title_info: null,
    section_row_num: "",
    section_info: {},
    study_use: null,
    student_info: {},
    set_study_use: (inData: any) => { },
    select_daily_study_title: (inData: any) => { },
    ...props
  };
  let study_title_info = myProps["study_title_info"];
  let section_info = myProps["section_info"];
  let study_use = myProps["study_use"];
  let detail_arr: any = [];
  if (study_use && study_use.detail_arr) {
    detail_arr = study_use.detail_arr;
  }
  let student_info = myProps.student_info;
  let default_detail_info = {
    a_stu_seq: student_info["a_seq"],
    a_study_title_seq: study_title_info["a_seq"],
    a_seq: 0,
    a_reserve_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    a_amount: "0",
    a_success_amount: "",
    a_is_success: "",
    a_success_date: "",
    a_text_score: "0",
    a_memo: "",
  };

  let study_arr = [];
  if (study_title_info["study_arr"] && study_title_info["study_arr"].length > 0) {
    for (let i = 0; i < study_title_info["study_arr"].length; i++) {
      if (study_title_info["study_arr"][i]["a_section_seq"] == section_info["a_seq"]) {
        study_arr.push(study_title_info["study_arr"][i]);
      }
    }
  }

  const on_change_detail_input = (e: any, detail_info: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let detail_row_num = -1;
    for (let i = 0; i < detail_arr.length; i++) {
      if (detail_arr[i]["a_seq"] == detail_info["a_seq"]) {
        detail_row_num = i;
      }
    }

    let change_row_data = {
      ...detail_info
    };
    if (name == "a_amount" || name == "a_text_score") {
      value = strFunc.uncomma(value);
      if (name == "a_amount") {
        let a_success_amount = parseInt(strFunc.uncomma(change_row_data["a_success_amount"]));
        let a_amount = parseInt(strFunc.uncomma(value));
        if (a_success_amount != 0) {
          if (a_amount >= a_success_amount) {
            change_row_data["a_is_success"] = "1";
            change_row_data["a_success_date"] = DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
          }
        }
      }
    }
    if (name == "a_is_success") {
      value = "";
      if (e.target.checked) {
        value = "1";
        if (strFunc.is_empty(change_row_data["a_amount"])) {
          change_row_data["a_amount"] = change_row_data["a_success_amount"];
        }
        change_row_data["a_success_date"] = DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
      }
    }

    change_row_data[name] = value;
    let change_detail_arr = [
      ...detail_arr
    ];
    if (detail_row_num == -1) {
      change_detail_arr.push(change_row_data);
    } else {
      change_detail_arr[detail_row_num] = change_row_data;
    }
    study_use.detail_arr = change_detail_arr;
    myProps.set_study_use({ ...study_use });
  };

  const onChangeDatePicker = (key: string, date: Date, detail_info: any) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    let detail_row_num = -1;
    for (let i = 0; i < detail_arr.length; i++) {
      if (detail_arr[i]["a_seq"] == detail_info["a_seq"]) {
        detail_row_num = i;
      }
    }
    let change_row_data = {
      ...detail_info
    };
    change_row_data[key] = date_str;
    let change_detail_arr = [
      ...detail_arr
    ];
    if (detail_row_num == -1) {
      change_detail_arr.push(change_row_data);
    } else {
      change_detail_arr[detail_row_num] = change_row_data;
    }
    study_use.detail_arr = change_detail_arr;
    myProps.set_study_use({ ...study_use });
  };

  const remove_detail_of_detail_info = (detail_info: any) => {
    let new_detail_arr = [];
    for (let i = 0; i < detail_arr.length; i++) {
      if (detail_arr[i]["a_seq"] == detail_info["a_seq"]) {

      } else {
        new_detail_arr.push(detail_arr[i]);
      }
    }
    study_use.detail_arr = new_detail_arr;
    myProps.set_study_use({ ...study_use });
  };

  const get_use_detail_by_study_info = (study_info: any) => {
    let detail_info = null;
    for (let i = 0; i < detail_arr.length; i++) {
      if (detail_arr[i]["a_seq"] == study_info["a_seq"]) {
        detail_info = detail_arr[i];
      }
    }
    return detail_info;
  };

  const get_study_detail_tags = () => {
    let study_arr = [];
    if (study_title_info["study_arr"] && study_title_info["study_arr"].length > 0) {
      for (let i = 0; i < study_title_info["study_arr"].length; i++) {
        let study_info = study_title_info["study_arr"][i];
        if (study_info["a_section_seq"] == section_info["a_seq"]) {
          study_arr.push(study_info);
        }
      }
    }

    let study_detail_tags = study_arr.map((study_info: any, idx: number) => {
      let detail_info = get_use_detail_by_study_info(study_info);
      let is_exist_detail = false;
      if (detail_info == null) {
        detail_info = {
          ...default_detail_info
        };
      } else {
        is_exist_detail = true;
      }
      detail_info["a_success_amount"] = study_info["a_amount_num"];
      detail_info["a_seq"] = study_info["a_seq"];

      let select_study_title_str = myProps["section_row_num"] + "-" + (idx + 1) + " " + study_info["a_title"];
      let select_is_main = study_use ? (study_use["a_is_main"] == "1") : true;
      let amount_amount_cnt = detail_info ? detail_info["a_amount"] : "0";
      let select_daily_study_title_json = {
        is_main: select_is_main,
        study_title: select_study_title_str,
        amount: amount_amount_cnt,
        success_amount: detail_info["a_success_amount"],
        score: detail_info["a_text_score"]
      };

      return (
        <tr key={idx}>
          <td className="text-center">{idx + 1}</td>
          <td style={{ cursor: "pointer" }} onClick={() => {
            myProps.select_daily_study_title(select_daily_study_title_json);
          }} >
            <div style={{position:"relative"}}>
              {study_info["a_title"]}
              <button className="btn btn-gray" style={{position:"absolute",right:0,top:0}}>선택</button>
            </div>
          </td>
          <td>
            <input type="text" className="row-input ml-1"
              name="a_amount"
              value={detail_info["a_amount"]}
              placeholder="수행"
              onChange={(e: any) => { on_change_detail_input(e, detail_info); }}
              onClick={(e: any) => { e.target.select(); }}
              style={{ width: "40px", textAlign: "right" }} />
          </td>
          <td className="text-center">
            {study_info["a_amount_num"]}
            {study_info["a_amount_sort"]}
          </td>
          <td className="text-center">
            <input type="checkbox" className="ml-1" checked={detail_info["a_is_success"] == "1"}
              name="a_is_success"
              onChange={(e: any) => {
                on_change_detail_input(e, detail_info);
                if (e.target.checked) {
                  myProps.select_daily_study_title(select_daily_study_title_json);
                }
              }} />
            <div className="ml-1" style={{display:"inline-block",width:90}}>
              <DatePicker
                selected={detail_info.a_success_date != "" ? new Date(detail_info.a_success_date) : null}
                onChange={(date: Date) => {
                  onChangeDatePicker("a_success_date", date, detail_info);
                }}
                locale={ko}
                dateFormat="yyyy-MM-dd"
                customInput={<CustomInputWrite />}
                renderCustomHeader={customHeaderFunc}
              />
            </div>
          </td>
          <td>
            <input type="text" className="row-input" placeholder="사용메모"
              name="a_memo"
              value={detail_info["a_memo"]}
              onChange={(e: any) => { on_change_detail_input(e, detail_info); }}
              style={{ width: "99%" }} />
          </td>
          <td>
            <input type="text" className="row-input ml-1"
              name="a_text_score"
              value={detail_info["a_text_score"]}
              onChange={(e: any) => { on_change_detail_input(e, detail_info); }}
              onClick={(e: any) => { e.target.select(); }}
              style={{ width: "40px", textAlign: "right" }} />
          </td>
          <td className="text-center">
            {is_exist_detail &&
              <button className="btn-s btn-red"
                onClick={(e: any) => { remove_detail_of_detail_info(detail_info); }}>X</button>
            }
          </td>
        </tr>
      );
    });

    return study_detail_tags;
  };

  return (
    <div>
      <div className="write_table_small pl-2">
        <table style={{ width: "98%" }}>
          <colgroup>
            <col width={"1%"}></col>
            <col width={"*"}></col>
            <col width={"5%"}></col>
            <col width={"8%"}></col>
            <col width={"18%"}></col>
            <col width={"10%"}></col>
            <col width={"5%"}></col>
            <col width={"5%"}></col>
          </colgroup>
          <thead>
            <tr>
              <th>No.</th>
              <th>제목</th>
              <th>수행</th>
              <th>수량</th>
              <th>완료</th>
              <th>사용메모</th>
              <th>점수</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>
            {get_study_detail_tags()}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default DetailListArea;