import { forwardRef, useEffect, useImperativeHandle, useRef, useState, useCallback } from "react";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import { useSelector, useDispatch } from 'react-redux';
import getXcolumnJson from "../../xcolumn/list";
import WriteAreaComponent from "./write_area";
import LectureTimeComponent from "./time";
import TabBottomComponent from "./tab/bottom_tab";
import StudentArrComponent from "./student";

const WriteLectureComponent = forwardRef((props: any, ref) => {
  const myProps = {
    list: (inOptObj: any) => {

    },
    ...props
  };
  const xColumnArr = getXcolumnJson();
  let user = useSelector((state: any) => state.user);
  const lectureTimeComponentRef = useRef<any>(null);
  const studentArrComponentRef = useRef<any>(null);

  const [isUpdate, setIsUpdate] = useState(false);
  const initLectureRowData = {
    "a_seq": "",
    "a_name": "",
    "a_main_teacher": "",
    "a_main_teacher_seq": "",
    "a_state": "ing",
    "a_period_sort": "month",
    "a_subject": "",
    "a_start_date": "",
    "a_end_date": "",
    "a_room_name": "",
    "a_room_seq": "",
    "a_target_stu_start": "",
    "a_target_stu_end": "",
    "a_max_stu": "20",
    "a_material": "",
    "a_basic_h": "13",
    "a_basic_m": "00",
    "a_basic_length_sec": 60 * 60,
    "a_basic_row_cnt": "1",
    "a_same_days_week": "",
    "a_memo": "",
    "a_order_num": "0",
    "time_arr": [],
  };
  const [rowData, setRowData] = useState({ ...initLectureRowData });
  const [room_info_arr, set_room_info_arr] = useState<any>([]);
  const pageData = {
    "write_btn_text": "등록",
    "confirm_msg": "등록 하시겠습니까?",
  };
  const [teacher_arr, set_teacher_arr] = useState([]);
  const tabBottomList = ["시간표", "수강생"];//,"수강생"
  const [tabBottomActiveIdx, setTabBottomActiveIdx] = useState(0);
  const [is_show_write_area, set_is_show_write_area] = useState(true);
  if (isUpdate) {
    pageData["write_btn_text"] = "수정";
    pageData["confirm_msg"] = "수정 하시겠습니까?";
  }

  useEffect(() => {
    
    getSelectRoomList();
    getSelectTeacherList();
  }, []);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setRowDataChild
  }));

  const setRowDataChild = (inRowData: any, inIsUpdate: boolean) => {
    setRowData({
      ...initLectureRowData,
      ...inRowData
    });
    if (lectureTimeComponentRef.current) {
      if (inRowData.time_arr) {
        lectureTimeComponentRef.current.setTimeInfoArr(inRowData.time_arr);
      } else {
        lectureTimeComponentRef.current.setTimeInfoArr([]);
      }
    }
    if (studentArrComponentRef.current) {
      if (inRowData.student_arr) {
        studentArrComponentRef.current.setStudentArr(inRowData.student_arr);
      } else {
        studentArrComponentRef.current.setStudentArr([]);
      }
    }
    setIsUpdate(inIsUpdate);
  };

  const getSelectRoomList = useCallback(() => {
    let formJsonData = {
      "order_id": "a_name",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/room/list', formJsonData,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_room_info_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });

  }, []);

  const getSelectTeacherList = useCallback(() => {
    let formJsonData = {
      "s_grade": ["teacher", "master"],
      "order_id": "a_user_name",
      "s_is_login": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', formJsonData,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_teacher_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });

  }, []);

  const goWrite = useCallback((e: any) => {
    if (rowData.a_name == "") {
      alert("수업명 입력이 필요합니다.");
      return false;
    }
    if (!confirm(pageData["confirm_msg"])) {
      return false;
    }

    let time_arr = [];
    if (lectureTimeComponentRef.current) {
      time_arr = lectureTimeComponentRef.current.getTimeInfoArr();
    }
    let student_arr = [];
    if (studentArrComponentRef.current) {
      student_arr = studentArrComponentRef.current.getStudentArr();
    }

    let formJsonData = {
      "lecture_info": rowData,
      "is_add_time_arr": "1",
      "time_arr": time_arr,
      "is_add_student_arr": "1",
      "student_arr": student_arr,
      "is_update": "",
    };

    if (isUpdate) {
      formJsonData["is_update"] = "1";
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/write_one', formJsonData,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("저장 되었습니다.");
          myProps.list({select_seq:response.data["data"]["info_arr"][0]["a_seq"]});
          setRowDataChild(response.data["data"]["info_arr"][0], true);
        } else {
          alert(response.data["msg"]);
        }
      });

  }, [rowData]);

  return (
    <div>
      <h4 style={{ height: 28, lineHeight: "28px", position: "relative" }}>
        수업관리
        <button className="btn-s2 btn-gray ml-2"
          style={{ position: "absolute", right: 0, top: 5 }}
          onClick={() => { set_is_show_write_area(!is_show_write_area); }}>
          {is_show_write_area ? "▲" : "▼"}
        </button>
      </h4>
      {is_show_write_area &&
        <WriteAreaComponent
          rowData={rowData}
          setRowData={setRowData}
          room_info_arr={room_info_arr}
          teacher_arr={teacher_arr}
          xColumnArr={xColumnArr}
        ></WriteAreaComponent>
      }
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
      </div>
      <div className="mt-1" >
        <TabBottomComponent
          tabList={tabBottomList}
          activeIdx={tabBottomActiveIdx}
          onChangeActive={(idx: number) => { setTabBottomActiveIdx(idx); }}
        ></TabBottomComponent>
      </div>
      <div className="mt-3">
        <div style={{ display: (tabBottomActiveIdx == 0 ? "block" : "none") }}>
          <LectureTimeComponent
            ref={lectureTimeComponentRef}
            rowData={rowData}
            room_info_arr={room_info_arr}
            teacher_arr={teacher_arr}
          ></LectureTimeComponent>
        </div>
        <div style={{ display: (tabBottomActiveIdx == 1 ? "block" : "none") }}>
          <StudentArrComponent
            ref={studentArrComponentRef}
          ></StudentArrComponent>
        </div>
      </div>
    </div>
  );
});

export default WriteLectureComponent;