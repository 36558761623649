import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

const SearchArea = forwardRef((props:any, ref) => {
  const [listOpt,setListOpt] = useState(props.listOpt);
  const [classSelectArr,setClassSelectArr] = useState<any>([]);
  const [subjectArr,setsubjectArr] = useState<any>([]);
  let subject_option_arr=XcolumnFunc.getSortByTableKey({'table':'study_title','key':'a_subject_sort'});
  useEffect(()=>{
    
    list_corse_subject();
  },[]);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
  }));

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'||target.type === 'radio'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }
    setListOpt({
      ...listOpt,
      ...{[name]: value}
    });
    if(name=="s_search_text"){
      return false;
    }
    props.list({[name]: value});
  };

  const goSearch=()=>{
    props.list({...listOpt});
  };

  const list_corse_subject = ()=>{
    let subject_list_form_data={
      "order_id":"a_order_num, a_title",
      "s_is_use":"1",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/study/study_subject/list',subject_list_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setsubjectArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  return (
    <div className="search-box relative">
      <select className="search-input_select" name="s_subject_seq" value={listOpt.s_subject_seq} 
        onChange={handleInputChange} 
        style={{width:70}}>
        <option value="">구분</option>
        {
          subjectArr.map((item:any,idx:number)=>{
            return (
              <option value={item.a_seq} key={idx}>{item.a_title}</option>
            );
          })
        }
      </select>
      <select className="search-input_select ml-1" name="s_subject_sort" value={listOpt.s_subject_sort} 
        onChange={handleInputChange} 
        style={{width:70}} >
        <option value="">과목</option>
        {
          subject_option_arr.map((item:any,idx:number)=>{
            return (
              <option value={item.value} key={idx}>{item.text}</option>
            );
          })
        }
      </select>
      <button className="btn btn-dark" onClick={()=>{goSearch();}} >검색</button>
      <button className="btn btn-dark" onClick={()=>{props.setWriteState();}}>신규</button>
      <button className="btn btn-dark" onClick={props.goDelete}>삭제</button>
    </div>
  );
});

export default SearchArea;