import { useState, useRef, useEffect, useMemo, useCallback,forwardRef } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';
import getXcolumnJson from "./xcolumn/list";
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';

function SubjectManagePopup(props:any) {
  let myProps={
    closePopup:()=>{},
    callback:(inData:any)=>{},
    ...props
  };
  let user=useSelector((state:any) => state.user);
  const xColumnArr=getXcolumnJson();
  const ListComponentRef=useRef<any>(null);
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      "s_is_use":"1",
    }
  });
  const [infoArr, setInfoArr] = useState([]);

  useEffect(()=>{
    
  },[]);

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/study/study_subject/list',pageData.listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        ListComponentRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const getLastSubjectOrderNum=()=>{
    let last_order_num=1;
    let selected_row=ListComponentRef.current.getRows();
    let selected_row_len=selected_row.length;
    for(let i=0;i<selected_row_len;i++){
      let row_data=selected_row[i];
      let row_order_num=parseInt(strFunc.uncomma(row_data["a_order_num"]));
      if(row_order_num>last_order_num){
        last_order_num=row_order_num;
      }
    }
    return last_order_num;
  };

  const goAddRow=()=>{
    let last_order_num=getLastSubjectOrderNum();
    let selected_row=ListComponentRef.current.getRows();
    let add_row_data={
      "a_seq":"",
      "a_title":"",
      "a_sort":"",
      "a_is_use":"1",
      "a_order_num":last_order_num,
    };
    selected_row.push(add_row_data);
    setInfoArr(selected_row);
    ListComponentRef.current.setInfoArr(selected_row);
  };

  const goWrite=()=>{
    let selected_row=ListComponentRef.current.getRows();
    selected_row=getReSetOrderNum(selected_row);
    if(selected_row.length===0){
      alert("데이터없음.");
      return false;
    }
    if(!confirm("저장 하시겠습니까?")){
      return false;
    }

    let write_form_json={
      "data_arr":selected_row
    };

    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/study/study_subject/write',write_form_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        list({});
        myProps.callback({});
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const goDelete = useCallback( () => {
    let selected_row=ListComponentRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    
    if(!confirm("삭제 하시겠습니까?")){
      return false;
    }

    let del_form_json={
      "data_arr":selected_row,
    };

    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/study/study_subject/delete',del_form_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        list({});
        myProps.callback({});
      }else{
        alert(response.data["msg"]);
      }
    });
  }, []);

  const moveOrderNum=(row_num:number,up_down:string)=>{
    let selected_row:any=ListComponentRef.current.getRows();

    let next_row_num=row_num;
    if(up_down=="up"){
      next_row_num=row_num-1;
    }else if(up_down=="down"){
      next_row_num=row_num+1;
    }

    if(next_row_num<0){
      return false;
    }
    if(next_row_num>=selected_row.length){
      return false;
    }

    let now_row_data=selected_row[row_num];
    let change_row_data=selected_row[next_row_num];

    selected_row[row_num]=change_row_data;
    selected_row[next_row_num]=now_row_data;

    selected_row=getReSetOrderNum(selected_row);

    setInfoArr(selected_row);
    ListComponentRef.current.setInfoArr(selected_row);
  };

  const getReSetOrderNum=(selected_row:any)=>{
    for(let i=0;i<selected_row.length;i++){
      selected_row[i].a_order_num=i+1;
    }
    return selected_row;
  };

  const customCellRenderer= useCallback( (params:any) => {
    /*
      {
        "rowData":rowData,
        "idx":idx,
        "key":key,
        "value":row_val
      }
      */
    var key=params.key;
    var render_str=params.value;
    if(key=="row_view_order_num"){
      render_str=(
      <div>
        <button className="btn-s btn-gray" onClick={()=>{moveOrderNum(params["idx"],"up");}}>▲</button>
        <button className="btn-s btn-gray ml-2" onClick={()=>{moveOrderNum(params["idx"],"down");}}>▼</button>
        <button className="btn-s btn-red ml-2" onClick={()=>{setTimeout(()=>{goDelete();},200);}}>X</button>
      </div>
      );
    }

    return render_str;
  }, []);

  return (
    <div>
      <div className="btn-box-center m-2">
        사용여부
        <select className="search-input_select" name="s_is_use" value={pageData.listOpt.s_is_use} onChange={(e:any)=>{
          list({"s_is_use":e.target.value});
        }} >
          <option value="">전체</option>
          <option value="1">예</option>
          <option value="empty">아니오</option>
        </select>
        <button className="btn btn-line-gray" onClick={()=>{myProps.closePopup();}}>닫기</button>
      </div>
      <div className="btn-box-center m-2">
        <button className="btn btn-line-gray" onClick={()=>{goWrite();}}>저장</button>
        <button className="btn btn-line-gray" onClick={()=>{goAddRow();}}>추가</button>
        <button className="btn btn-red" onClick={()=>{goDelete();}}>삭제</button>
      </div>
      <div className="list_round_box">
        <ListComponent
        ref={ListComponentRef}
        xColumnArr={{...xColumnArr}}
        gridOpt={{
          fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
          isGrid:true,
          is_add_idx_num:false,
          is_add_checkbox:true,
          td_align:"center",
          is_fix_width:true,
          onGridReady:()=>{
            list({});
          },
          customCellRenderer:customCellRenderer,
          class_name_json:{
            table_div:"basic_list_div",
            td_div:"row-col-div",
            input_check:"chk_row",
            row_input:"row-input",
            data_row_tr:"data_row_tr",
            active_row:"active",
            empty_con_div:"list_empty_content_div",
          },
        }}
        ></ListComponent>
      </div>
    </div>
  );
}

export default SubjectManagePopup;
