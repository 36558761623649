import strFunc from "@/lib/lyg/string";
import TodoStateFunc from "@/pcomponents/common/content/todo/state/func/state_func";

class DailyDataFunc {
  static get_daily_info_of_date(inData: any) {
    let opt_obj = {
      date_str: "",
      pre_date_str:"",
      is_now_date: false,
      last_daily_info: {},
      lecture_time_str: "",
      info: {},
      lecture_info: null,
      ...inData,
    };
    let lecture_info = opt_obj["lecture_info"];
    let info = opt_obj["info"];
    let daily_info: any = {
      ...opt_obj.last_daily_info,
      a_ymd: strFunc.getNumber2(opt_obj["date_str"]),
      a_seq: "",
      a_date: opt_obj["date_str"],
      a_lecture_time: opt_obj.lecture_time_str,
      a_stu_seq: info["a_seq"],
      a_stu_name: info["a_user_name"],
      a_textbook_study: "",
      a_subtextbook_study: "",
      a_todo_sub_success_amount: "0",
      a_todo_sub_amount: "0",
    };

    let td_sort_data = {
      "sort": "study",
      "title": "학습",
      "percent": 0,
      "count": 0,
      "success_cnt": 0,
      "elapse_time_sec": 0,
      "per_arr": [],
      "todo_info_arr": [],
    };
    if (info.td_sort_data_json && info.td_sort_data_json["study"]) {
      td_sort_data = info.td_sort_data_json["study"];
    }

    if (lecture_info != null) {
      daily_info["a_lecture_seq"] = lecture_info["a_seq"];
      daily_info["a_lecture_name"] = lecture_info["a_name"];
      daily_info["a_writer_seq"] = lecture_info["a_main_teacher_seq"];
      daily_info["a_writer"] = lecture_info["a_main_teacher"];
      daily_info["a_subject"] = lecture_info["a_subject"];
    }
    //기존데이터 덮어쓰기
    if (info.daily_report_arr && info.daily_report_arr.length > 0 && lecture_info != null) {
      for (let i = 0; i < info.daily_report_arr.length; i++) {
        let row_daily_info = info.daily_report_arr[i];
        if (row_daily_info["a_lecture_seq"] == lecture_info["a_seq"]
          && row_daily_info["a_stu_seq"] == info["a_seq"]
          && row_daily_info["a_writer_seq"] == lecture_info["a_main_teacher_seq"]
          && row_daily_info["a_date"] == opt_obj["date_str"]) {
          daily_info = row_daily_info;
        }
      }
    }
    //오늘꺼 덮어쓰기
    if (opt_obj["is_now_date"]) {
      // daily_info["a_todo_success_amount"] = td_sort_data["count"];
      // daily_info["a_todo_amount"] = td_sort_data["success_cnt"];

      if (info.td_sort_data_json && info.td_sort_data_json["pre_homework"]) {
        daily_info["a_homework_per_num"] = info.td_sort_data_json["pre_homework"]["percent"];
      }

      let home_work_file_cnt=TodoStateFunc.get_file_cnt_by_user_info({
        info:info,
        date_sort:"pre",//pre,now
        select_date:opt_obj["date_str"],
        pre_date_str:opt_obj["pre_date_str"],
      });
      daily_info["a_homework_file_cnt"]=home_work_file_cnt;
    }

    return daily_info;
  };
  static get_daily_percent(inData: any) {
    let opt_obj = {
      daily_info: {},
      is_sub: false,//sub
      ...inData
    };
    let daily_info = opt_obj["daily_info"];
    let daily_percent = 0;
    let a_todo_amount = parseInt(strFunc.uncomma(daily_info["a_todo_amount"]));
    let a_todo_success_amount = parseInt(strFunc.uncomma(daily_info["a_todo_success_amount"]));
    if (opt_obj.is_sub ) {
      a_todo_amount = parseInt(strFunc.uncomma(daily_info["a_todo_sub_amount"]));
      a_todo_success_amount = parseInt(strFunc.uncomma(daily_info["a_todo_sub_success_amount"]));
    }
    
    if (a_todo_amount != 0 && a_todo_success_amount != 0) {
      daily_percent = Math.floor((a_todo_amount / a_todo_success_amount)*100);
    }
    if (daily_percent > 100) { daily_percent = 100; }
    return daily_percent;
  }
}
export default DailyDataFunc;