import { useState, useRef, useEffect, useMemo, useCallback, forwardRef } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '@/store/user/user';
import strFunc from '@/lib/lyg/string';
import { useNavigate } from 'react-router-dom';

function LoginOtherArea(props: any) {
  let myProps = {
    ...props
  };

  let user = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const wrap_ref = useRef<any>(null);
  const [group_manager_arr, set_group_manager_arr] = useState<any>([]);
  const [user_comp_link,set_user_comp_link]=useState<any>([]);
  const [groupArr, setGroupArr] = useState<any>([]);
  const [group_seq, set_group_seq] = useState("");
  const [groupLinkArr, setGroupLinkArr] = useState<any>([]);
  const [mcomp_seq, set_mcomp_seq] = useState("");

  useEffect(() => {
    get_group_manager_by_ajax();
    set_mcomp_seq(user.comp_seq);
  }, [user]);

  const unshow_wrap = () => {
    if (wrap_ref.current) {
      wrap_ref.current.style.display = "none";
    }
  };

  const get_group_manager_by_ajax = () => {
    if (strFunc.is_empty(user.main_user_seq)) {
      unshow_wrap();
      return false;
    }
    get_user_comp_link_by_ajax();
    let form_json_data = {
      "s_user_seq": user.main_user_seq,
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/mcomp/group_manager/list', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_group_manager_arr(response.data["data"]["info_arr"]);
          if (response.data["data"]["info_arr"].length == 0) {
            unshow_wrap();
          } else {
            let tmp_group_seq_arr = [];
            for (let i = 0; i < response.data["data"]["info_arr"].length; i++) {
              let group_info = response.data["data"]["info_arr"][i];
              tmp_group_seq_arr.push(group_info["a_group_seq"]);
            }
            set_group_seq(tmp_group_seq_arr[0]);
            list_group_link(tmp_group_seq_arr[0]);
            list_group({
              group_seq_arr: tmp_group_seq_arr,
            });
          }
        } else {

        }
      });
  };

  const get_user_comp_link_by_ajax=()=>{
    if (strFunc.is_empty(user.main_user_seq)) {
      return false;
    }
    let form_json_data={
      "s_main_seq":user.main_user_seq,
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user_comp_link/link/list', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_user_comp_link(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const list_group = (inData: any) => {
    let opt_obj = {
      "group_seq_arr": [],
      ...inData
    };
    if (opt_obj["group_seq_arr"].length == 0) {
      return false;
    }
    let group_list_form_data = {
      "s_pri_arr": opt_obj["group_seq_arr"],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/mcomp/group/list', group_list_form_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setGroupArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const list_group_link = (in_group_seq: any) => {
    if (in_group_seq == "") {
      setGroupLinkArr([]);
      return false;
    }
    let group_list_form_data = {
      "s_group_seq": in_group_seq,
      "s_addon_mcomp":"1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/mcomp/group_link/list', group_list_form_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setGroupLinkArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const login_other_comp=()=>{
    if(strFunc.is_empty(mcomp_seq)){
      alert("학원 선택 없음.");
      return false;
    }

    let tmp_mcomp_info=null;
    for(let i=0;i<groupLinkArr.length;i++){
      let group_link=groupLinkArr[i];
      if(group_link["a_mcomp_seq"]==mcomp_seq){
        if(group_link["mcomp_info"]){
          tmp_mcomp_info=group_link["mcomp_info"];
        }
      }
    }
    if(tmp_mcomp_info==null){
      alert("학원 정보 없음.");
      return false;
    }

    let login_user_seq="1";
    for(let i=0;i<user_comp_link.length;i++){
      if(user_comp_link[i]["a_mcomp_seq"]==tmp_mcomp_info["a_seq"]){
        login_user_seq=user_comp_link[i]["a_mcomp_user_seq"];
      }
    }

    if(!confirm("다른 학원으로 로그인 하시겠습니까?")){
      return false;
    }

    let login_form_data={
      comp_id:tmp_mcomp_info["a_id"],
      user_seq:login_user_seq,
      is_no_pw_login:"1",
      is_login_log:"",
    };

    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/login/login',login_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        goLogOutAndMove({
          login_data:response.data.data
        });
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const goLogOutAndMove=(inData:any)=>{
    let opt_obj={
      "login_data":{},
      ...inData
    };
    let login_data=opt_obj["login_data"];
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/login/logout',{ 
      token_id: localStorage.intoyou_token,
      is_login_log:"",
    },get_axios_data()).then((response) => {
      localStorage.setItem('intoyou_token', login_data["token_id"]);
      var client_info=login_data["client_info"];
      client_info.main_user_seq=user.main_user_seq;
      client_info.is_other_comp_login="1";
      dispatch(setUser(client_info));
      localStorage.sort_data_arr=JSON.stringify(client_info.sort_data);
      if(client_info.comp_sort=="main"){
        navigate("/main");
      }else if(client_info.user_grade=="student"){
        navigate("/comp_stu");
      }else if(client_info.user_grade=="parent"){
        navigate("/comp_par");
      }else{
        navigate("/comp");
      }
    });
  };

  return (
    <div ref={wrap_ref}>
      <div className="search-box">
        그룹
        <select className="search-input_select" value={group_seq}
          onChange={(e: any) => {
            set_group_seq(e.target.value);
            list_group_link(e.target.value);
          }} style={{width:80}}>
          <option value="" >선택없음</option>
          {
            groupArr.map((item: any, idx: number) => {
              return (
                <option key={idx} value={item.a_seq}>{item.a_name}</option>
              );
            })
          }
        </select>
        학원
        <select className="search-input_select" name="s_mcomp_seq" value={mcomp_seq}
          onChange={(e: any) => {
            set_mcomp_seq(e.target.value);
          }} style={{width:80}} >
          <option value="" >선택없음</option>
          {
            groupLinkArr.map((item: any, idx: number) => {
              
              return (
                <option key={idx} value={item.a_mcomp_seq}>{item.a_mcomp_name}</option>
              );
            })
          }
        </select>
        <button className="btn-s btn-dark" onClick={()=>{login_other_comp();}}>타학원 로그인</button>
      </div>
    </div>
  );
};
export default LoginOtherArea;