import CompLayout from "@/pcomponents/comp/layout/layout";
import Style from "./css/style.module.css";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import RoomListWrap from "./area/room_list_wrap";

function DeskMain() {
  
  return (
    <CompLayout isConTitle={false}>
      <RoomListWrap></RoomListWrap>
    </CompLayout>
  );
}

export default DeskMain;