import { useState, useEffect } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import {customHeaderFunc} from '@/pcomponents/common/date_picker/custom_header';
import {CustomInputSearch} from '@/pcomponents/common/date_picker/custom_input';
import strFunc from '@/lib/lyg/string';

const SearchArea = (props:any) => {
  let myProps={
    listOpt:{},
    list:(inData:any)=>{},
    ...props
  };
  let listOpt=props.listOpt;
  const [classSelectArr,setClassSelectArr] = useState<any>([]);
  const [schoolNameSelectArr,setSchoolNameSelectArr] = useState<any>([]);
  useEffect(()=>{
    
    getClassFrontArr();
  },[]);

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'||target.type === 'radio'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }
    if(name=="s_search_text"){
      return false;
    }
    myProps.list({now_page:1,[name]: value});
  };
  const onChangeDatePicker=(key:string,date:Date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    myProps.list({now_page:1,[key]: date_str});
  };

  const onKeyUpEnterSearch=(e:any)=>{
    if(e.key === 'Enter'){goSearch();}
  };

  const goSearch=()=>{
    myProps.list({...listOpt});
  };

  const getClassFrontArr=()=>{
    let class_list_form={
      "s_add_school_arr":"1"
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/class/class/front_class_arr',class_list_form,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setClassSelectArr(response.data["data"]["class_arr"]);
        setSchoolNameSelectArr(response.data["data"]["school_name_arr"]);
      }else{
        
      }
    });
  };

  let oder_id_select_arr=[
    {"text":"예약일","value":"a_reserve_date"},
    {"text":"예약일 역순","value":"a_reserve_date DESC, a_create_date DESC"},
    {"text":"등록순","value":"a_create_date"},
    {"text":"등록역순","value":"a_create_date DESC, a_is_success"},
    {"text":"미상담","value":"a_is_success"},
    {"text":"상담","value":"a_is_success DESC"},
  ];

  return (
    <div className="search_round_box_wrap">
      <div className="search_round_title_bar">
        상담검색
      </div>
      <div className="search_round_box">
        <div className="search_item">
          <div className="search_item_title" >이름</div>
          <div className="search_item_input_wrap" >
            <input type="text" className="search_item_input" name="s_stu_name_like"
              value={listOpt.s_stu_name_like} onChange={handleInputChange} placeholder="이름" />
            <span className="basic_search_item_book_mark"
              onClick={() => {
                let change_s_stu_bookmark = listOpt.s_stu_bookmark;
                if (change_s_stu_bookmark == "1") {
                  change_s_stu_bookmark = "";
                } else {
                  change_s_stu_bookmark = "1";
                }
                strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
                myProps.list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
              }}>
              {listOpt.s_stu_bookmark == "1" ? "★" : "☆"}
            </span>
          </div>
        </div>
        <div className="search_item">
          <div className="search_item_title" >연락처</div>
          <div className="search_item_input_wrap" >
            <input type="text" className="search_item_input" name="s_stu_phone_like"
              value={listOpt.s_phone_like} onChange={handleInputChange} placeholder="연락처" />
          </div>
        </div>
        <div className="search_item">
          <div className="search_item_title" >학교</div>
          <div className="search_item_input_wrap" >
            <input type="text" className="search_item_input" name="s_stu_school_name_like"
              value={listOpt.s_school_name_like} onChange={handleInputChange} placeholder="학교" />
          </div>
        </div>
        <div className="search_item">
          <div className="search_item_title" >제목</div>
          <div className="search_item_input_wrap" >
            <input type="text" className="search_item_input" name="s_title_like"
              value={listOpt.s_title_like} onChange={handleInputChange} placeholder="제목" />
          </div>
        </div>
        <div className="search_item">
          <div className="search_item_title" >정렬</div>
          <div className="search_item_input_wrap" >
            <select className="search_item_input_select" name="order_id" value={listOpt.order_id} onChange={handleInputChange} >
            <option value="">전체</option>
            {
              oder_id_select_arr.map((item:any,idx:number)=>{
                return (
                  <option value={item.value} key={idx}>{item.text}</option>
                );
              })
            }
            </select>
          </div>
        </div>
        <div className="search_item">
          <div className="search_item_title" >기간</div>
          <div className="search_item_input_wrap" >
            <div style={{display:"inline-block",width:90}}>
              <DatePicker 
                wrapperClassName="pl-1"
                selected={listOpt.s_start_date!=""?new Date(listOpt.s_start_date):null} 
                onChange={(date:Date) => {
                  onChangeDatePicker("s_start_date",date);
                }}
                locale={ko} 
                dateFormat="yyyy-MM-dd"
                customInput={<CustomInputSearch />}
                renderCustomHeader={customHeaderFunc}
              />
            </div>
            ~
            <div style={{display:"inline-block",width:90}}>
              <DatePicker 
                wrapperClassName="pl-1"
                selected={listOpt.s_end_date!=""?new Date(listOpt.s_end_date):null} 
                onChange={(date:Date) => {
                  onChangeDatePicker("s_end_date",date);
                }}
                locale={ko} 
                dateFormat="yyyy-MM-dd"
                customInput={<CustomInputSearch />}
                renderCustomHeader={customHeaderFunc}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchArea;