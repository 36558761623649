//import SearchArea from "@/pages/comp/basic/student/list/area/search/search_area";
import { useEffect } from 'react';
import search_ico from "@/img/ico/search3.png";
import search_ico2 from "@/img/ico/search2.png";
import strFunc from '@/lib/lyg/string';
import SearchLectureSelect from "@/pcomponents/common/content/basic/student/search/search_lecture_select";

const SearchArea = (props: any) => {
  let myProps = {
    listOpt: {},
    xColumnArr: {},
    list: (inData: any) => { },
    ...props
  };
  let listOpt = myProps.listOpt;

  useEffect(() => {
    
  }, []);

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    if (name == "s_search_text") {
      return false;
    }
    myProps.list({ now_page: 1, [name]: value });
  };
  
  return (
    <div className="basic_search_wrap">
      <div className="basic_search_title_bar">
        <img src={search_ico} />
        학생검색
      </div>
      <div className="basic_search_box_wrap">
        <div className="basic_search_item_input_wrap" >
          <input type="text" className="search_item_input" name="s_user_name_like"
            value={listOpt.s_user_name_like} onChange={handleInputChange} placeholder="이름" autoComplete="off" />
        </div>
        <div className="basic_search_item_input_wrap" >
          <input type="text" className="search_item_input" name="s_school_name_like"
            value={listOpt.s_school_name_like} onChange={handleInputChange} placeholder="학교" autoComplete="off" />
        </div>
        <div className="basic_search_item_input_wrap" >
          <SearchLectureSelect
            name="s_stu_lecture_seq"
            value={listOpt.s_stu_lecture_seq}
            on_change_input={(inData:any)=>{
              let name=inData.name;
              let value=inData.value;
              myProps.list({ now_page: 1, [name]: value });
            }}
          ></SearchLectureSelect>
        </div>
        <div className="basic_search_item_input_wrap" >
          <select className="search_item_input_select" name="s_stu_grade" value={listOpt.s_stu_grade} onChange={handleInputChange}>
            <option value="">학년</option>
            <option value="elementary">초</option>
            <option value="middle">중</option>
            <option value="high">고</option>
            {
              myProps.xColumnArr.select_arr.a_stu_grade.map((item: any, idx: number) => {
                return (
                  <option value={item.value} key={idx}>{item.text}</option>
                );
              })
            }
          </select>
        </div>
        <div className="basic_search_item_input_wrap" >
          <select className="search_item_input_select" name="s_stu_state" value={listOpt.s_stu_state} onChange={handleInputChange} >
            <option value="">전체</option>
            <option value="now,ready">대기,재원</option>
            {
              myProps.xColumnArr.select_arr.a_stu_state.map((item: any, idx: number) => {
                return (
                  <option value={item.value} key={idx}>{item.text}</option>
                );
              })
            }
          </select>
        </div>
        <div className="basic_search_item_book_mark"
          onClick={() => {
            let change_s_stu_bookmark = listOpt.s_stu_bookmark;
            if (change_s_stu_bookmark == "1") {
              change_s_stu_bookmark = "";
            } else {
              change_s_stu_bookmark = "1";
            }
            strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
            myProps.list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
          }}>
          {listOpt.s_stu_bookmark == "1" ? "★" : "☆"}
        </div>
        <div className="basic_search_item_input_wrap">
          <button className="btn-l btn-yellowish2" onClick={() => { myProps.list({ now_page: 1 }); }} style={{ padding: "6px" }}>
            <img src={search_ico2} style={{ maxHeight: 25, minWidth: 20 }} />
          </button>
        </div>
      </div>
      {props.children}
    </div>
  );
};

export default SearchArea;