import strFunc from '@/lib/lyg/string';
import { useState, useRef, useEffect, useMemo, useCallback,forwardRef } from 'react';
import getXcolumnJson from "@/pages/comp/edu/month_prescribe/list/xcolumn/list";
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";

function ViewPopupContent(props:any) {
  let myProps={
    is_view_write_btn:true,
    ...props
  };
  //props.rowData.a_user_name;
  const [info,set_info]=useState(props.rowData);
  useEffect(()=>{
    //get_info_by_ajax();
  },[]);
  const get_info_by_ajax=()=>{
    if(strFunc.is_empty(info["a_seq"])){
      return false;
    }
    let pri_val=info["a_ymd"]+","+info["a_seq"];
    let tmp_list_opt={
      "s_pri_arr":[pri_val],
      "s_addon_user": "1",
      "is_need_count":"1",
      "is_need_info_arr":"1",
      "is_no_limit":"",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/month_prescribe/list', tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if(response.data["data"]["info_arr"].length>0){
            set_info(response.data["data"]["info_arr"][0]);
          }
        } else {

        }
      });
  };
  let row_data={...info};
  if(info.stu_info){
    row_data["a_stu_name"]=info.stu_info["a_user_name"];
  }
  let week_i_str:any=parseInt(strFunc.uncomma(row_data.week_i));
  let month_num=parseInt(strFunc.uncomma(row_data.month_num));
  week_i_str++;
  if(week_i_str==0){
    week_i_str="전달 마지막";
  }else{
    week_i_str=month_num+"월 "+week_i_str;
  }
  let init_content_title_str = "학생명: "+row_data["a_stu_name"]+"\n";
  init_content_title_str+=week_i_str+" 주차 학습처방 내용 \n\n";
  init_content_title_str+="1.진도전략:\n";
  init_content_title_str+="  "+row_data.a_progress_reason+"\n\n";
  init_content_title_str += "2.현재진도:\n";
  init_content_title_str+="  "+row_data.a_progress+"\n\n";
  init_content_title_str += "3.성장한부분:\n";
  init_content_title_str+="  "+row_data.a_grow+"\n\n";
  init_content_title_str += "4.발견:\n";
  init_content_title_str+="  "+row_data.a_lack+"\n\n";
  init_content_title_str += "5.학습처방:\n";
  init_content_title_str+="  "+row_data.a_content+"\n";
  if(row_data.comment_arr){
    init_content_title_str+="\n";
    init_content_title_str+="질문답변\n";
    for(let i=0;i<row_data.comment_arr.length;i++){
      let comment_info=row_data.comment_arr[i];
      let tmp_idx=i+1;
      let qa_str="Q";
      if(comment_info.a_qna_sort=="a"){
        qa_str="A";
      }
      init_content_title_str+=" "+tmp_idx+". "+qa_str+" "+comment_info["a_message"]+"\n";
      init_content_title_str+=" "+comment_info["a_create_date"]+" "+comment_info["a_responser"]+"\n";
    }
  }
  const [content_title_str,set_content_title_str]=useState(init_content_title_str);
  const [copy_msg,set_copy_msg]=useState("");

  const copy_str_to_clipboard=(str:string)=>{
    navigator.clipboard.writeText(str);
    set_copy_msg("복사됨.");
    setTimeout(()=>{
      set_copy_msg("");
    },2000);
  }
  return (
    <div>
      <div className="view-table-div mt-2">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="30%"></col>
            <col width="20%"></col>
            <col width="30%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>원생</th>
              <td>{row_data.a_stu_name}</td>
              <th>날짜</th>
              <td>
                {row_data.a_reserve_date}
              </td>
            </tr>
            <tr>
              <th colSpan={4}>내용</th>
            </tr>
            <tr>
              <td colSpan={4}>
                <TextAreaComponent
                  name={"content_title_str"}
                  value={content_title_str}
                  onChange={(e:any)=>{
                    set_content_title_str(e.target.value);
                  }}
                ></TextAreaComponent>
              </td>
            </tr>
            <tr>
              <td colSpan={4} style={{textAlign:"center"}}>
                <button className="btn btn-sky" onClick={()=>{copy_str_to_clipboard(content_title_str);}}>복사</button>
                <span className="ml-1" style={{color:"blue"}}>{copy_msg}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        {/* {myProps.is_view_write_btn&&
        <button className="btn btn-line-gray" onClick={()=>{
            props.closePopup();
            props.openOnePopup("write",true);
          }}>수정</button>
        } */}
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default ViewPopupContent;
