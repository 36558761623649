import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";

import SearchOrderRadio from "@/pcomponents/common/crud/write/radio/radio_arr";
import SearchArea from "./area/search/search_area";
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';
import DateFunc from '@/lib/lyg/date_func';
import MyScreenFunc from '@/pcomponents/common/screen/func/screen_func';


function ListPage(){
  let user=useSelector((state:any) => state.user);
  const xColumnArr=getXcolumnJson();
  const ListComponentRef=useRef<any>(null);
  let origin_order_id="a_create_date DESC";
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      order_id:origin_order_id,
      's_date_type':'a_date',
      's_start_date':DateFunc.get_date_format(new Date(),"Y-m-01"),
      's_end_date':DateFunc.get_date_format(new Date(),"Y-m-t"),
      "s_addon_edu_room":"1",
      "s_addon_desk":"1",

      "s_user_name_like":"",
      "s_school_name_like":"",
      "s_class_name_like":"",
      "s_stu_grade":"",
      "s_stu_state":"",
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});
  let is_under_width=MyScreenFunc.get_is_under();

  useEffect(() => {
    
  }, []);

  const list = (inOptObj:any)=>{
    let tmpPageData=pageData;
    if(inOptObj!=undefined){
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/desku/list',pageData.listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        ListComponentRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const goDelete = useCallback( () => {
    let selected_row=ListComponentRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    let del_form_data_json={
      "data_arr":selected_row,
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/desku/delete',del_form_data_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        list({});
      }else{
        alert(response.data["msg"]);
      }
    });
  }, []);

  const rowOnClick = useCallback( (rowData:any,idx:number,e:any) => {
    // if(key=="a_user_name"||key=="a_user_id"){
    //   openOnePopup("write",true);
    // }
  }, []);

  const customCellRenderer= useCallback( (params:any) => {
    /*
      {
        "rowData":rowData,
        "idx":idx,
        "key":key,
        "value":row_val
      }
      */
    var key=params.key;
    var render_str=params.value;

    if(key=="row_view_start"){
      render_str=strFunc.HourMinuteToTime(params.rowData["a_start_h"],params.rowData["a_start_m"]);
    }else if(key=="row_view_end"){
      render_str=strFunc.HourMinuteToTime(params.rowData["a_end_h"],params.rowData["a_end_m"]);
    }else if(key=="row_view_period"){
      let period_time_json=strFunc.secondsToTimeJson(strFunc.uncomma(params.rowData["a_target_time_sec"]));
      render_str=period_time_json["h"]+":"+period_time_json["i"];
    }else if(key=="row_view_room_name"){
      //edu_room_info
      if(!strFunc.is_empty(params.rowData.edu_room_info)){
        render_str=params.rowData.edu_room_info["a_name"];
      }
    }else if(key=="row_view_desk_name"){
      //desk_info
      if(!strFunc.is_empty(params.rowData.desk_info)){
        render_str=params.rowData.desk_info["a_name"];
      }
    }
    
    return render_str;
  }, []);
  
  return (
    <CompLayout isConTitle={false}>
      <div className="con_wrap" >
        <SearchArea 
          listOpt={{...pageData.listOpt}}
          xColumnArr={{...xColumnArr}}
          list={list}
          ></SearchArea>
        
        <div className="basic_list_title_bar_wrap">
          {is_under_width==false&&
          <>
            <span>
              총 <span className="text-blue-600">{countInfo.tot}</span> 명
            </span>
            <SearchOrderRadio
              valueTextArr={[
                {"text":"최근등록순","value":"a_create_date DESC"},
                {"text":"이름순","value":"a_user_name"},
              ]}
              value={pageData.listOpt.order_id}
              handleInputChange={(e:any)=>{
                list({
                  "now_page":1,
                  "order_id":e.target.value
                });
              }}
            ></SearchOrderRadio>
          </>
          }
          <div className="basic_title_bar_right_box">
            <button className="btn-m btn-red ml-1" onClick={()=>{goDelete();}}>삭제</button>
          </div>
        </div>
        <div className="list_round_box">
          <ListComponent
          ref={ListComponentRef}
          xColumnArr={{...xColumnArr}}
          gridOpt={{
            fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
            is_add_idx_num:false,
            is_add_checkbox:true,
            td_align:"center",
            height:"47vh",
            is_fix_width:true,
            onGridReady:()=>{
              list({});
            },
            rowOnClick:rowOnClick,
            customCellRenderer:customCellRenderer,
            class_name_json:{
              table_div:"basic_list_div",
              td_div:"row-col-div",
              input_check:"chk_row",
              row_input:"row-input",
              data_row_tr:"data_row_tr",
              active_row:"active",
              empty_con_div:"list_empty_content_div",
            },
          }}
          ></ListComponent>
          <Paging now_page={pageData.listOpt.now_page}
            num_per_page={pageData.listOpt.num_per_page}
            total_rec={countInfo.tot} 
            onChangePage={(now_page:number)=>{list({now_page:now_page});}}
            is_view_num_per_page={true}
            onChangeNumPerPage={(num_per_page:number)=>{list({now_page:1,num_per_page:num_per_page});}}></Paging>
        </div>
      </div>
    </CompLayout>
  );
}

export default ListPage;