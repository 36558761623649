import { useState, useRef, useEffect, useMemo, useCallback, forwardRef } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import { useSelector, useDispatch } from 'react-redux';
import getXcolumnJson from "@/pages/comp/edu/month_prescribe/list/xcolumn/list";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FindStudent from "@/pages/comp/basic/student/popup/find_multy";
import { default_info } from "@/pages/comp/edu/month_prescribe/common/data/default_data";
import WriteArea from "@/pages/comp/edu/month_prescribe/common/write";

function WritePopupContent(props: any) {
  let myProps = {
    isUpdate: false,
    stu_seq: "",
    stu_name: "",
    s_stu_seq_arr: [],
    select_date: "",
    rowData: {},
    ...props,
  };
  let user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  const pageData = {
    "write_btn_text": "등록",
    "confirm_msg": "등록 하시겠습니까?",
  };
  if (props.isUpdate) {
    pageData["write_btn_text"] = "수정";
    pageData["confirm_msg"] = "수정 하시겠습니까?";
  }

  const init_data = {
    ...default_info,
    "a_ymd": DateFunc.get_date_format(new Date(), "Ymd"),
    "a_seq": "",
    "a_stu_seq": myProps.stu_seq,
    "a_stu_name": myProps.stu_name,
    "a_reserve_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
    "a_writer_seq": user.user_seq,
    "a_writer_name": user.user_name,
    "a_is_write": "1",
    "a_write_date": DateFunc.get_date_format(new Date(), "Y-m-d h:i:s")
  };
  const [rowData, setRowData] = useState({
    ...init_data,
    file_obj: null,
  });
  const [comment_list, set_comment_list] = useState([]);
  const last_row_info_ref = useRef(rowData);
  const [select_stu_info_arr, set_select_stu_info_arr] = useState<any>([]);
  const [refresh, set_refresh] = useState(false);

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "find",//view
    isUpdate: false,
    s_except_user_seq: {},
    title: "팝업",
    width: "800px",
    height: "80%",
  });

  useEffect(() => {
    if (props.isUpdate) {
      let changeRowData: any = {
        ...rowData,
        ...props.rowData
      };
      setRowData(changeRowData);
      list({ row_data: changeRowData });
      get_comment_list({
        row_data: changeRowData,
      });
    } else {
      last_row_info_ref.current = { ...rowData };
      get_writer_info_by_ajax();
    }
  }, []);

  const list = (inData: any) => {
    let opt_obj = {
      row_data: rowData,
      ...inData
    };
    if (strFunc.is_empty(opt_obj["row_data"]["a_seq"])) {
      return false;
    }
    let row_pri_val = opt_obj["row_data"]["a_ymd"] + "," + opt_obj["row_data"]["a_seq"];
    let formJsonData = {
      "s_pri_arr": [row_pri_val],
      "s_addon_user": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/month_prescribe/list', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            let tmp_row_data = {
              ...init_data,
              ...response.data["data"]["info_arr"][0]
            };
            setRowData(tmp_row_data);
            last_row_info_ref.current = tmp_row_data;
            if (tmp_row_data.stu_info) {
              set_select_stu_info_arr([tmp_row_data.stu_info]);
            }
          }
        } else {

        }
      });
  };

  const get_comment_list = (inData: any) => {
    let opt_obj = {
      row_data: rowData,
      ...inData
    };
    if (strFunc.is_empty(opt_obj["row_data"]["a_seq"])) {
      return false;
    }
    let row_pri_val = opt_obj["row_data"]["a_ymd"] + "," + opt_obj["row_data"]["a_seq"];
    let formJsonData = {
      "s_par_id": "study_diary",
      "s_par_seq": row_pri_val,
      "order_id": "a_create_date",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/comment/comment/list', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_comment_list(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const get_writer_info_by_ajax = () => {
    if (strFunc.is_empty(user.user_seq)) {
      return false;
    }
    if (props.isUpdate) {
      return false;
    }
    let formJsonData = {
      "s_pri_arr": [user.user_seq],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            let writer_info = response.data["data"]["info_arr"][0];
            if (props.isUpdate == false) {
              let changeRowData: any = {
                ...rowData,
                a_subject_name: writer_info["a_main_subject"],
              };
              if (!strFunc.is_empty(myProps.select_date)) {
                changeRowData["a_reserve_date"] = myProps.select_date;
                changeRowData["a_ymd"] = strFunc.getNumber2(myProps.select_date);
              }
              last_row_info_ref.current = changeRowData;
              setRowData(changeRowData);
              get_write_stu_arr_by_ajax();
            }
          }
        } else {

        }
      });
  };

  const get_write_stu_arr_by_ajax = () => {
    if (strFunc.is_empty(myProps.s_stu_seq_arr)) {
      return false;
    }
    if (props.isUpdate) {
      return false;
    }
    let formJsonData = {
      "s_pri_arr": myProps.s_stu_seq_arr,
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            set_select_stu_info_arr(response.data["data"]["info_arr"]);
            let stu_info = response.data["data"]["info_arr"][0];
            let changeRowData: any = {
              ...last_row_info_ref.current,
              a_stu_seq: stu_info["a_seq"],
              a_stu_name: stu_info["a_user_name"],
            };
            setRowData(changeRowData);
            last_row_info_ref.current = changeRowData;
          }
        } else {

        }
      });
  };

  const goWrite = (e: any) => {
    if (strFunc.is_empty(rowData.a_stu_seq)) {
      alert("학생 선택 필요.");
      return false;
    }
    if (strFunc.is_empty(rowData.a_writer_seq)) {
      alert("작성자 정보 없음.");
      return false;
    }
    if (strFunc.is_empty(rowData.a_subject_name)) {
      alert("과목선택 필요.");
      return false;
    }
    if (!confirm(pageData["confirm_msg"])) {
      return false;
    }

    let wRowData = { ...rowData };
    if (props.isUpdate == false) {
      wRowData["a_ymd"] = strFunc.getNumber2(wRowData["a_reserve_date"]);
    }
    let w_data_arr = [wRowData];
    if (props.isUpdate == false && select_stu_info_arr.length >= 2) {
      for (let i = 0; i < select_stu_info_arr.length; i++) {
        let stu_info = select_stu_info_arr[i];
        if (stu_info["a_seq"] == wRowData["a_stu_seq"]) {

        } else {
          let add_row_data = {
            ...wRowData,
            a_stu_seq: stu_info["a_seq"],
            a_stu_name: stu_info["a_user_name"],
          };
          add_row_data["a_progress_reason"] = "";
          add_row_data["a_progress"] = "";
          if (stu_info.last_mprescribe_arr && stu_info.last_mprescribe_arr.length > 0) {
            for (let i = 0; i < stu_info.last_mprescribe_arr.length; i++) {
              let last_mprescribe_info = stu_info.last_mprescribe_arr[i];
              if (last_mprescribe_info["a_subject_name"] == add_row_data["a_subject_name"]
                && last_mprescribe_info["a_writer_seq"] == add_row_data["a_writer_seq"]) {
                add_row_data["a_progress_reason"] = last_mprescribe_info["a_progress_reason"];
                add_row_data["a_progress"] = last_mprescribe_info["a_progress"];
              }
            }
          }
          if (stu_info.last_daily_report_arr && stu_info.last_daily_report_arr.length > 0) {
            for (let i = 0; i < stu_info.last_daily_report_arr.length; i++) {
              let last_report_info = stu_info.last_daily_report_arr[i];
              if (last_report_info["a_subject"] == add_row_data["a_subject_name"]) {
                add_row_data["a_progress"] = last_report_info["a_textbook"]+" "+last_report_info["a_textbook_study"];
              }
            }
          }
          w_data_arr.push(add_row_data);
        }
      }
    }
    let formJsonData = {
      "data_arr": w_data_arr,
      "is_default_val": "1",
      "is_update": "",
    };
    if (props.isUpdate) {
      formJsonData["is_default_val"] = "";
      formJsonData["is_update"] = "1";
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/month_prescribe/write', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          let priVal = xColumnArr.func.getPriValByDataRow({
            xColumnArr: xColumnArr,
            rowData: response.data["data"][0]
          });
          props.callback({ select_seq: priVal });
          props.closePopup();
        } else {
          alert(response.data["msg"]);
        }
      });

  }

  return (
    <div>
      {select_stu_info_arr.length >= 2 &&
        <div>
          <div>다량등록 학생정보</div>
          <div style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
            {select_stu_info_arr.map((item: any, idx: number) => {
              return (
                <div key={idx} style={{ display: "inline-block", padding: "0 3px", background: "#bdffc0", borderRadius: "3px" }} >
                  {item.a_user_name}
                  <button className="btn-s btn-red ml-1"
                    onClick={() => {
                      let change_select_stu_info_arr = [];
                      for (let i = 0; i < select_stu_info_arr.length; i++) {
                        let tmp_stu_info = select_stu_info_arr[i];
                        if (item["a_seq"] == tmp_stu_info["a_seq"]) {

                        } else {
                          change_select_stu_info_arr.push(tmp_stu_info);
                        }
                      }
                      set_select_stu_info_arr(change_select_stu_info_arr);
                    }}>X</button>
                </div>
              );
            })}
          </div>
        </div>
      }
      <div className="mt-1">
        <WriteArea
          rowData={rowData}
          setRowData={setRowData}
          isUpdate={myProps.isUpdate}
          is_find_stu={true}
          comment_list={comment_list}
          refresh_comment={get_comment_list}
          select_stu_info_arr={select_stu_info_arr}
          set_select_stu_info_arr={set_select_stu_info_arr}
          set_refresh={() => { set_refresh(!refresh); }}
        ></WriteArea>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
        <button className="btn btn-line-gray" onClick={() => { myProps.closePopup(); }}>닫기</button>
      </div>
    </div>
  );
}

export default WritePopupContent;
