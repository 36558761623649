//import SearchLectureSelect from "@/pcomponents/common/content/basic/student/search/search_lecture_select";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useEffect } from 'react';
import strFunc from '@/lib/lyg/string';
import Style from "./css/style.module.css";

function SearchLectureSelect(props: any) {
  let myProps = {
    value: "",
    name: "s_stu_lecture_seq",
    on_change_input: (inData: any) => { },
    wrap_style:{},
    select_title_style:{},
    ...props
  };

  useEffect(() => {
    get_lecture_select_arr();
  }, []);

  let default_wrap_style={
    position:"relative",
  };
  let wrap_style={
    ...default_wrap_style,
    ...myProps.wrap_style
  };
  let default_select_title_style={
    lineHeight:"36px",
  };
  let select_title_style={
    ...default_select_title_style,
    ...myProps.select_title_style,
  };

  const get_lecture_select_arr = () => {
    let class_list_form = {
      "s_state": "ing",
      "order_id": "a_order_num",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/list', class_list_form, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_lectrue_select_arr(response.data["data"]["info_arr"]);
          if (response.data["data"]["info_arr"].length == 0) {
            strFunc.set_storage("s_stu_lecture_seq", "");
          }
        } else {

        }
      });
  };

  const [lectrue_select_arr, set_lectrue_select_arr] = useState<any>([]);
  const [is_show_select, set_is_show_select] = useState(false);

  const get_select_value_arr = (value_str: any) => {
    if(Array.isArray(value_str)){
      return value_str;
    }
    let select_value_arr = value_str.split(",");
    select_value_arr = strFunc.remove_empty_of_arr(select_value_arr);
    return select_value_arr;
  };
  const get_select_lecture_name_arr = (select_value_arr:[]) => {
    let select_lecture_name_arr = [];
    for (let i = 0; i < lectrue_select_arr.length; i++) {
      let lecture_info = lectrue_select_arr[i];
      if (strFunc.str_in_array(lecture_info["a_seq"], select_value_arr) != -1) {
        select_lecture_name_arr.push(lecture_info["a_name"]);
      }
    }
    return select_lecture_name_arr;
  };
  const on_select_lecture_seq = (inData: any) => {
    let opt_obj = {
      value: "",
      name: "",
      ...inData
    };
    let value = opt_obj.value;
    let name = opt_obj.name;
    let pre_value_arr = get_select_value_arr(myProps.value);
    //공백이면 없애기
    if (strFunc.is_empty(value)) {
      value = [];
    } else if (strFunc.str_in_array(value, pre_value_arr) != -1) {
      //이미있다면제거
      let new_value_arr = [];
      for (let i = 0; i < pre_value_arr.length; i++) {
        if (value == pre_value_arr[i]) {

        } else {
          new_value_arr.push(pre_value_arr[i]);
        }
      }
      value = new_value_arr;
    } else {
      //없다면 추가
      pre_value_arr.push(value);
      value = pre_value_arr;
    }
    value=strFunc.remove_empty_of_arr(value);
    strFunc.set_storage("s_stu_lecture_seq", value.join(","));
    myProps.on_change_input({
      name: name,
      value: value,
    });
  };
  let select_value_arr = get_select_value_arr(myProps.value);
  let select_lecture_name_arr = get_select_lecture_name_arr(select_value_arr);
  let select_lecture_name_str = strFunc.cut_str(select_lecture_name_arr.join(","), 10, "..");
  if (strFunc.is_empty(select_lecture_name_str)) {
    select_lecture_name_str = "수업";
  }


  return (
    <div style={wrap_style}>
      <div className={Style.select_title} style={select_title_style}
        onClick={() => { set_is_show_select(!is_show_select); }}>
        {select_lecture_name_str}
        {is_show_select&&
          <button className="btn-s btn-red" style={{position:"absolute",right:0,top:2}}>X</button>
        }
      </div>
      {is_show_select &&
        <div className={Style.select_wrap}>
          <div className={Style.select_wrap_con} >
            <div className={Style.select_row_box} 
              onClick={()=>{
                on_select_lecture_seq({
                  name: myProps.name,
                  value: ""
                });
              }}>
              전체
            </div>
            {
              lectrue_select_arr.map((item: any, idx: number) => {
                let is_select_row = false;
                if (strFunc.str_in_array(item.a_seq, select_value_arr) != -1) {
                  is_select_row = true;
                }
                let select_row_class_name = Style.select_row_box;
                if (is_select_row) {
                  select_row_class_name += " " + Style.active;
                }

                return (
                  <div key={idx}
                    className={select_row_class_name}
                    onClick={(e: any) => {
                      on_select_lecture_seq({
                        name: myProps.name,
                        value: item.a_seq
                      });
                    }}>
                    {item.a_name}
                  </div>
                );
              })
            }
          </div>
        </div>
      }
    </div>
  );
};
export default SearchLectureSelect;