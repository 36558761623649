import { useState, useEffect } from 'react';
import Style from "./css/style.module.css";
import getXcolumnJson from "./xcolumn/list";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import ViewTodoDaySortPopup from "@/pages/comp/plan/calendar/area/popup/view_todo_day_sort";
import SendSMSPopup from "@/pages/comp/basic/sms/popup/send_sms";
import AddAttendPopup from "@/pages/comp/edu/attend/popup/add_attend";
import WriteTodoPopup from "@/pages/comp/plan/stu_todo_history/popup/write";
import ViewStuLayerPopup from "@/pages/comp/basic/student/popup/view_layer";

import TabArea from '@/pcomponents/common/content/list/user/search/tab_area';
import TopSearchDayArea from "./area/search_day";
import TopSearchSort from "./area/top_search_sort";
import TopSearchStateArea from "./area/top_search_state";
import SearchArea from "@/pages/comp/basic/student/list/area/search/search_area";
import CardListArea from "./area/card_list";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import RadioListArea from "@/pcomponents/common/crud/write/radio/radio_arr";
import WritePage from "@/pages/comp/basic/student/list/area/write";
import InfoArrFunc from "./func/info_arr_func";
import { useSelector, useDispatch } from 'react-redux';

function TodoStuCardListArea() {
  let user = useSelector((state: any) => state.user);
  const [tab_id, set_tab_id] = useState("lecture");
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const xColumnArr = getXcolumnJson();
  const [check_row_val_arr, set_check_row_val_arr] = useState([]);
  const [sms_phone_check, set_sms_phone_check] = useState("student");//student,parent,both
  const [select_idx, set_select_idx] = useState(0);
  const [is_detail_view, set_is_detail_view] = useState(false);
  const [refresh, set_refresh] = useState(false);

  useEffect(() => {

    list({});
  }, []);

  const [pageData, setPageData] = useState({
    listOpt: {
      ...xColumnArr.list_opt,
      s_is_login: "1",
      s_stu_state: "now",
      s_grade: ["student"],
      //s_addon_class_user: "1",
      //s_addon_link_user: "1",
      s_user_name_like: "",
      s_school_name_like: "",
      s_class_name_like: "",
      s_stu_grade: "",
      s_addon_todo: "1",
      s_addon_todo_start_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
      s_addon_todo_end_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
      s_addon_todo_s_todo_sort: "",
      s_addon_todo_elapse_time: "1",
      s_addon_todo_s_state: "",
      s_addon_todo_addon_par: "",
      s_addon_home_work_file: "1",
      s_addon_home_work_file_start_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
      s_addon_home_work_file_end_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
      s_addon_plan_study_file: "1",
      s_addon_plan_study_file_start_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
      s_addon_plan_study_file_end_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
      s_addon_attend: "1",
      s_addon_attend_start_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
      s_addon_attend_end_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
      s_stu_bookmark: strFunc.get_storage("s_stu_bookmark"),
      s_stu_lecture_seq: user.user_grade == "master" ? "" : strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
      s_addon_lecture_time: "1",
    }
  });

  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "todo_day_sort",//todo_day_sort, send_sms, add_attend
    rowData: {},
    isUpdate: false,
    select_stu_name: "",
    select_stu_seq: "",
    select_date: "",
    receive_arr: [],
    attend_sort: "in",//in,out
    attend_sort_detail: "",
    user_info_arr: [],
    todo_info: {},
    todo_sort: "study",
    has_header: true,
    write_page_data: {
      is_show: false,
      is_update: false,
      is_view_mode: true,
      is_view_basic: false,
      info_arr: [],
      select_idx: 0,
      default_write_info: {},
    },
    title: "맞춤플랜 보기",
    width: "500px",
    height: "100%",
  });

  const handleDateChange = (inData: any) => {
    let opt_obj = {
      ...inData
    };//s_year,s_month
    let name = opt_obj.target.name;
    let value = opt_obj.target.value;
    let tmp_select_date = pageData.listOpt.s_addon_todo_start_date;

    let tmp_year = tmp_select_date.substring(0, 4);
    let tmp_month = tmp_select_date.substring(5, 7);
    let tmp_day = tmp_select_date.substring(8, 10);
    let tmp_date_json: any = DateFunc.get_date_json(new Date(tmp_select_date));
    if (name == "s_month") {
      tmp_month = value;
    } else if (name == "s_year") {
      tmp_year = value;
    } else if (name == "s_day") {
      tmp_day = value;
    }
    if (parseInt(tmp_day) > tmp_date_json.last_day) {
      tmp_day = strFunc.str_pad({ "str": tmp_date_json.last_day, "pad_length": 2 });
    }
    tmp_select_date = tmp_year + "-" + tmp_month + "-" + tmp_day;

    list({
      s_addon_todo_start_date: tmp_select_date,
      s_addon_todo_end_date: tmp_select_date,
    });
  };

  const list = (inOptObj: any) => {
    if (inOptObj != undefined) {
      let tmpPageData: any = pageData;
      for (let key in tmpPageData.listOpt) {
        if (inOptObj[key] != undefined) {
          tmpPageData.listOpt[key] = inOptObj[key];
        }
      }
      setPageData({ ...tmpPageData });
    }
    let tmp_listOpt = { ...pageData.listOpt };
    tmp_listOpt["s_addon_home_work_file_start_date"] = tmp_listOpt["s_addon_todo_start_date"];
    tmp_listOpt["s_addon_home_work_file_end_date"] = tmp_listOpt["s_addon_todo_end_date"];
    tmp_listOpt["s_addon_plan_study_file_start_date"] = tmp_listOpt["s_addon_todo_start_date"];
    tmp_listOpt["s_addon_plan_study_file_end_date"] = tmp_listOpt["s_addon_todo_end_date"];
    tmp_listOpt["s_addon_attend_start_date"] = tmp_listOpt["s_addon_todo_start_date"];
    tmp_listOpt["s_addon_attend_end_date"] = tmp_listOpt["s_addon_todo_end_date"];

    //하루전꺼부터 조회
    let tmp_start_date = tmp_listOpt["s_addon_todo_start_date"];
    let tmp_start_date_json = DateFunc.get_date_json(new Date(tmp_start_date));
    let pre_date_change_num = -1;
    if (tmp_start_date_json.day == 1) {
      //일요일이면 3일뒤로이동(금요일부터 보이게한다.)
      pre_date_change_num = -3;
    }
    let pre_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(tmp_start_date), 'day', pre_date_change_num), "Y-m-d");
    tmp_listOpt["s_addon_todo_start_date"] = pre_date_str;
    tmp_listOpt["s_addon_home_work_file_start_date"] = pre_date_str;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', tmp_listOpt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          let info_arr = InfoArrFunc.get_info_arr_by_filter({
            info_arr: response.data["data"]["info_arr"],
            list_opt: tmp_listOpt,
            base_date_str: tmp_listOpt["s_addon_todo_end_date"],
            pre_date_str: pre_date_str
          });
          setInfoArr(info_arr);
          setCountInfo({ "tot": info_arr.length });
        } else {

        }
      });
  };

  const openTodoDaySort = (inData: any) => {
    let opt_obj = {
      select_stu_name: "",
      select_stu_seq: "",
      select_date: pageData.listOpt.s_addon_todo_start_date,
      ...inData
    };
    setPopupData({
      ...popupData,
      isOpen: true,
      has_header: true,
      sort: "todo_day_sort",
      title: "",
      ...opt_obj
    });
  };

  const openSendSmsPopup = () => {
    let receive_arr: any = [];
    for (let i = 0; i < infoArr.length; i++) {
      let u_info: any = infoArr[i];
      if (strFunc.str_in_array(u_info["a_seq"], check_row_val_arr) != -1) {
        let row_is_add_stu_phone = false;
        let row_is_add_parent_phone = false;
        if (sms_phone_check == "student") {
          row_is_add_stu_phone = true;
        } else if (sms_phone_check == "parent") {
          row_is_add_parent_phone = true;
        } else if (sms_phone_check == "both") {
          row_is_add_stu_phone = true;
          row_is_add_parent_phone = true;
        }
        if (row_is_add_stu_phone) {
          receive_arr.push({
            phone_num: u_info["a_user_phone"],
            user_name: u_info["a_user_name"],
            user_seq: u_info["a_seq"]
          });
        }
        if (row_is_add_parent_phone) {
          if (u_info.link_user_arr && u_info.link_user_arr[0] && u_info.link_user_arr[0]["par_info"]) {
            let tmp_line_len = u_info.link_user_arr.length;
            for (let j = 0; j < tmp_line_len; j++) {
              let par_info = u_info.link_user_arr[j]["par_info"];
              receive_arr.push({
                "user_seq": par_info.a_seq,
                "phone_num": strFunc.autoHypenPhone(par_info.a_user_phone),
                "user_name": par_info.a_user_name
              });
            }
          }
        }
      }
    }

    if (receive_arr.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }

    setPopupData({
      ...popupData,
      isOpen: true,
      has_header: true,
      sort: "send_sms",
      title: "문자전송",
      receive_arr: receive_arr
    });
  };

  const deleteAttend = (dataArr: any, confirm_msg: string) => {
    if (!confirm(confirm_msg)) {
      return false;
    }
    let del_form_data = {
      "data_arr": dataArr
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/attend/delete', del_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {

        }
      });
  };

  const openAttendPopup = (selected_row: [], attend_sort: string, attend_sort_detail: string) => {
    let pop_title = "등원";
    if (attend_sort == "out") {
      pop_title = "하원";
    }
    if (xColumnArr.select_arr.a_sort_detail) {
      for (let i = 0; i < xColumnArr.select_arr.a_sort_detail.length; i++) {
        let item = xColumnArr.select_arr.a_sort_detail[i];
        if (item.value == attend_sort_detail) {
          pop_title = item.text;
        }
      }
    }

    if (selected_row.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    let attend_date = pageData.listOpt.s_addon_attend_start_date;
    setPopupData({
      ...popupData,
      "isOpen": true,
      has_header: true,
      sort: "add_attend",
      "title": pop_title,
      attend_sort: attend_sort,
      attend_sort_detail: attend_sort_detail,
      select_date: attend_date,
      user_info_arr: selected_row,
    });
  };

  const openAttendBySelect = (attend_sort: string, attend_sort_detail: string) => {
    let selected_rows: any = [];
    for (let i = 0; i < infoArr.length; i++) {
      let u_info: any = infoArr[i];
      if (strFunc.str_in_array(u_info["a_seq"], check_row_val_arr) != -1) {
        selected_rows.push(u_info);
      }
    }
    openAttendPopup(selected_rows, attend_sort, attend_sort_detail);
  };

  const openTodoWriteOnePopup = (inData: any) => {
    let opt_obj = {
      select_date: "",
      select_stu_name: "",
      select_stu_seq: "",
      todo_info: {},
      todo_sort: "study",
      isUpdate: true,
      ...inData
    };
    if (opt_obj["isUpdate"]) {
      if (strFunc.is_empty(opt_obj["todo_info"]["a_seq"])) {
        alert("내용 없음.");
        return false;
      }
    }
    setPopupData({
      ...popupData,
      isOpen: true,
      has_header: true,
      sort: "todo_write_one",
      select_stu_name: opt_obj["select_stu_name"],
      select_stu_seq: opt_obj["select_stu_seq"],
      select_date: opt_obj["select_date"],
      todo_sort: opt_obj["todo_sort"],
      todo_info: opt_obj["todo_info"],
      isUpdate: opt_obj["isUpdate"],
      title: "맞춤플랜",
      ...opt_obj
    });
  };

  const show_detail_view = (info_idx: number) => {
    if (info_idx < 0) {
      info_idx = 0;
    }
    if (info_idx >= infoArr.length) {
      info_idx = infoArr.length - 1;
    }

    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": "원생관리",
      "sort": "stu_view",
      write_page_data: {
        is_show: true,
        is_update: true,
        is_view_mode: true,
        is_view_basic: false,
        select_idx: info_idx,
        info_arr: infoArr,
        default_write_info: {},
      },
      width: "98%",
      height: "99%",
      has_header: false,
    });

    //set_select_idx(info_idx);
    //set_is_detail_view(true);
  };
  const close_detail_view = () => {
    list({});
    set_is_detail_view(false);
  };

  return (
    <div>
      {is_detail_view == false &&
        <div>
          <TabArea
            tab_id={tab_id}
            set_tab_id={set_tab_id}
          ></TabArea>
          <TopSearchDayArea
            select_date={pageData.listOpt.s_addon_todo_start_date}
            set_select_date={(inData: any) => {
              list({
                s_addon_todo_start_date: inData,
                s_addon_todo_end_date: inData,
              });
            }}
            handleDateChange={handleDateChange}
          ></TopSearchDayArea>
          <div className="mt-5">
            <SearchArea
              listOpt={pageData.listOpt}
              list={list}
              xColumnArr={xColumnArr}
            ></SearchArea>
          </div>
          <div className={Style.plan_cal_middle_wrap + " mt-1"}>
            <TopSearchSort
              listOpt={pageData.listOpt}
              list={list}
            ></TopSearchSort>

            <TopSearchStateArea
              Style={Style}
              listOpt={pageData.listOpt}
              list={list}
            ></TopSearchStateArea>
          </div>
          <div className="mt-1" style={{ position: "relative" }}>
            {check_row_val_arr.length > 0 &&
              <span style={{ position: "absolute", top: 3, left: 0 }}>
                <span style={{ color: "green" }}>{check_row_val_arr.length}</span>명
              </span>
            }
            <div className="text-right">
              <RadioListArea
                valueTextArr={[
                  { "value": "student", "text": "학생" },
                  { "value": "parent", "text": "부모님" },
                  { "value": "both", "text": "둘다" },
                ]}
                value={sms_phone_check}
                name={"sms_phone_check"}
                handleInputChange={(e: any) => {
                  set_sms_phone_check(e.target.value);
                }}
              ></RadioListArea>
              <button className="btn-m btn-gray ml-1" onClick={() => { openSendSmsPopup(); }} >문자전송</button>
              <span>
                <button className="btn-m btn-gray ml-1"
                  style={{ background: "#53a500" }}
                  onClick={() => { openAttendBySelect("in", "attend"); }} >등원</button>
                {/* <button className="btn-m btn-gray ml-1" 
              style={{background:"#EABF0B"}}
              onClick={()=>{openAttendBySelect("in","late");}} >지각</button> */}
                <button className="btn-m btn-gray ml-1"
                  style={{ background: "#F26B6F" }}
                  onClick={() => { openAttendBySelect("in", "absent"); }} >결석</button>
                <button className="btn-m btn-gray ml-1"
                  style={{ background: "#7461ff" }}
                  onClick={() => { openAttendBySelect("out", "out"); }} >하원</button>
              </span>
            </div>
          </div>
          <CardListArea
            infoArr={infoArr}
            tab_id={tab_id}
            Style={Style}
            check_row_val_arr={check_row_val_arr}
            set_check_row_val_arr={(inData: any) => {
              set_check_row_val_arr(inData);
              set_refresh(!refresh);
            }}
            base_date_str={pageData.listOpt.s_addon_todo_start_date}
            openTodoDaySort={openTodoDaySort}
            xColumnArr={xColumnArr}
            deleteAttend={deleteAttend}
            openAttendPopup={openAttendPopup}
            openTodoWriteOnePopup={openTodoWriteOnePopup}
            show_detail_user_by_seq={(user_seq: any) => {
              for (let i = 0; i < infoArr.length; i++) {
                if (infoArr[i]["a_seq"] == user_seq) {
                  show_detail_view(i);
                }
              }
            }}
          ></CardListArea>
          <Paging now_page={pageData.listOpt.now_page}
            num_per_page={pageData.listOpt.num_per_page}
            total_rec={countInfo.tot}
            onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
            is_view_num_per_page={true}
            onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
          {popupData.isOpen &&
            <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
              width={popupData.width} height={popupData.height} title_bar_style={{ borderBottom: 0 }}
              content_div_style={{ padding: "10px 0px" }} y={"0"} has_header={popupData.has_header} >
              {popupData.sort == "todo_day_sort" &&
                <ViewTodoDaySortPopup
                  list_opt={{
                    "s_stu_user_name": popupData.select_stu_name,
                    "s_stu_user_seq": popupData.select_stu_seq,
                    "s_start_date": popupData.select_date,
                    "s_end_date": popupData.select_date,
                  }}
                  list={list}
                  openTodoWriteOnePopup={openTodoWriteOnePopup}
                  closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
                ></ViewTodoDaySortPopup>
              }
              {popupData.sort == "send_sms" &&
                <SendSMSPopup
                  receive_arr={popupData.receive_arr}
                  closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
                  callBackRefresh={list}
                ></SendSMSPopup>
              }
              {popupData.sort === "add_attend" &&
                <AddAttendPopup
                  attend_sort={popupData.attend_sort}
                  attend_sort_detail={popupData.attend_sort_detail}
                  attend_date={popupData.select_date}
                  userInfoArr={popupData.user_info_arr}
                  callback={() => { list({}); }}
                  closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></AddAttendPopup>
              }
              {popupData.sort == "todo_write_one" &&
                <WriteTodoPopup
                  stu_seq={popupData.select_stu_seq}
                  stu_name={popupData.select_stu_name}
                  select_date={popupData.select_date}
                  rowData={popupData.todo_info}
                  todo_sort={popupData.todo_sort}
                  isUpdate={popupData.isUpdate}
                  callback={(inData: any) => {
                    list({});
                  }}
                  closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
                ></WriteTodoPopup>
              }
              {popupData.sort === "stu_view" &&
                <ViewStuLayerPopup
                  write_page_data={popupData.write_page_data}
                  callback={() => { list({}); }}
                  closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
                ></ViewStuLayerPopup>
              }
            </LayerPopup>
          }
        </div>
      }
      {is_detail_view &&
        <WritePage
          info_arr={infoArr}
          select_idx={select_idx}
          close_write={close_detail_view}
          list={list}
          is_update={true}
          is_view_stu_info={true}
          is_view_parent={true}
          is_view_supply={true}
        ></WritePage>
      }
    </div>
  );
}

export default TodoStuCardListArea;