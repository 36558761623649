import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState, useCallback } from "react"
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';
import TopManageBarArea from "./top_manage_bar";

const LectureTimeComponent = forwardRef((props: any, ref) => {
  const myProps = {
    rowData: {
      "a_basic_h": "13",
      "a_basic_m": "00",
      "a_basic_length_sec": 60 * 60,
      "a_same_days_week": "",
    },
    list: (inOptObj: any) => {

    },
    room_info_arr: [],
    teacher_arr: [],
    ...props
  };
  let dark = useSelector((state: any) => state.dark);

  let same_days_week_arr: any = [];
  let is_same_days_week = !strFunc.is_empty(myProps.rowData["a_same_days_week"]);
  if (is_same_days_week == false) {
    if (myProps.rowData["a_same_days_week"] == "0") {
      is_same_days_week = true;
    }
  }
  if (is_same_days_week) {
    same_days_week_arr = myProps.rowData["a_same_days_week"].split(",");
  }

  const initLectureTimeRowData = {
    "a_lecture_seq": "",
    "a_day_num": "",
    "a_seq": "",
    "a_name": "",
    "a_start_h": "09",
    "a_start_m": "00",
    "a_end_h": "10",
    "a_end_m": "00",
    "a_room_seq": "",
    "a_teacher_seq": "",
    "a_teacher_name": "",
    "a_date": "",
  };
  let dayNumArr = [
    { value: 0, text: "일", color: "red" },
    { value: 1, text: "월", color: "black" },
    { value: 2, text: "화", color: "black" },
    { value: 3, text: "수", color: "black" },
    { value: 4, text: "목", color: "black" },
    { value: 5, text: "금", color: "black" },
    { value: 6, text: "토", color: "blue" },
  ];
  if (is_same_days_week) {
    let new_dayNumArr = [];
    for (let i = 0; i < dayNumArr.length; i++) {
      if (strFunc.str_in_array(dayNumArr[i]["value"], same_days_week_arr) == -1) {
        new_dayNumArr.push(dayNumArr[i]);
      }
    }
    new_dayNumArr.push({
      value: same_days_week_arr.join(","),
      text: DateFunc.get_day_str_by_day_num_arr(same_days_week_arr, ""),
      color: "black"
    });
    dayNumArr = new_dayNumArr;
  }
  if (dark.dark == "dark") {
    for (let i = 0; i < dayNumArr.length; i++) {
      if (dayNumArr[i]["color"] == "black") {
        dayNumArr[i]["color"] = "white";
      }
    }
  }
  const [timeInfoArr, setTimeInfoArr] = useState<any[]>([]);
  const [refresh, set_refresh] = useState(false);

  useEffect(()=>{
    setTimeInfoArr(get_copy_of_same_days_week_data(timeInfoArr));
    set_refresh(!refresh);
  },[myProps.rowData["a_same_days_week"]]);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setRowDataChild, getTimeInfoArr, setTimeInfoArr
  }));

  const setRowDataChild = (inTimeDataArr: []) => {
    setTimeInfoArr([...inTimeDataArr]);
  };

  const getTimeInfoArr = () => {
    return timeInfoArr;
  };

  const onChangeTimeRowInput = (timeRowData: any, key: string, val: string) => {
    let tmpTimeInfoArr: any[] = [...timeInfoArr];
    let time_col_arr = [
      "a_start_h", "a_start_m",
      "a_end_h", "a_end_m"
    ];
    if (strFunc.str_in_array(key, time_col_arr) != -1) {
      val = strFunc.comma(val);
      val = val.substring(0, 2);
      if (key == "a_start_h" || key == "a_end_h") {
        if (parseInt(val) >= 24) {
          val = "23";
        }
      } else {
        if (parseInt(val) >= 60) {
          val = "59";
        }
      }
      if (parseInt(val) < 0) {
        val = "0";
      }
    }
    let a_teacher_name = "";
    if (key == "a_teacher_seq") {
      for (let i = 0; i < myProps.teacher_arr.length; i++) {
        if (myProps.teacher_arr[i]["a_seq"] == val) {
          a_teacher_name = myProps.teacher_arr[i]["a_user_name"];
        }
      }
    }
    for (let i = 0; i < tmpTimeInfoArr.length; i++) {
      let tmpTimeInfo = tmpTimeInfoArr[i];
      if (tmpTimeInfo.a_day_num == timeRowData.a_day_num && tmpTimeInfo.a_seq == timeRowData.a_seq) {
        tmpTimeInfoArr[i][key] = val;
        if (key == "a_teacher_seq") {
          tmpTimeInfoArr[i]["a_teacher_name"] = a_teacher_name;
        }
      }
    }
    tmpTimeInfoArr=get_copy_of_same_days_week_data(tmpTimeInfoArr);
    setTimeInfoArr([...tmpTimeInfoArr]);
  };

  const getTimeRowTagsByDayNum = (inDayNum: any) => {
    let dayNum = inDayNum;
    if (inDayNum == myProps.rowData["a_same_days_week"]) {
      dayNum = same_days_week_arr[0];
    }
    let dayNumData:any = getDayDataByDayNum(inDayNum);
    let timeRows: any = getTimeRowsByDayNum(dayNum);
    let timeRowTags = timeRows.map((item: any, idx: number) => {
      let first_td: any = "";
      if (idx == 0) {
        first_td = (
          <td rowSpan={timeRows.length} style={{ color: dayNumData?.color, verticalAlign: "top" }}>
            <div style={{ height: 30, lineHeight: "30px" }}>
              {dayNumData?.text}
              <button className="btn-s btn-gray ml-2 px-2" onClick={() => { addTimeRowOfDayNum(dayNum); }}>+</button>
            </div>
          </td>
        );
      }
      return (
        <tr key={idx}>
          {first_td}
          <td>
            <input type="text" className="text-center" name="a_name" value={item.a_name}
              onChange={(e: any) => { onChangeTimeRowInput(item, e.target.name, e.target.value); }} style={{ width: 50 }}
              autoComplete="off" />
          </td>
          <td>
            <input type="text" className="text-center" name="a_start_h" value={strFunc.str_pad({ "str": item.a_start_h, "pad_length": 2 })}
              onChange={(e: any) => { onChangeTimeRowInput(item, e.target.name, e.target.value); }} style={{ width: 30 }} />:
            <input type="text" className="text-center" name="a_start_m" value={strFunc.str_pad({ "str": item.a_start_m, "pad_length": 2 })}
              onChange={(e: any) => { onChangeTimeRowInput(item, e.target.name, e.target.value); }} style={{ width: 30 }} />
          </td>
          <td>
            <input type="text" className="text-center" name="a_end_h" value={strFunc.str_pad({ "str": item.a_end_h, "pad_length": 2 })}
              onChange={(e: any) => { onChangeTimeRowInput(item, e.target.name, e.target.value); }} style={{ width: 30 }} />:
            <input type="text" className="text-center" name="a_end_m" value={strFunc.str_pad({ "str": item.a_end_m, "pad_length": 2 })}
              onChange={(e: any) => { onChangeTimeRowInput(item, e.target.name, e.target.value); }} style={{ width: 30 }} />
          </td>
          <td>
            <select className="row-input" name="a_teacher_seq" value={item.a_teacher_seq}
              onChange={(e: any) => { onChangeTimeRowInput(item, e.target.name, e.target.value); }} >
              <option value="">선택없음</option>
              {myProps.teacher_arr.map((teacher_info: any, teacher_idx: number) => {
                return (
                  <option value={teacher_info.a_seq} key={teacher_idx}>{teacher_info.a_user_name}</option>
                );
              })}
            </select>
          </td>
          <td>
            <select className="row-input" name="a_room_seq" value={item.a_room_seq}
              onChange={(e: any) => { onChangeTimeRowInput(item, e.target.name, e.target.value); }} >
              <option value="">선택없음</option>
              {
                myProps.room_info_arr.map((item: any, idx: number) => {
                  return (
                    <option key={idx} value={item.a_seq}>{item.a_name}</option>
                  );
                })
              }
            </select>
          </td>
          <td>
            <button className="btn-s btn-dark px-2" onClick={() => { removeTimeRowOfIdx(item.a_day_num, item.a_seq); }}>X</button>
          </td>
        </tr>
      );
    });

    if (strFunc.is_empty(timeRowTags)) {
      timeRowTags = (<tr>
        <td style={{ color: dayNumData?.color }}>
          {dayNumData?.text}
          <button className="btn-s btn-gray ml-2 px-2" onClick={() => { addTimeRowOfDayNum(dayNum); }}>+</button>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      );
    }

    return timeRowTags;
  };

  const getDayDataByDayNum = (dayNum: number) => {
    let dayNumLen = dayNumArr.length;
    let dayNumData = null;
    for (let i = 0; i < dayNumLen; i++) {
      if (dayNumArr[i].value == dayNum) {
        dayNumData = dayNumArr[i];
      }
    }
    return dayNumData;
  };

  const getTimeRowsByDayNum = (dayNum: number) => {
    let timeRows = [];
    let time_len = timeInfoArr.length;
    for (let i = 0; i < time_len; i++) {
      let timeRowData = timeInfoArr[i];
      if (timeRowData["a_day_num"] == dayNum) {
        timeRows.push(timeRowData);
      }
    }
    if (timeRows.length == 0) {

    }

    return timeRows;
  };

  const addTimeRowOfDayNum = (dayNum: number) => {
    let time_len = timeInfoArr.length;
    let pre_time_info = null;
    for (let i = 0; i < time_len; i++) {
      let timeRowData = timeInfoArr[i];
      if (timeRowData["a_day_num"] == dayNum) {
        pre_time_info = timeRowData;
      }
    }
    if (pre_time_info == null) {
      if (strFunc.is_empty(myProps.rowData.a_basic_row_cnt)) {
        myProps.rowData.a_basic_row_cnt = 1;
      }

      addTimeRowByBasicRowCnt(dayNum, myProps.rowData.a_basic_row_cnt);
    } else {
      addTimeRow(dayNum, 1);
    }
  };

  const addTimeRowByBasicRowCnt = (dayNum: number, cnt: number) => {
    addTimeRow(dayNum, cnt);
  };

  const addTimeRow = (dayNum: number, cnt: number) => {
    let tmpTimeInfoArr = [...timeInfoArr];
    for (let row_i = 0; row_i < cnt; row_i++) {
      let time_len = tmpTimeInfoArr.length;
      let time_row_num = 1;
      let pre_time_info = null;
      for (let i = 0; i < time_len; i++) {
        let timeRowData = tmpTimeInfoArr[i];
        if (timeRowData["a_day_num"] == dayNum) {
          pre_time_info = timeRowData;
          time_row_num++;
        }
      }
      if (strFunc.is_empty(myProps.rowData.a_basic_h)) {
        myProps.rowData.a_basic_h = "09";
      }
      if (strFunc.is_empty(myProps.rowData.a_basic_m)) {
        myProps.rowData.a_basic_m = "00";
      }
      if (strFunc.is_empty(myProps.rowData.a_basic_length_sec)) {
        myProps.rowData.a_basic_length_sec = "3600";
      }
      let start_init_sec = strFunc.timeToSeconds(myProps.rowData.a_basic_h, myProps.rowData.a_basic_m, "0");
      let end_init_sec = start_init_sec + parseInt(strFunc.uncomma(myProps.rowData.a_basic_length_sec));
      let end_init_hms_json = strFunc.secondsToTimeJson(end_init_sec);

      let addTimeRowData: any = {
        ...initLectureTimeRowData,
        ...{
          "a_name": time_row_num,
          "a_start_h": myProps.rowData.a_basic_h,
          "a_start_m": myProps.rowData.a_basic_m,
          "a_end_h": end_init_hms_json.h,
          "a_end_m": end_init_hms_json.i,
          "a_teacher_seq": myProps.rowData.a_main_teacher_seq,
          "a_teacher_name": myProps.rowData.a_main_teacher,
          "a_room_seq": myProps.rowData.a_room_seq,
        },
        ...{
          a_day_num: dayNum,
          a_seq: time_row_num,
        }
      };

      if (pre_time_info != null) {
        //이전타임 + 기준시간
        let tmp_start_h = parseInt(pre_time_info.a_end_h);
        let tmp_start_m = parseInt(pre_time_info.a_end_m);
        let tmp_start_sec = strFunc.timeToSeconds(tmp_start_h, tmp_start_m, "0");
        let tmp_end_sec = tmp_start_sec + parseInt(strFunc.uncomma(myProps.rowData.a_basic_length_sec));
        let tmp_end_hms_json = strFunc.secondsToTimeJson(tmp_end_sec);

        addTimeRowData["a_start_h"] = strFunc.str_pad({ "str": tmp_start_h, "pad_length": 2 });
        addTimeRowData["a_start_m"] = strFunc.str_pad({ "str": tmp_start_m, "pad_length": 2 });
        addTimeRowData["a_end_h"] = tmp_end_hms_json.h;
        addTimeRowData["a_end_m"] = tmp_end_hms_json.i;
      }

      if (parseInt(addTimeRowData["a_end_h"]) >= 24) {
        addTimeRowData["a_end_h"] = "24";
        addTimeRowData["a_end_m"] = "00";
      }
      if (addTimeRowData["a_start_h"] >= "24") {
        continue;
      }

      tmpTimeInfoArr.push(addTimeRowData);
    }
    tmpTimeInfoArr=get_copy_of_same_days_week_data(tmpTimeInfoArr);
    setTimeInfoArr([...tmpTimeInfoArr]);
  };

  const removeTimeRowOfIdx = (dayNum: number, idx: number) => {
    let time_len = timeInfoArr.length;
    let tmpTimeInfoArr = [];
    for (let i = 0; i < time_len; i++) {
      let timeRowData = timeInfoArr[i];
      if (timeRowData["a_day_num"] == dayNum && timeRowData["a_seq"] == idx) {

      } else {
        tmpTimeInfoArr.push(timeRowData);
      }
    }
    tmpTimeInfoArr=get_copy_of_same_days_week_data(tmpTimeInfoArr);
    setTimeInfoArr(tmpTimeInfoArr);
  };

  const get_copy_of_same_days_week_data=(inTimeInfoArr:any[])=>{
    let newTimeInfoArr:any=[];
    if(is_same_days_week==false){
      return inTimeInfoArr;
    }
    if(same_days_week_arr.length==0){
      return inTimeInfoArr;
    }
    
    //첫번째 데이터 리스트 얻기
    let first_day_num=same_days_week_arr[0];
    let first_time_info_arr:any=[];
    for(let i=0;i<inTimeInfoArr.length;i++){
      let tmp_time_info=inTimeInfoArr[i];
      if(tmp_time_info["a_day_num"]==first_day_num){
        first_time_info_arr.push(tmp_time_info);
      }
    }
    //기존데이터 없애기
    newTimeInfoArr=[];
    for(let i=0;i<inTimeInfoArr.length;i++){
      let tmp_time_info=inTimeInfoArr[i];
      if(strFunc.str_in_array(tmp_time_info["a_day_num"],same_days_week_arr)==-1){
        newTimeInfoArr.push(tmp_time_info);
      }
    }
    if(first_time_info_arr.length==0){
      return newTimeInfoArr;
    }
    //첫번째 데이터 리스트 추가
    for(let day_i in same_days_week_arr){
      let day_i_str=same_days_week_arr[day_i];
      for(let i=0;i<first_time_info_arr.length;i++){
        let first_time_info=first_time_info_arr[i];
        let add_time_info_row={
          ...first_time_info,
          a_day_num:day_i_str,
        };
        newTimeInfoArr.push(add_time_info_row);
      }
    }
    return newTimeInfoArr;
  };

  return (
    <div>
      <TopManageBarArea
        teacher_arr={myProps.teacher_arr}
        room_info_arr={myProps.room_info_arr}
        timeInfoArr={timeInfoArr}
        setTimeInfoArr={(inData: any) => {
          setTimeInfoArr(inData);
          set_refresh(!refresh);
        }}
      ></TopManageBarArea>
      <div className="list-table-div gray_table mt-1" >
        <table style={{ width: "auto" }} >
          <colgroup>
            <col width="100px"></col>
            <col width="60px"></col>
            <col width="100px"></col>
            <col width="100px"></col>
            <col width="90px"></col>
            <col width="90px"></col>
            <col width="70px"></col>
          </colgroup>
          <thead>
            <tr>
              <th>요일</th>
              <th>교시</th>
              <th>시작</th>
              <th>종료</th>
              <th>담당선생님</th>
              <th>강의실</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>
            {dayNumArr.map((item: any, idx: number) => {
              return (
                <React.Fragment key={idx} >
                  {getTimeRowTagsByDayNum(item.value)}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default LectureTimeComponent;