import WritePage from "@/pages/comp/basic/student/list/area/write";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

function ViewStuLayerPopup(props: any) {
  let myProps = {
    write_page_data: {},
    callback: (inData: any) => { },
    closePopup: () => { },
    ...props
  };
  let default_write_page_data = {
    is_show: false,
    is_update: false,
    is_view_mode: true,
    is_view_basic: false,
    info_arr: [],
    select_idx: 0,
    default_write_info: {},
    tab_id: "monthly_prescribe",
    is_first_tab_scroll: false,
    counsel_s_select_date: "",
  };
  let write_page_data = {
    ...default_write_page_data,
    ...myProps.write_page_data
  };

  let select_stu_grade_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });
  let stu_name = "";
  let stu_school_name = "";
  let stu_grade_str = "";
  let select_stu_info: any = {};
  if (write_page_data.info_arr && write_page_data.info_arr[write_page_data.select_idx]) {
    select_stu_info = write_page_data.info_arr[write_page_data.select_idx];
    stu_name = select_stu_info["a_user_name"];
    stu_school_name = select_stu_info["a_school_name"];
    stu_grade_str = select_stu_info["a_stu_grade"];
    if (select_stu_grade_option_arr) {
      for (let i = 0; i < select_stu_grade_option_arr.length; i++) {
        let row_option = select_stu_grade_option_arr[i];
        if (row_option["value"] == stu_grade_str) {
          stu_grade_str = row_option["text"];
        }
      }
    }
  }
  return (
    <div style={{ paddingTop: 27 }}>
      <div style={{ position: "fixed", width: "100%", top: 0, left: 0, zIndex: 99 }}>
        <div style={{
          position: "relative", width: "100%",
          textAlign: "center", background: "#3b3b3b", borderBottom: "1px solid #ddd", height: 37,
          lineHeight: "35px", color: "#fff"
        }}>
          {stu_name}
          {stu_school_name &&
            <span className="ml-1">
              ({stu_school_name} {stu_grade_str})
            </span>
          }
          <button className="btn btn-line-gray" onClick={() => { myProps.closePopup(); }}
            style={{ position: "absolute", right: 20, top: 5, color: "red",border:"2px solid #fff" }}>X</button>
        </div>
      </div>
      <WritePage
        info_arr={write_page_data.info_arr}
        select_idx={write_page_data.select_idx}
        close_write={() => { myProps.closePopup(); }}
        close_btn_name={"닫기"}
        is_view_close_btn={false}
        list={() => { myProps.callback(); }}
        refresh_data={() => {
          if (opener && opener.refresh_list) {
            myProps.callback();
          }
        }}
        is_update={write_page_data.is_update}
        is_view_mode={write_page_data.is_view_mode}
        is_view_basic={write_page_data.is_view_basic}
        default_write_info={write_page_data.default_write_info}
        tab_id={write_page_data.tab_id}
        is_first_tab_scroll={write_page_data.is_first_tab_scroll}
        counsel_s_select_date={write_page_data.counsel_s_select_date}
      ></WritePage>
    </div>
  );
};
export default ViewStuLayerPopup;