import { useSelector, useDispatch } from 'react-redux';
import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";
import strFunc from '@/lib/lyg/string';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState } from 'react';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FindMultyStudentPopup from "@/pages/comp/basic/student/popup/find_multy";
import FindPrescribePopup from "@/pages/comp/edu/prescribe/prescribe_check/popup/find";
import CommentPartArea from "@/pcomponents/common/content/comment/part_area";

function MonthPrescribeWriteArea(props: any) {
  let myProps = {
    rowData: {},
    setRowData: (inData: any) => { },
    isUpdate: false,
    list: (inData: any) => { },
    is_view_mode: false,
    comment_list: [],
    select_stu_info_arr: [],
    set_select_stu_info_arr: (inData: any) => { },
    refresh_comment: (inData: any) => { },
    set_refresh: (inData: any) => { },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  let rowData = myProps.rowData;
  let isUpdate = myProps.isUpdate;
  let is_view_mode = myProps.is_view_mode;
  let select_subject_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'study_title', 'key': 'a_subject_sort' });
  let row_pri_val = rowData["a_ymd"] + "," + rowData["a_seq"];
  let comment_init_data = {
    writer_seq: rowData.a_writer_seq,
    writer_name: rowData.a_writer_name,
    par_id: "month_prescribe",
    par_seq: row_pri_val,
    par_locate: "",
    par_locate_name: "",
    s_start_date: DateFunc.get_date_format(new Date(rowData["a_reserve_date"]), "Y-01-01"),
    s_end_date: DateFunc.get_date_format(new Date(), "Y-12-31"),
  };
  if (isUpdate) {
    comment_init_data["s_start_date"] = rowData["a_reserve_date"];
  }

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "find",//view
    isUpdate: false,
    rowData: {},
    has_header: true,
    title: "팝업",
    width: "800px",
    height: "80%",
  });

  const on_change_input = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let change_row_data = { ...rowData };
    if (name == "a_content" && !strFunc.is_empty(value) && change_row_data["a_is_success"] != "1") {
      change_row_data["a_is_success"] = "1";
      change_row_data["a_success_date"] = DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
      change_row_data["a_success_writer_seq"] = user.user_seq;
      change_row_data["a_success_writer_name"] = user.user_name;
    }
    if (name == "a_is_success") {
      if (e.target.checked) {
        value = "1";
      } else {
        value = "";
      }
      if (value == "1") {
        if (change_row_data[name] != "1") {
          change_row_data["a_success_date"] = DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
          change_row_data["a_success_writer_seq"] = user.user_seq;
          change_row_data["a_success_writer_name"] = user.user_name;
        }
      }
    } else if (name == "a_subject_name") {
      update_subject_name_of_writer_by_ajax({
        subject_name: value
      });
    }
    change_row_data[name] = value;
    myProps.setRowData(change_row_data);
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    myProps.setRowData({
      ...rowData,
      ...{ [key]: date_str }
    });
  };

  const open_find_stu_popup = () => {
    let pop_title = "학생찾기";
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": pop_title,
      "sort": "find",
      width: "800px",
      height: "80%",
    });
  };
  const callBackDataStuPopup = (inData: any) => {
    let optObj = {
      info_arr: [],
      row_num: 0,
      key: "",
      ...inData,
    };
    if (optObj["info_arr"].length > 0) {
      myProps.set_select_stu_info_arr([
        ...myProps.select_stu_info_arr,
        ...optObj["info_arr"]
      ]);
      let select_stu_info = optObj["info_arr"][0];
      let chnage_row_data = {
        ...rowData,
        a_stu_seq: select_stu_info["a_seq"],
        a_stu_name: select_stu_info["a_user_name"],
      };
      if (select_stu_info.last_mprescribe_arr && select_stu_info.last_mprescribe_arr.length > 0) {
        for (let i = 0; i < select_stu_info.last_mprescribe_arr.length; i++) {
          let last_mprescribe_info = select_stu_info.last_mprescribe_arr[i];
          if (last_mprescribe_info["a_subject_name"] == chnage_row_data["a_subject_name"]
            && last_mprescribe_info["a_writer_seq"] == chnage_row_data["a_writer_seq"]) {
            chnage_row_data["a_progress_reason"] = last_mprescribe_info["a_progress_reason"];
            chnage_row_data["a_progress"] = last_mprescribe_info["a_progress"];
          }
        }
      }
      if (select_stu_info.last_daily_report_arr && select_stu_info.last_daily_report_arr.length > 0) {
        for (let i = 0; i < select_stu_info.last_daily_report_arr.length; i++) {
          let last_report_info = select_stu_info.last_daily_report_arr[i];
          if (last_report_info["a_subject"] == chnage_row_data["a_subject_name"]) {
            chnage_row_data["a_progress"] = last_report_info["a_textbook"]+" "+last_report_info["a_textbook_study"];
          }
        }
      }
      myProps.setRowData(chnage_row_data);
    }
  };

  const openFindPrescribePopup = (inData: any) => {
    let opt_obj = {
      sort: "find_pres",
      row_num: 0,
      ...inData,
    };
    let pop_title = "찾기";
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": pop_title,
      "sort": opt_obj.sort,
      width: "800px",
      height: "80%",
    });
  };

  const callBackDataFindPrescribe = (inData: any) => {
    let opt_obj = {
      cate_info: null,
      info_arr: [],
      select_check_idx: 0,
      select_cate_idx: 0,
      row_num: 0,
      key_str: "",
      pri_val: "",
      ...inData,
    };
    if (opt_obj["cate_info"] == null) {
      return false;
    }
    let week_info: any = rowData;
    if (week_info == null) {
      return false;
    }
    let check_info_arr = opt_obj["info_arr"];
    if (check_info_arr.length == 0) {
      return false;
    }
    let check_info = check_info_arr[0];
    let value_str = (opt_obj["select_cate_idx"] + 1) + ") " + opt_obj["cate_info"]["a_title"];
    value_str += "-" + check_info["a_title"];//(opt_obj["select_check_idx"]+1)
    let change_row_data = { ...rowData };
    change_row_data["a_grow"] = value_str;
    myProps.setRowData(change_row_data);
  };

  const update_subject_name_of_writer_by_ajax = (inData: any) => {
    let opt_obj = {
      subject_name: "",
      ...inData
    };
    if (strFunc.is_empty(opt_obj["subject_name"])) {
      return false;
    }
    if (strFunc.is_empty(user.user_seq)) {
      return false;
    }
    let up_row_data = {
      a_seq: user.user_seq,
      a_main_subject: opt_obj["subject_name"],
    };
    let formJsonData = {
      "data_arr": [up_row_data],
      "is_update": "1"
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/write', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {

        } else {

        }
      });
  };

  return (
    <div className="write_table_small">
      <table>
        <colgroup>
          <col width={"*"}></col>
        </colgroup>
        <tbody>
          <tr>
            <td>
              학생:
              <span style={{ color: "blue" }}>
                {rowData.a_stu_name}
              </span>
              <button className="btn-s btn-sky ml-1" onClick={open_find_stu_popup}>찾기</button>
              <span className="ml-1">
                과목:
                <select className="row-input" name="a_subject_name"
                  value={rowData.a_subject_name}
                  onChange={on_change_input}
                  style={{ width: 60 }} >
                  <option value="">과목</option>
                  {select_subject_option_arr.map((item: any, idx: number) => {
                    return (
                      <option key={idx} value={item["value"]}>{item["text"]}</option>
                    );
                  })}
                </select>
              </span>
              <span className="ml-1">
                날짜:
                <div style={{ display: "inline-block", width: 100 }}>
                  <DatePicker
                    selected={rowData.a_reserve_date != "" ? new Date(rowData.a_reserve_date) : null}
                    onChange={(date: Date) => {
                      onChangeDatePicker("a_reserve_date", date);
                    }}
                    locale={ko}
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomInputWrite />}
                    renderCustomHeader={customHeaderFunc}
                  />
                </div>
              </span>
              <span className="ml-1">
                작성자:
                {rowData.a_writer_name}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <div className="text-left pl-2" style={{ fontWeight: "bold" }}>1. 진도전략</div>
              <div className="text-left mt-1" style={{ minHeight: 20 }}>
                {is_view_mode ?
                  <MultyLineTextView text={rowData.a_progress_reason}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_progress_reason"}
                    value={rowData.a_progress_reason}
                    class_name={"row-input"}
                    height={32}
                    style={{ "width": "100%" }}
                    onChange={on_change_input}
                    placeholder={"내용입력"}
                  ></TextAreaComponent>
                }
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div className="text-left pl-2" style={{ fontWeight: "bold" }}>2. 현재 진도</div>
                <div className="text-left mt-1" style={{ minHeight: 20 }}>
                  {is_view_mode ?
                    <MultyLineTextView text={rowData.a_progress}></MultyLineTextView>
                    :
                    <TextAreaComponent
                      name={"a_progress"}
                      value={rowData.a_progress}
                      class_name={"row-input"}
                      height={32}
                      style={{ "width": "100%" }}
                      onChange={on_change_input}
                      placeholder={"내용입력"}
                    ></TextAreaComponent>
                  }
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div className="text-left pl-2" style={{ fontWeight: "bold" }}>
                  3. 성장한 부분
                  {is_view_mode == false &&
                    <button className="btn-s btn-dark ml-1" onClick={() => {
                      openFindPrescribePopup({});
                    }}>찾기</button>
                  }
                </div>
                <div className="text-left mt-1" style={{ minHeight: 20 }}>
                  {is_view_mode ?
                    <MultyLineTextView text={rowData.a_grow}></MultyLineTextView>
                    :
                    <TextAreaComponent
                      name={"a_grow"}
                      value={rowData.a_grow}
                      class_name={"row-input"}
                      height={32}
                      style={{ "width": "100%" }}
                      onChange={on_change_input}
                      placeholder={"내용입력"}
                    ></TextAreaComponent>
                  }
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div className="text-left pl-2" style={{ fontWeight: "bold" }}>4. 발견</div>
                <div className="text-left mt-1" style={{ minHeight: 20 }}>
                  {is_view_mode ?
                    <MultyLineTextView text={rowData.a_lack}></MultyLineTextView>
                    :
                    <TextAreaComponent
                      name={"a_lack"}
                      value={rowData.a_lack}
                      class_name={"row-input"}
                      height={32}
                      style={{ "width": "100%" }}
                      onChange={on_change_input}
                      placeholder={"내용입력"}
                    ></TextAreaComponent>
                  }
                </div>
              </div>
              {isUpdate &&
                <CommentPartArea
                  info_arr={myProps.comment_list}
                  list={(inData:any)=>{
                    myProps.refresh_comment();
                    myProps.list({select_seq:row_pri_val});
                  }}
                  is_show_toggle={false}
                  is_show_detail={true}
                  is_send_write_push={true}
                  title_str={"질문"}
                  wrap_style={{ width: "100%" }}
                  init_data={{
                    ...comment_init_data,
                    par_locate: "a_lack",
                    par_locate_name: "질문",
                  }}
                ></CommentPartArea>
              }
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div className="text-left pl-2" style={{ color: "#fff", background: "#2d8372", position: "relative" }}>
                  5. 학습 처방
                  <span style={{ position: "absolute", right: 5 }} title={rowData.a_is_success == "1" ? rowData.a_success_date : ""} >
                    <label>
                      <input type="checkbox" name="a_is_success" value="1"
                        className="ml-2 mr-1"
                        checked={rowData.a_is_success == "1"}
                        onChange={on_change_input}
                      />
                      완료
                    </label>
                  </span>
                </div>
                <div className="text-left mt-1" style={{ minHeight: 20 }}>
                  {is_view_mode ?
                    <MultyLineTextView
                      text={rowData.a_content}
                      style={{ color: "blue" }}></MultyLineTextView>
                    :
                    <TextAreaComponent
                      name={"a_content"}
                      value={rowData.a_content}
                      class_name={"row-input"}
                      height={32}
                      style={{ color: "blue" }}
                      onChange={on_change_input}
                      placeholder={"내용입력"}
                    ></TextAreaComponent>
                  }
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} has_header={popupData.has_header} >
          {popupData.sort === "find" &&
            <FindMultyStudentPopup
              callBackData={callBackDataStuPopup}
              listOpt={{
                s_addon_daily_report_last: "1",
                s_addon_mprescribe_last: "1"
              }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></FindMultyStudentPopup>
          }
          {popupData.sort == "find_pres" &&
            <FindPrescribePopup
              callBackData={callBackDataFindPrescribe}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></FindPrescribePopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default MonthPrescribeWriteArea;