export const default_info={
  "a_ymd":"",
  "a_seq":"",
  "a_reserve_date":"",
  "a_is_write":"",
  "a_write_date":"",
  "a_stu_seq":"",
  "a_stu_name":"",
  "a_writer_seq":"",
  "a_writer_name":"",
  "a_subject_name":"",
  "a_title":"",
  "a_progress_reason":"",
  "a_progress":"",
  "a_grow":"",
  "a_lack":"",
  "a_content":"",
  "a_is_success":"",
  "a_success_date":"",
  "a_success_writer_seq":"",
  "a_success_writer_name":"",
};