import strFunc from "@/lib/lyg/string";
import Style from "../../../../css/style.module.css";
import DeskBoxArea from "./desk_box";
import DateFunc from '@/lib/lyg/date_func';
import popupFunc from "@/lib/lyg/popup";

const RoomBoxArea=(props:any)=>{
  let myProps:any={
    room_info:{},
    now_ymdhis:"",
    open_find_stu_popup:(inData:any)=>{},
    open_view_desk_popup:(inData:any)=>{},
    is_detal_popup:true,
    ...props
  };
  let room_info=myProps.room_info;
  let lecture_data_arr:any=[];
  let now_lecture_data:any=null;

  const open_room_detail_popup=()=>{
    var win_w=(window.innerWidth || document.documentElement.clientWidth)/3*2;
    var win_h=(window.innerHeight || document.documentElement.clientHeight)/3*2;
    popupFunc.openPopup({
      'url':'/comp/edu/desk/popup/view_room_popup?room_seq='+myProps.room_info.a_seq,
      'width':win_w,
      'height':win_h,
      'pop_name':'room_detail_popup',
      'auto_size':true
    });
  };

  const get_lecture_time_list_tags=()=>{
    let lecture_tags:any="";//14:50~16:00 (90분)
    let now_date_json=DateFunc.get_date_json(new Date(myProps.now_ymdhis));
    let now_sec=strFunc.timeToSeconds(now_date_json["h"],now_date_json["i"],now_date_json["s"]);
    //지금가능한 강의 찾기
    if(room_info.lecture_arr){
      for(let lec_i=0;lec_i<room_info.lecture_arr.length;lec_i++){
        let lecture_info=room_info.lecture_arr[lec_i];
        if(lecture_info.stu_union_day_time_stat){
          for(let i=0;i<lecture_info.stu_union_day_time_stat.length;i++){
            let time_stat=lecture_info.stu_union_day_time_stat[i];
            if(now_date_json["day"]==time_stat["a_day_num"]){
              let st_sec=strFunc.timeToSeconds(time_stat["a_start_h"],time_stat["a_start_m"],"0");
              let end_sec=strFunc.timeToSeconds(time_stat["a_end_h"],time_stat["a_end_m"],"0");
              let diff_sec=end_sec-st_sec;
              let minute_num=0;
              let is_now=false;
              if(diff_sec!=0){
                minute_num=Math.round(diff_sec/60);
              }
              if(st_sec<=now_sec&&now_sec<=end_sec){
                is_now=true;
              }
              lecture_data_arr.push({
                "title":lecture_info["a_name"],
                "start_h":time_stat["a_start_h"],
                "start_m":time_stat["a_start_m"],
                "end_h":time_stat["a_end_h"],
                "end_m":time_stat["a_end_m"],
                "start_hi":strFunc.HourMinuteToTime(time_stat["a_start_h"],time_stat["a_start_m"]),
                "end_hi":strFunc.HourMinuteToTime(time_stat["a_end_h"],time_stat["a_end_m"]),
                "minute_num":minute_num,
                "is_now":is_now,
              });
              if(is_now){
                now_lecture_data=lecture_data_arr[lecture_data_arr.length-1];
              }
            }
          }
        }
      }
    }
    lecture_tags=lecture_data_arr.map((item:any,idx:number)=>{
      let lecture_title_class:any={};
      if(item.is_now){
        lecture_title_class["color"]="#00f";
      }
      return (
        <div key={idx}>
          <span className={lecture_title_class}>{item.title}</span>
          <span className="mx-2">
            {item.start_hi}~{item.end_hi} 
          </span>
          ({item.minute_num}분)
        </div>
      );
    });
    if(lecture_tags==""){
      lecture_tags="수업이 없습니다.";
    }

    return lecture_tags;
  };
  let lecture_tags=get_lecture_time_list_tags();

  //x
  let desk_row_arr=[];
  let desk_row_len=parseInt(room_info["a_size_y"]);
  for(let i=0;i<desk_row_len;i++){
    desk_row_arr.push(i);
  }
  //y
  let desk_col_arr:any=[];
  let desk_col_len=parseInt(room_info["a_size_x"]);
  for(let i=0;i<desk_col_len;i++){
    desk_col_arr.push(i);
  }
  let desk_arr:any=[];
  if(room_info.desk_arr){
    desk_arr=room_info.desk_arr;
  }
  let desku_arr:any=[];
  if(room_info.desku_arr){
    desku_arr=room_info.desku_arr;
  }
  const get_desk_info_by_xy=(x:number,y:number)=>{
    let desk_info=null;
    let desk_arr_len=desk_arr.length;
    for(let i=0;i<desk_arr_len;i++){
      if(parseInt(desk_arr[i]["a_x"])==x&&parseInt(desk_arr[i]["a_y"])==y){
        desk_info=desk_arr[i];
      }
    }
    return desk_info;
  };
  const get_desku_info_by_xy=(x:number,y:number)=>{
    let desku_info=null;
    let now_date_json=DateFunc.get_date_json(new Date(myProps.now_ymdhis));
    let now_sec=strFunc.timeToSeconds(now_date_json.h,now_date_json.i,now_date_json.s);
    let desku_arr_len=desku_arr.length;
    for(let i=0;i<desku_arr_len;i++){
      if(parseInt(desku_arr[i]["a_x"])==x&&parseInt(desku_arr[i]["a_y"])==y){
        let start_sec=strFunc.timeToSeconds(desku_arr[i]["a_start_h"],desku_arr[i]["a_start_m"],0);
        let end_sec=strFunc.timeToSeconds(desku_arr[i]["a_end_h"],desku_arr[i]["a_end_m"],0);
        if(start_sec<=now_sec&&now_sec<=end_sec){
          desku_info=desku_arr[i];
        }
      }
    }
    return desku_info;
  };
  
  let desk_row_tags=desk_row_arr.map((row_num:number,row_idx:number)=>{
    let desk_col_tags=desk_col_arr.map((col_num:number,col_idx:number)=>{
      let desk_info=get_desk_info_by_xy(col_num,row_num);
      let desku_info=get_desku_info_by_xy(col_num,row_num);
      return (
        <DeskBoxArea 
          key={col_idx}
          x={col_num}
          y={row_num}
          room_info={room_info}
          desk_info={desk_info}
          desku_info={desku_info}
          now_ymdhis={myProps.now_ymdhis}
          now_lecture_data={now_lecture_data}
          open_find_stu_popup={myProps.open_find_stu_popup}
          open_view_desk_popup={myProps.open_view_desk_popup}
        ></DeskBoxArea>
      );
    });
    return (
      <div className={Style.desk_box_row} key={row_idx}>
        {desk_col_tags}
      </div>
    );
  });

  return (
    <div className={Style.room_box} >
      <div className={Style.room_box_title_bar}>
        <div className={Style.room_box_title} >
          {room_info.a_name}
        </div>
        <div className={Style.room_box_sub_title} >
          {lecture_tags}
        </div>
      </div>
      <div className={Style.room_box_desk_wrap} >
        {desk_row_tags}
      </div>
      {myProps.is_detal_popup&&
        <div className={Style.room_box_bottom_bar}>
          <button className="btn-m btn-dark" onClick={()=>{open_room_detail_popup();}}>상세보기</button>
        </div>
      }
    </div>
  );
};
export default RoomBoxArea;