import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import WritePopupContent from '../popup/write/index';
import ViewPopupContent from '../popup/view/index';
import MoveTodoDatePopup from "../popup/move_date";
import TodoMultyWritePopup from "@/pages/comp/plan/todo/popup/multy_write";
import WriteOptPopupContent from "@/pcomponents/common/xcolumn/popup/write_opt";
import FindStudentList from "@/pages/comp/basic/student/frame/left_find_list";
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import TodoStateFunc from "@/pcomponents/common/content/todo/state/func/state_func";
import SearchArea from "./area/search";

function ListPage(){
  let user=useSelector((state:any) => state.user);
  const findStudentListRef=useRef<any>(null);
  const xColumnArr=getXcolumnJson();
  const listAggridRef= useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      "s_start_date":DateFunc.get_date_format(new Date(),"Y-m-d"),
      "s_end_date":DateFunc.get_date_format(new Date(),"Y-m-d"),
      "s_addon_user":"1",
      "s_is_mcomp_public":"1",
      "s_stu_mcomp_seq":user.comp_seq,
      "s_stu_user_seq":[],
      "s_search_text_type":"name",
      "s_search_text":"",
      "s_is_success":"",
      "s_state":"",
      "s_todo_sort":"",
      "s_addon_par_content":"1",
      "s_stu_bookmark": strFunc.get_storage("s_stu_bookmark"),
    }
  });
  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({"tot":0});

  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"write",//view
    isUpdate:false,
    rowData:{},
    info_arr:[],
    stu_seq:"",
    stu_name:"",
    title:"팝업",
    width:"800px",
    height:"80%",
  });

  useEffect(() => {
    
  }, []);

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      inOptObj["sc"]=listAggridRef.current.getListSc();
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    let list_form_json=pageData.listOpt;
    if(strFunc.is_empty(user.comp_seq)){
      return false;
    }
    list_form_json.s_stu_mcomp_seq=user.comp_seq;

    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/list',list_form_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const goDelete = useCallback( (e:any) => {
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length===0){
      alert("선택없음.");
      return false;
    }
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }
    let form_data={
      "data_arr":selected_row
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/delete',form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert(response.data["msg"]);
        list({});
      }else{
        alert(response.data["msg"]);
      }
    });
  }, []);

  const openOnePopup=(sort:string,isUpdate:boolean)=>{
    let pop_title="등록";
    let tmp_rowData={};
    if(isUpdate){
      pop_title="수정";
      let selected_row=listAggridRef.current.getSelectedRows();
      if(selected_row.length==0){
        alert("선택이 없습니다.");
        return false;
      }else if(selected_row.length>=2){
        // setPopupData({
        //   ...popupData,
        //   "isOpen":true,
        //   "isUpdate":isUpdate,
        //   "info_arr":selected_row,
        //   "title":"수정",
        //   "sort":"multy_write"
        // });
        // return false;
      }
      if(selected_row.length>0){
        tmp_rowData={...selected_row[0]};
      }
    }
    if(sort=="view"){
      pop_title="보기";
    }
    if(sort=="write"){
      if(isUpdate==false){
        if(pageData.listOpt.s_stu_user_seq.length==0){
          alert("원생 선택이 필요합니다.");
          return false;
        }
      }
    }
    setPopupData({
      ...popupData,
      "isOpen":true,
      "isUpdate":isUpdate,
      "rowData":tmp_rowData,
      stu_seq:pageData.listOpt.s_stu_user_seq[0],
      "title":pop_title,
      "sort":sort
    });
  };

  const openMoveDatePopup=()=>{
    let pop_title="일정 미루기";
    let selected_row=listAggridRef.current.getSelectedRows();

    if(selected_row.length==0){
      alert("선택이 없습니다.");
      return false;
    }

    setPopupData({
      ...popupData,
      "isOpen":true,
      "title":pop_title,
      "info_arr":selected_row,
      "sort":"move_date",
    });
  };

  const openListOptPopup=()=>{
    let pop_title="리스트설정";
    setPopupData({
      ...popupData,
      "isOpen":true,
      "title":pop_title,
      "sort":"list_opt"
    });
  };

  const go_change_select_key_val=(inData:any)=>{
    let opt_obj={
      key:"",
      value:"",
      ...inData
    };
    let key=opt_obj["key"];
    let value=opt_obj["value"];
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length==0){
      alert("선택이 없습니다.");
      return false;
    }
    let up_data_arr=[];
    for(let i=0;i<selected_row.length;i++){
      let row_info=selected_row[i];
      selected_row[i][key]=value;
      let up_data_row:any={
        "a_ymd":row_info["a_ymd"],
        "a_seq":row_info["a_seq"],
      };
      up_data_row[key]=value;

      up_data_arr.push(up_data_row);
    }
    if(!confirm(up_data_arr.length+"개의 플랜을 수정 하시겠습니까?")){
      return false;
    }

    let write_form_json={
      "is_update":"1",
      "data_arr":up_data_arr,
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/write',write_form_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert(response.data["msg"]);
        list({});
      }else{
        
      }
    });
  };

  const cellClickedListener = useCallback( (params:any) => {
    var key=params.colDef.field;
    if(key=="a_stu_name"){
      openOnePopup("view",true);
    }
  }, []);

  const cellRenderer= useCallback( (params:any) => {
    var key=params.colDef.field;
    var render_str=params.value;
    if(key=="row_view_success_sort"){
      let sort_str=params.data.a_success_sort;
      if(xColumnArr.select_arr["a_success_sort"]){
        for(let i=0;i<xColumnArr.select_arr["a_success_sort"].length;i++){
          let tmp_option_row=xColumnArr.select_arr["a_success_sort"][i];
          if(tmp_option_row["value"]==sort_str){
            sort_str=tmp_option_row["text"];
          }
        }
      }
      render_str=(
        <div>
          {sort_str}
        </div>
      );
    }else if(key=="row_view_state"){
      let state_str=params.data.a_state;
      if(xColumnArr.select_arr["a_state"]){
        for(let i=0;i<xColumnArr.select_arr["a_state"].length;i++){
          let tmp_option_row=xColumnArr.select_arr["a_state"][i];
          if(tmp_option_row["value"]==state_str){
            state_str=tmp_option_row["text"];
          }
        }
      }
      render_str=(
        <div>
          {state_str}
        </div>
      );
    }else if(key=="row_view_per"){
      let per_str=TodoStateFunc.get_percent_by_todo_info(params.data);
      if(params.data.a_is_success=="1"){
        per_str=100;
      }
      let per_color_json=TodoStateFunc.get_color_of_percent(per_str);
      let per_div_style:any={};
      per_div_style["color"]=per_color_json.per;
      render_str=(
        <div style={per_div_style}>
          {per_str} %
        </div>
      );
    }else if(key=="row_view_success_amount"){
      if(params.data.a_success_sort=="amount"){
        render_str=params.data.a_amount_num+"/"+params.data.a_success_amount;
      }
    }else if(key=="row_view_time_str"){
      let tmp_time_json:any=strFunc.secondsToTimeJson(params.data.a_time_sec);
      render_str=tmp_time_json.i+":"+tmp_time_json.s;
      if(tmp_time_json.h!="00"){
        render_str=tmp_time_json.h+":"+render_str;
      }
    }else if(key=="row_view_reserve_date"){
      render_str=params.data["a_reserve_start_date"].substring(5,10);
    }
    return render_str;
  }, []);

  return (
    <CompLayout>
      <div className="con_wrap">
        <div className="flex flex-row gap-x-2">
          <div style={{minWidth:220}}>
            <FindStudentList
              ref={findStudentListRef}
              list={(inOptObj:any)=>{list(inOptObj);}}
            ></FindStudentList>
          </div>
          <div className="grow" style={{minWidth:400}}>
            <SearchArea
              xColumnArr={xColumnArr}
              listOpt={pageData.listOpt}
              list={list}
              stu_list={(inData:any)=>{
                if(findStudentListRef.current){
                  findStudentListRef.current.list(inData);
                }
              }}
              openOnePopup={openOnePopup}
              openMoveDatePopup={openMoveDatePopup}
              openListOptPopup={openListOptPopup}
              goDelete={goDelete}
              unselect_stu={()=>{
                if(findStudentListRef.current){
                  findStudentListRef.current.setSelectFalseAll();
                }
              }}
              go_change_select_key_val={go_change_select_key_val}
            ></SearchArea>
            <ListAggrid
            ref={listAggridRef}
            infoArr={infoArr}
            xColumnArr={{...xColumnArr}}
            list={list}
            gridOpt={{
              fix_left_num:xColumnArr.list_opt_arr.fix_left_num,
              is_idx_num:false,
              is_add_checkbox:true,
              floatingFilter:true,
              onGridReady:()=>{
                list({});
              },
              onCellClicked:cellClickedListener,
              cellRenderer:cellRenderer
            }}
            ></ListAggrid>
            <Paging now_page={pageData.listOpt.now_page}
              num_per_page={pageData.listOpt.num_per_page}
              total_rec={countInfo.tot} 
              onChangePage={(now_page:number)=>{list({now_page:now_page});}}
              onChangeNumPerPage={(num_per_page:number)=>{list({num_per_page:num_per_page,now_page:1});}}></Paging>
            </div>
          </div>
      </div>
      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} >
          {popupData.sort==="write"&&
            <WritePopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={()=>{list({});}} 
             stu_seq={popupData.stu_seq}
             stu_name={popupData.stu_name}
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></WritePopupContent>
          }
          {popupData.sort==="view"&&
            <ViewPopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={()=>{list({});}} 
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}
             openOnePopup={openOnePopup}></ViewPopupContent>
          }
          {popupData.sort=="move_date"&&
            <MoveTodoDatePopup
              info_arr={popupData.info_arr}
              callback={()=>{list({});}}
              closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}
            ></MoveTodoDatePopup>
          }
          {popupData.sort=="multy_write"&&
            <TodoMultyWritePopup
              info_arr={popupData.info_arr}
              callback={()=>{list({});}}
              closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}
            ></TodoMultyWritePopup>
          }
          {
            popupData.sort=="list_opt"&&
            <WriteOptPopupContent
              xColumnArr={xColumnArr}
              callback={()=>{list({});}}
              closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}
            ></WriteOptPopupContent>
          }
      </LayerPopup>
      }
    </CompLayout>
  );
}

export default ListPage;