function TopManageBarArea(props: any) {
  let myProps = {
    teacher_arr: [],
    room_info_arr: [],
    timeInfoArr: [],
    setTimeInfoArr: (inData: any) => { },
    ...props
  };

  const go_change_all_teacher=(value:any)=>{
    let teacher_info=null;
    for(let i=0;i<myProps.teacher_arr.length;i++){
      if(value==myProps.teacher_arr[i]["a_seq"]){
        teacher_info=myProps.teacher_arr[i];
      }
    }
    
    let tmp_timeInfoArr=myProps.timeInfoArr;
    for(let i=0;i<tmp_timeInfoArr.length;i++){
      if(teacher_info==null){
        tmp_timeInfoArr[i]["a_teacher_seq"]="";
        tmp_timeInfoArr[i]["a_teacher_name"]="";
      }else{
        tmp_timeInfoArr[i]["a_teacher_seq"]=teacher_info["a_seq"];
        tmp_timeInfoArr[i]["a_teacher_name"]=teacher_info["a_user_name"];
      }
    }
    myProps.setTimeInfoArr(tmp_timeInfoArr);
  };

  const go_change_all_room=(value:any)=>{
    let room_info=null;
    for(let i=0;i<myProps.room_info_arr.length;i++){
      if(value==myProps.room_info_arr[i]["a_seq"]){
        room_info=myProps.room_info_arr[i];
      }
    }
    
    let tmp_timeInfoArr=myProps.timeInfoArr;
    for(let i=0;i<tmp_timeInfoArr.length;i++){
      if(room_info==null){
        tmp_timeInfoArr[i]["a_room_seq"]="";
      }else{
        tmp_timeInfoArr[i]["a_room_seq"]=room_info["a_seq"];
      }
    }
    myProps.setTimeInfoArr(tmp_timeInfoArr);
  };

  return (
    <div className="text-center" >
      모두변경:
      <select className="search-input" style={{ width: 80 }} value={""}
        onChange={(e: any) => {
          go_change_all_teacher(e.target.value);
        }} >
        <option value="" >선생님</option>
        {myProps.teacher_arr.map((item: any, idx: number) => {
          return (
            <option key={idx} value={item.a_seq} >{item.a_user_name}</option>
          );
        })}
      </select>
      <select className="search-input ml-1" style={{ width: 80 }} value={""}
        onChange={(e: any) => {
          go_change_all_room(e.target.value);
        }} >
        <option value="" >강의실</option>
        {myProps.room_info_arr.map((item: any, idx: number) => {
          return (
            <option key={idx} value={item.a_seq} >{item.a_name}</option>
          );
        })}
      </select>
    </div>
  );
};
export default TopManageBarArea;