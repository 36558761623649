import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';

class TdDataFunc {
  static get_split_day_data_of_counsel_todo_arr = (inData: any) => {
    let opt_obj = {
      counsel_todo_arr: [],
      study_diary_arr: [],
      ...inData,
    };
    let counsel_todo_arr = opt_obj.counsel_todo_arr;
    let study_diary_arr = opt_obj.study_diary_arr;
    let split_counsel_todo_arr: any = {};
    for (let i = 0; i < counsel_todo_arr.length; i++) {
      let ctodo_info = counsel_todo_arr[i];
      let a_reserve_date = ctodo_info["a_reserve_date"];
      if (split_counsel_todo_arr[a_reserve_date] == undefined) {
        split_counsel_todo_arr[a_reserve_date] = {
          "ctodo_arr": [],
          "success_cnt": 0,
          "tot": 0,
        };
      }
      ctodo_info["sdiary_arr"] = [];
      split_counsel_todo_arr[a_reserve_date]["ctodo_arr"].push(ctodo_info);
      if (ctodo_info["a_is_success"] == "1") {
        split_counsel_todo_arr[a_reserve_date]["success_cnt"]++;
      }
      split_counsel_todo_arr[a_reserve_date]["tot"]++;
    }
    //study_diary_arr
    for (let key_date_str in split_counsel_todo_arr) {
      let ctodo_arr = split_counsel_todo_arr[key_date_str]["ctodo_arr"];
      //7일전
      let pre7_date_time = DateFunc.get_change_date(new Date(key_date_str), 'day', -7).getTime();
      //let after3_date_time=DateFunc.get_change_date(new Date(key_date_str), 'day', +3).getTime();
      let after3_date_time=DateFunc.get_change_date(new Date(key_date_str), 'day', +0).getTime();

      //상담별 세팅
      for (let todo_i = 0; todo_i < ctodo_arr.length; todo_i++) {
        let ctodo_info = ctodo_arr[todo_i];
        //상담별 학습일지 찾기
        for (let i = 0; i < study_diary_arr.length; i++) {
          let sdiary_info = study_diary_arr[i];
          let a_study_date = sdiary_info["a_study_date"];
          let study_date_time = new Date(a_study_date).getTime();
          //같은학생, 날짜범위 면 추가
          if (ctodo_info["a_stu_seq"] == sdiary_info["a_stu_seq"]) {
            if (pre7_date_time < study_date_time && study_date_time <= after3_date_time) {
              ctodo_info["sdiary_arr"].push(sdiary_info);
            }
          }
        }
        ctodo_arr[todo_i] = ctodo_info;
      }
      split_counsel_todo_arr[key_date_str]["ctodo_arr"] = ctodo_arr;
    }
    return split_counsel_todo_arr;
  };
  static setting_data_at_td_data_arr = (inData: any) => {
    let opt_obj = {
      td_data_arr: [],
      ctodo_split_day_data: {},
      listOpt: {},
      Style: {},
      open_view_counsel_todo: (tmpInData: any) => { },
      show_write_counsel_todo: (tmpInData: any) => { },
      show_write_diary_page: (tmpInData: any) => { },
      is_show_counseler: false,
      is_show_sdiary: false,
      ...inData,
    };
    let td_data_arr = opt_obj["td_data_arr"];
    let ctodo_split_day_data = opt_obj["ctodo_split_day_data"];
    let Style = opt_obj["Style"];
    let td_data_len = td_data_arr.length;

    const now_date_json = DateFunc.get_date_json(new Date());
    let now_date_time = new Date(now_date_json.Y + "-" + now_date_json.m + "-" + now_date_json.d).getTime();

    for (let i = 0; i < td_data_len; i++) {
      let td_data = td_data_arr[i];
      let select_date_str = td_data["year"] + "-" + DateFunc.get_digit_str(td_data["month"]) + "-" + DateFunc.get_digit_str(td_data["day"]);
      let tmp_select_date_time = new Date(select_date_str).getTime();
      td_data_arr[i]["content"] = "";
      if (ctodo_split_day_data[select_date_str] != undefined) {
        let ctodo_split_data = ctodo_split_day_data[select_date_str];
        let opt_view_ctodo_list_opt = {
          "select_date": select_date_str,
          "list_opt": {
            "s_stu_name_like": opt_obj.listOpt.s_stu_name_like,
            "s_counseler_seq": opt_obj.listOpt.s_counseler_seq,
            "s_is_success": opt_obj.listOpt.s_is_success,
          }
        };
        td_data_arr[i]["content"] = (
          <div className={Style.counsel_todo_td_wrap} >
            <div className={Style.ctodo_td_title_wrap}
              onClick={() => { opt_obj.open_view_counsel_todo(opt_view_ctodo_list_opt); }}>
              {ctodo_split_data["success_cnt"]} / {ctodo_split_data["tot"]}
            </div>
            <div className={Style.ctodo_td_list} >
              {ctodo_split_data.ctodo_arr.map((item: any, idx: number) => {
                let state_back_color = "#eab358";

                let diary_state_str: any = "일지";
                let diary_state_title_str: any = "일지";
                let td_todo_row_state_box_style: any = {};
                if (item["sdiary_arr"].length > 0) {
                  diary_state_str = item["sdiary_arr"][0]["a_study_date"].substring(5, 10).replace("-", "/");
                  diary_state_str = "일지 " + diary_state_str;
                  diary_state_title_str="상담일지:"+item["sdiary_arr"].length+"건 (일주일 전부터 3일 후까지)";
                  for(let i=0;i<item["sdiary_arr"].length;i++){
                    diary_state_title_str+="\n "+item["sdiary_arr"][i]["a_study_date"].substring(5, 10).replace("-", "/");
                  }
                } else {
                  td_todo_row_state_box_style["color"] = "#f00";
                  td_todo_row_state_box_style["background"] = "#fff";
                }
                if (tmp_select_date_time < now_date_time) {
                  state_back_color = "#db6c58";
                }
                if (item.a_state == "complete") {
                  state_back_color = "#A2B854";
                }

                let counsel_border_style = "";
                if (item.a_kind == "신규") {
                  counsel_border_style = "2px solid #ff3333";
                }

                let a_reserve_time = "";
                if (item.a_reserve_time.substring(0, 5) != "00:00") {
                  let row_his_sec = strFunc.hisToSeconds(item.a_reserve_time);
                  let row_his_json = strFunc.secondsToTimeJson(row_his_sec);
                  a_reserve_time = row_his_json.h + ":" + row_his_json.i;
                }

                return (
                  <div key={idx} className={Style.ctodo_td_todo_row} style={{ background: state_back_color, border: counsel_border_style }} >
                    <span style={{ cursor: "pointer" }}
                      onClick={() => { opt_obj.open_view_counsel_todo(opt_view_ctodo_list_opt); }} 
                        title={"상담자:"+item.a_writer+" "+item.a_kind+"\n"+item.a_content}>
                      {item.a_stu_name}
                    </span>
                    {a_reserve_time != "" &&
                      <span className="ml-1" style={{ color: "#777", fontSize: "10px" }}>
                        {a_reserve_time}
                      </span>
                    }
                    {opt_obj.is_show_counseler &&
                      <span className={Style.ctodo_td_todo_row_sub_text}>
                        {item.a_counseler}
                      </span>
                    }
                    {opt_obj.is_show_sdiary &&
                      <div className={Style.ctodo_td_todo_row_state_box} style={td_todo_row_state_box_style}
                        onClick={() => {
                          opt_obj.show_write_diary_page({
                            stu_seq_arr: [item["a_stu_seq"]],
                            select_stu_seq: item["a_stu_seq"],
                            select_cousel_seq: item["a_ymd"]+","+item["a_seq"],
                            //select_date: item["a_reserve_date"],
                          });
                        }} title={diary_state_title_str} >
                        {diary_state_str}
                      </div>
                    }
                  </div>
                )
              })}
            </div>
            <button className="btn btn-dark"
              style={{
                position: "absolute",
                top: -27,
                right: 0,
                background: "#508B87"
              }}
              onClick={() => {
                opt_obj.show_write_counsel_todo({ start_date: select_date_str, end_date: select_date_str });
              }}>+</button>
          </div>
        );
      } else if (tmp_select_date_time >= now_date_time) {
        td_data_arr[i]["content"] = (
          <div className="text-center" style={{ lineHeight: "90px" }}>
            <button className={Style.ctodo_td_center_plus_btn}
              onClick={() => {
                opt_obj.show_write_counsel_todo({ start_date: select_date_str, end_date: select_date_str });
              }}
            >+ 상담일정</button>
          </div>
        );
      } else {
        td_data_arr[i]["content"] = (
          <div className="text-center" style={{ lineHeight: "90px", position: "relative" }}>
            <button className="btn btn-dark"
              style={{
                position: "absolute",
                top: -27,
                right: 0,
                background: "#508B87"
              }}
              onClick={() => {
                opt_obj.show_write_counsel_todo({ start_date: select_date_str, end_date: select_date_str });
              }}>+</button>
          </div>
        );
      }
    }

    return td_data_arr;
  };
}
export default TdDataFunc;