import strFunc from "@/lib/lyg/string";
import SectionArea from "./section";

function SectionStudyArea(props:any){
  const myProps={
    section_arr:[],
    study_arr:[],
    set_section_arr:(section_arr:any)=>{},
    set_study_arr:(study_arr:any)=>{},
    ...props
  };

  let section_arr=myProps.section_arr;

  let default_section_info={
    a_title_seq:"",
    a_seq:"",
    a_title:"",
    a_memo:"",
    a_order_num:"",
    study_arr:[],//[{row_num:0},...]
  };

  const addNewRow=()=>{
    let max_a_seq=0;
    if(section_arr.length>0){
      for(let i=0;i<section_arr.length;i++){
        let tmp_a_seq=parseInt(section_arr[i].a_seq);
        if(tmp_a_seq>max_a_seq){
          max_a_seq=tmp_a_seq;
        }
      }
    }
    let addRow:any={...default_section_info};
    addRow["a_seq"]=max_a_seq+1;
    addRow["a_order_num"]=addRow["a_seq"];
    let tmp_section_arr:any=[...section_arr];
    tmp_section_arr.push(addRow);
    myProps.set_section_arr(tmp_section_arr);
  };

  const removeRowData=(row_num:number)=>{
    let tmp_section_arr=[];
    for(let i=0;i<section_arr.length;i++){
      if(i!=row_num){
        tmp_section_arr.push(section_arr[i]);
      }
    }
    myProps.set_section_arr(tmp_section_arr);
  };

  const moveOrderNum=(row_num:number,up_down:string)=>{
    let tmp_section_arr:any=[...section_arr];

    let next_row_num=row_num;
    if(up_down=="up"){
      next_row_num=row_num-1;
    }else if(up_down=="down"){
      next_row_num=row_num+1;
    }

    if(next_row_num<0){
      return false;
    }
    if(next_row_num>=tmp_section_arr.length){
      return false;
    }

    let now_row_data=tmp_section_arr[row_num];
    let change_row_data=tmp_section_arr[next_row_num];

    tmp_section_arr[row_num]=change_row_data;
    tmp_section_arr[next_row_num]=now_row_data;

    tmp_section_arr=getReSetOrderNum(tmp_section_arr);

    myProps.set_section_arr(tmp_section_arr);
  };

  const getReSetOrderNum=(tmp_section_arr:any)=>{
    for(let i=0;i<tmp_section_arr.length;i++){
      tmp_section_arr[i].a_order_num=i+1;
    }
    return tmp_section_arr;
  };

  const onChangeRowData=(inOptObj:any)=>{
    if(inOptObj==undefined){inOptObj={};}
    let optObj={
      key:"",
      value:"",
      row_num:0,
      ...inOptObj
    };
    let value=optObj["value"];
    let key=optObj["key"];
    let row_num=optObj["row_num"];
    let tmp_section_arr:any=[...section_arr];
    tmp_section_arr[row_num][key]=value;

    myProps.set_section_arr(tmp_section_arr);
  };

  const get_section_arr_of_addon_study=()=>{
    let addon_section_arr=[];
    let section_len=myProps.section_arr.length;
    let study_row_num_arr=[];
    for(let section_i=0;section_i<section_len;section_i++){
      let section_info={
        ...default_section_info,
        ...myProps.section_arr[section_i]
      };
      section_info.study_arr=[];
      for(let i=0;i<myProps.study_arr.length;i++){
        let study_info=myProps.study_arr[i];
        if(section_info["a_seq"]==study_info["a_section_seq"]){
          study_info["row_num"]=i;
          section_info.study_arr.push(study_info);
          study_row_num_arr.push(i);
        }
      }
      addon_section_arr.push(section_info);
    }

    //빈섹션 등록
    if(study_row_num_arr.length!=myProps.study_arr.length&&myProps.study_arr.length.length>0){
      let last_section_num=myProps.section_arr.length+1;
      let empty_section_info:any={
        ...default_section_info,
        a_title_seq:"",
        a_seq:"empty",
        a_title:"소속없음",
        a_order_num:last_section_num,
      };
      empty_section_info.study_arr=[];
      for(let i=0;i<myProps.study_arr.length;i++){
        let study_info=myProps.study_arr[i];
        if(strFunc.str_in_array(i,study_row_num_arr)==-1){
          study_info["row_num"]=i;
          empty_section_info.study_arr.push(study_info);
          study_row_num_arr.push(i);
        }
      }
      addon_section_arr.push(empty_section_info);
    }

    return addon_section_arr;
  };

  const get_section_tag_arr=()=>{
    //section_arr 만들기
    let addon_section_arr=get_section_arr_of_addon_study();

    //tags얻기
    let section_tags:any=addon_section_arr.map((item:any,idx:number)=>{
      return (
        <SectionArea
          key={idx}
          section_info={item}
          section_row_num={idx}
          section_arr={myProps.section_arr}
          study_arr={myProps.study_arr}
          set_section_arr={myProps.set_section_arr}
          set_study_arr={myProps.set_study_arr}

          onChangeRowData={onChangeRowData}
          moveOrderNum={moveOrderNum}
          removeRowData={removeRowData}
        ></SectionArea>
      );
    });
    
    return section_tags;
  };

  return  (
    <div style={{border:"1px solid #ddd",padding:5}}>
      <h4 style={{color:"#00f"}}>
        단원
        <button className="btn-s btn-dark ml-2" onClick={()=>{addNewRow();}}>대단원 추가</button>
      </h4>
      <div className="mt-1">
        {get_section_tag_arr()}
      </div>
    </div>
  );
}
export default SectionStudyArea;