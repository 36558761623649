import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";
import CommentPartArea from "@/pcomponents/common/content/comment/part_area";

function TdContentArea(props: any) {
  let myProps = {
    name: "",
    week_info: {},
    week_idx: 0,
    on_change_week_info: (inData: any) => { },
    is_view_mode: false,
    openFindPrescribePopup: (inData: any) => { },
    comment_list: [],
    refresh_comment: (inData: any) => { },
    ...props
  };
  let name = myProps.name;
  let week_info = myProps.week_info;
  let is_view_mode = myProps.is_view_mode;

  let row_pri_val = week_info["a_ymd"] + "," + week_info["a_seq"];
  let is_update = false;
  let comment_init_data = {
    writer_seq: week_info.a_writer_seq,
    writer_name: week_info.a_writer_name,
    par_id: "month_prescribe",
    par_seq: row_pri_val,
    par_locate: "",
    par_locate_name: "",
    s_start_date: DateFunc.get_date_format(new Date(week_info["a_reserve_date"]), "Y-01-01"),
    s_end_date: DateFunc.get_date_format(new Date(), "Y-12-31"),
  };
  if (!strFunc.is_empty(week_info["a_seq"])) {
    is_update = true;
    comment_init_data["s_start_date"] = week_info["a_reserve_date"];
  }

  return (
    <div>
      {name == "a_progress_reason" &&
        <div>
          <div className="text-left pl-2" style={{ fontWeight: "bold" }}>1. 진도전략</div>
          <div className="text-left mt-1" style={{ minHeight: 20 }}>
            {is_view_mode ?
              <MultyLineTextView text={week_info.a_progress_reason}></MultyLineTextView>
              :
              <TextAreaComponent
                name={"a_progress_reason"}
                value={week_info.a_progress_reason}
                class_name={"row-input"}
                height={32}
                style={{ "width": "100%" }}
                onChange={(e: any) => {
                  myProps.on_change_week_info({
                    name: "a_progress_reason",
                    value: e.target.value,
                    week_idx: myProps.week_idx,
                  });
                }}
                placeholder={"내용입력"}
              ></TextAreaComponent>
            }
          </div>
        </div>
      }
      {name == "a_progress" &&
        <div>
          <div className="text-left pl-2" style={{ fontWeight: "bold" }}>2. 현재 진도</div>
          <div className="text-left mt-1" style={{ minHeight: 20 }}>
            {is_view_mode ?
              <MultyLineTextView text={week_info.a_progress}></MultyLineTextView>
              :
              <TextAreaComponent
                name={"a_progress"}
                value={week_info.a_progress}
                class_name={"row-input"}
                height={32}
                style={{ "width": "100%" }}
                onChange={(e: any) => {
                  myProps.on_change_week_info({
                    name: "a_progress",
                    value: e.target.value,
                    week_idx: myProps.week_idx,
                  });
                }}
                placeholder={"내용입력"}
              ></TextAreaComponent>
            }
          </div>
        </div>
      }
      {name == "a_grow" &&
        <div>
          <div className="text-left pl-2" style={{ fontWeight: "bold" }}>
            3. 성장한 부분
            {is_view_mode == false &&
              <button className="btn-s btn-dark ml-1" onClick={() => {
                myProps.openFindPrescribePopup({
                  row_num: myProps.week_idx,
                });
              }}>찾기</button>
            }
          </div>
          <div className="text-left mt-1" style={{ minHeight: 20 }}>
            {is_view_mode ?
              <MultyLineTextView text={week_info.a_grow}></MultyLineTextView>
              :
              <TextAreaComponent
                name={"a_grow"}
                value={week_info.a_grow}
                class_name={"row-input"}
                height={32}
                style={{ "width": "100%" }}
                onChange={(e: any) => {
                  myProps.on_change_week_info({
                    name: "a_grow",
                    value: e.target.value,
                    week_idx: myProps.week_idx,
                  });
                }}
                placeholder={"내용입력"}
              ></TextAreaComponent>
            }
          </div>
        </div>
      }
      {name == "a_lack" &&
        <div>
          <div className="text-left pl-2" style={{ fontWeight: "bold" }}>4. 발견</div>
          <div className="text-left mt-1" style={{ minHeight: 20 }}>
            {is_view_mode ?
              <MultyLineTextView text={week_info.a_lack}></MultyLineTextView>
              :
              <TextAreaComponent
                name={"a_lack"}
                value={week_info.a_lack}
                class_name={"row-input"}
                height={32}
                style={{ "width": "100%" }}
                onChange={(e: any) => {
                  myProps.on_change_week_info({
                    name: "a_lack",
                    value: e.target.value,
                    week_idx: myProps.week_idx,
                  });
                }}
                placeholder={"내용입력"}
              ></TextAreaComponent>
            }
          </div>
          <div>
            {is_update &&
              <CommentPartArea
                info_arr={myProps.comment_list}
                list={myProps.refresh_comment}
                is_show_toggle={false}
                is_show_detail={true}
                is_send_write_push={true}
                title_str={"질문"}
                wrap_style={{ width: "100%" }}
                init_data={{
                  ...comment_init_data,
                  par_locate: "a_lack",
                  par_locate_name: "질문",
                }}
              ></CommentPartArea>
            }
          </div>
        </div>
      }
      {name == "a_content" &&
        <div>
          <div className="text-left pl-2" style={{ color: "#fff", background: "#2d8372", position: "relative" }}>
            5. 학습 처방
            <span style={{ position: "absolute", right: 5 }} title={week_info.a_is_success == "1" ? week_info.a_success_date : ""} >
              <label>
                <input type="checkbox" name="a_is_success" value="1"
                  className="ml-2 mr-1"
                  checked={week_info.a_is_success == "1"}
                  onChange={(e: any) => {
                    let change_val = "";
                    if (e.target.checked) {
                      change_val = "1";
                    }
                    if (is_view_mode == false) {
                      myProps.on_change_week_info({
                        name: "a_is_success",
                        value: change_val,
                        week_idx: myProps.week_idx,
                      });
                    }
                  }}
                />
                완료
              </label>
            </span>
          </div>
          <div className="text-left mt-1" style={{ minHeight: 20 }}>
            {is_view_mode ?
              <MultyLineTextView
                text={week_info.a_content}
                style={{ color: "blue" }}></MultyLineTextView>
              :
              <TextAreaComponent
                name={"a_content"}
                value={week_info.a_content}
                class_name={"row-input"}
                height={32}
                style={{ color: "blue" }}
                onChange={(e: any) => {
                  myProps.on_change_week_info({
                    name: "a_content",
                    value: e.target.value,
                    week_idx: myProps.week_idx,
                  });
                }}
                placeholder={"내용입력"}
              ></TextAreaComponent>
            }
          </div>
        </div>
      }
    </div>
  );
};
export default TdContentArea;