import CompDefault from "@/pages/comp_stu/main";
import MyVideoList from "@/pages/comp_stu/plan/video/my_list";
import MyVideoListView from "@/pages/comp_stu/plan/video/my_list_view";
import CorseList from "@/pages/comp_stu/plan/video/corse_list";
import TodoList from "@/pages/comp_stu/plan/todo/list";
import TodoListView from "@/pages/comp_stu/plan/todo/list_view";
import TodoViewVideoPage from "@/pages/comp_stu/plan/todo/todo_view_video";
import MySchedule from "@/pages/comp_stu/sche/main";
import MyPage from "@/pages/comp_stu/setting/my_page";

const routerArr:object[] = [
  {
    path: "/comp_stu",
    element: <CompDefault></CompDefault>,
  },

  {
    path: "/comp_stu/video/my_list",
    element: <MyVideoList></MyVideoList>,
  },
  {
    path: "/comp_stu/video/my_list_view",
    element: <MyVideoListView></MyVideoListView>,
  },
  {
    path: "/comp_stu/video/corse_list",
    element: <CorseList></CorseList>,
  },
  {
    path: "/comp_stu/plan/todo_list",
    element: <TodoList></TodoList>,
  },
  {
    path: "/comp_stu/plan/todo_list_view",
    element: <TodoListView></TodoListView>,
  },
  {
    path: "/comp_stu/plan/todo_list_view_video",
    element: <TodoViewVideoPage></TodoViewVideoPage>,
  },
  {
    path: "/comp_stu/sche/my_sche",
    element: <MySchedule></MySchedule>,
  },
  {
    path: "/comp_stu/setting/mypage",
    element: <MyPage></MyPage>,
  },
];

export default routerArr;