import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import WritePopupContent from '../popup/write/index';
import ViewPopupContent from '../popup/view_content/index';
import ViewStuLayerPopup from "@/pages/comp/basic/student/popup/view_layer";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from "@/lib/lyg/string";
import { useSelector, useDispatch } from 'react-redux';
import SearchArea from './area/search/search_area';
import WriteAreaComponent from "./area/write";
import MonthPrescribeListFunc from "@/pcomponents/common/content/edu/month_prescribe/list/list_func";

function ListPage() {
  let user=useSelector((state:any) => state.user);
  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  let default_list_opt = {
    's_start_date': DateFunc.get_date_format(new Date(), "Y-m-d"),
    's_end_date': DateFunc.get_date_format(new Date(), "Y-m-d"),
    "s_is_success": "",
    "s_writer_seq": "",
    "s_subject": "",
  };
  const [pageData, setPageData] = useState({
    listOpt: {
      ...xColumnArr.list_opt,
      "s_stu_bookmark": strFunc.get_storage("s_stu_bookmark"),
      "s_addon_user": "1",
      "s_addon_comment": "1",
      "select_seq": "",
      ...default_list_opt
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const WriteAreaComponentRef = useRef<any>(null);

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "write",//view
    isUpdate: false,
    rowData: {},
    stu_seq: "",
    stu_name: "",
    has_header: true,
    write_page_data: {
      is_show: false,
      is_update: false,
      is_view_mode: true,
      is_view_basic: false,
      info_arr: [],
      select_idx: 0,
      default_write_info: {},
      tab_id: "monthly_prescribe",
      is_first_tab_scroll: true,
      counsel_s_select_date: "",
    },
    title: "팝업",
    width: "800px",
    height: "80%",
  });

  useEffect(() => {

  }, []);

  const list = (inOptObj: any) => {
    let tmpPageData = pageData;
    if (inOptObj != undefined) {
      inOptObj["sc"] = listAggridRef.current.getListSc();
      for (let key in tmpPageData.listOpt) {
        if (inOptObj[key] != undefined) {
          tmpPageData.listOpt[key] = inOptObj[key];
        }
      }
      setPageData({ ...tmpPageData });
    }
    let tmp_list_opt = { ...tmpPageData.listOpt };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/month_prescribe/list', tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          response.data["data"]["info_arr"]=MonthPrescribeListFunc.get_list_week_i_by_date({
            info_arr: response.data["data"]["info_arr"],
            select_date: tmp_list_opt.s_start_date,
          });
          response.data["data"]["info_arr"]=MonthPrescribeListFunc.get_list_order_by_comment_arr({
            info_arr: response.data["data"]["info_arr"],
            iam_user_seq:user.user_seq,
          });

          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
          if (!strFunc.is_empty(tmp_list_opt.select_seq) && listAggridRef.current) {
            setTimeout(() => {
              let all_nodes = listAggridRef.current.getNodeRows();
              for (let i = 0; i < all_nodes.length; i++) {
                let row_node = all_nodes[i];
                let row_pri_val = row_node.data["a_ymd"] + "," + row_node.data["a_seq"];
                if (row_pri_val == tmp_list_opt.select_seq) {
                  row_node.setSelected(true);
                }
              }
            }, 100);
          }
        } else {

        }
      });
  };

  const goDelete = useCallback((e: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    let form_data = {
      "data_arr": selected_row
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/month_prescribe/delete', form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert(response.data["msg"]);
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  }, []);

  const openOnePopup = (sort: string, isUpdate: boolean) => {
    let pop_title = "등록";
    let tmp_rowData = {};
    if (isUpdate) {
      pop_title = "수정";
      let selected_row = listAggridRef.current.getSelectedRows();
      if (selected_row.length == 0) {
        alert("선택이 없습니다.");
        return false;
      }
      if (selected_row.length > 0) {
        tmp_rowData = { ...selected_row[0] };
      }
    }
    if (sort == "view") {
      pop_title = "보기";
    }
    setPopupData({
      ...popupData,
      "isOpen": true,
      "isUpdate": isUpdate,
      "rowData": tmp_rowData,
      "title": pop_title,
      "sort": sort,
      width: "800px",
      height: "80%",
    });
  };

  const open_stu_layer_popup = () => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    if (selected_row.length != 1) {
      alert("한개만 선택해주세요.");
      return false;
    }
    let select_row_data = selected_row[0];
    if (strFunc.is_empty(select_row_data["stu_info"])) {
      alert("학생정보가 없습니다.");
      return false;
    }
    let select_idx = 0;
    let stu_info_arr: any = [select_row_data["stu_info"]];
    let s_row_select_date = select_row_data["a_reserve_date"];
    if (select_row_data["week_i"] == -1) {
      //전달로 보내기
      s_row_select_date = DateFunc.get_date_format(new Date(DateFunc.get_change_date(new Date(s_row_select_date), 'month', -1)), "Y-m-d");
    }

    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": "원생관리",
      "sort": "stu_view",
      write_page_data: {
        is_show: true,
        is_update: true,
        is_view_mode: true,
        is_view_basic: false,
        select_idx: select_idx,
        info_arr: stu_info_arr,
        default_write_info: {},
        tab_id: "monthly_prescribe",
        is_first_tab_scroll: true,
        counsel_s_select_date: s_row_select_date,
      },
      width: "98%",
      height: "99%",
      has_header: false,
    });
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;
    if (key == "a_stu_name") {
      // setTimeout(() => {
      //   open_stu_layer_popup();
      // }, 100);
    }
  }, []);

  const cellRenderer = (params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;
    let just_con_key_arr = [
      "a_study_goal",
      "a_observe",
      "a_study_prescribe",
    ];
    if (key == "a_is_success") {
      let col_text = "아니오";
      let col_style: any = {
        "color": "red",
      };
      let content_title_str = "";
      if (params.data.comment_arr && params.data.comment_arr.length > 0) {
        col_style["background"] = "red";
        col_style["color"] = "#fff";
        col_style["padding"] = "0px 3px";
        col_style["borderRadius"] = "5px";
        for (let i = 0; i < params.data.comment_arr.length; i++) {
          let comment_idx = i + 1;
          let comment_info = params.data.comment_arr[i];
          content_title_str += comment_idx + "." + comment_info["a_responser"] + " " + comment_info["a_create_date"] + "\n";
          content_title_str += "  " + comment_info["a_message"] + "\n";
          if (i == (params.data.comment_arr.length - 1)) {
            if (comment_info["a_qna_sort"] == "a") {
              col_style["background"] = "#de8003";
            }
          }
        }
      }
      if (params.data.a_is_success == "1") {
        col_text = "완료";
        col_style["color"] = "blue";
        col_style["background"] = "";
      }
      if (params.data.comment_arr && params.data.comment_arr.length > 0) {
        col_text += "(" + params.data.comment_arr.length + ")";
      }
      render_str = (
        <span style={col_style} title={content_title_str}>{col_text}</span>
      );
    } else if (key == "a_prescribe_success_date") {
      if (params.data.a_is_success_prescribe != "1") {
        render_str = "";
      }
    } else if (key == "row_view_manage") {
      let content_title_str = "1.진도전략:\n";
      content_title_str += params.data.a_progress_reason + "\n";
      content_title_str += "2.현재진도:\n";
      content_title_str += params.data.a_progress + "\n";
      content_title_str += "3.성장한부분:\n";
      content_title_str += params.data.a_grow + "\n";
      content_title_str += "4.발견:\n";
      content_title_str += params.data.a_lack + "\n";
      content_title_str += "5.학습처방:\n";
      content_title_str += params.data.a_content + "\n";
      content_title_str += "작성일:" + params.data.a_create_date + "\n";
      if (params.data.a_is_success == "1") {
        content_title_str += "처방일:" + params.data.a_success_date + "\n";
      }

      render_str = (
        <div className="text-center">
          <button className="btn btn-dark"
            title={content_title_str}
            onClick={() => {
              setTimeout(() => {
                open_stu_layer_popup();
              }, 100);
            }}>상세</button>
          <button className="btn btn-dark ml-1"
            title={content_title_str}
            onClick={() => {
              setTimeout(() => {
                openOnePopup("view", true);
              }, 100);
            }}
          >복사</button>
        </div>
      );
    } else if (strFunc.str_in_array(key, just_con_key_arr) != -1) {
      render_str = (
        <div title={params.value}>
          {params.value}
        </div>
      );
    } else if (key == "row_view_comment") {
      if (params.data.comment_arr && params.data.comment_arr.length > 0) {
        let last_comment_info = params.data.comment_arr[0];
        render_str = (
          <span title={last_comment_info["a_message"]}>
            {"(" + params.data.comment_arr.length + ") " + last_comment_info["a_message"]}
          </span>
        );
      }
    } else if (key == "a_stu_name") {
      if (params.data.stu_info) {
        render_str = params.data.stu_info["a_user_name"];
      }
    } else if (key == "row_view_date") {
      let week_i_num: any = parseInt(strFunc.uncomma(params.data.week_i));
      week_i_num++;
      if (week_i_num == 0) {
        //week_i_num="전달 마지막";
        week_i_num = "전달";
      } else {
        week_i_num = week_i_num + "주";
      }
      render_str = params.data.a_reserve_date + " (" + week_i_num + ")";
    }
    return render_str;
  };

  const onSelectionChanged = (event: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length == 0) {
      if (WriteAreaComponentRef.current) {
        WriteAreaComponentRef.current.initData({
          isUpdate: false,
        });
      }
    } else if (selected_row.length == 1) {
      if (WriteAreaComponentRef.current) {
        WriteAreaComponentRef.current.initData({
          rowData: selected_row[0],
          isUpdate: true,
        });
      }
    }
  };

  return (
    <CompLayout isConTitle={false} >
      <div className="con_wrap">
        <SearchArea
          listOpt={{ ...pageData.listOpt }}
          xColumnArr={{ ...xColumnArr }}
          list={list}
        ></SearchArea>
        <div className="list_round_box_wrap mt-7">
          <div className="list_box_title_bar">
            <span className="list_box_title_bar_title">월간학습처방</span>
            <div className="absolute right-3 top-4 btn-box-right">
              <button className="btn btn-sky" onClick={() => {
                if (WriteAreaComponentRef.current) {
                  WriteAreaComponentRef.current.initData({
                    isUpdate: false,
                  });
                }
              }}>신규</button>
              <button className="btn-m btn-red" onClick={goDelete}>삭제</button>
            </div>
          </div>
          <div className="list_round_box">
            <div className="flex flex-row gap-x-2">
              <div style={{ flexGrow: 1, minWidth: 660 }}>
                <ListAggrid
                  ref={listAggridRef}
                  infoArr={infoArr}
                  xColumnArr={{ ...xColumnArr }}
                  list={list}
                  gridOpt={{
                    fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
                    is_idx_num: false,
                    is_add_checkbox: true,
                    floatingFilter: false,
                    onGridReady: () => {
                      list({});
                    },
                    onCellClicked: cellClickedListener,
                    cellRenderer: cellRenderer,
                    onSelectionChanged: onSelectionChanged
                  }}
                ></ListAggrid>
                <Paging now_page={pageData.listOpt.now_page}
                  num_per_page={pageData.listOpt.num_per_page}
                  total_rec={countInfo.tot}
                  onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
                  onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
              </div>
              <div style={{ flexGrow: 1, minWidth: 400 }}>
                <WriteAreaComponent
                  ref={WriteAreaComponentRef}
                  list={list}
                ></WriteAreaComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} has_header={popupData.has_header} >
          {popupData.sort === "write" &&
            <WritePopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData}
              callback={(inData: any) => { list({ now_page: 1, select_seq: inData.select_seq }); }}
              stu_seq={popupData.stu_seq}
              stu_name={popupData.stu_name}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></WritePopupContent>
          }
          {popupData.sort === "view" &&
            <ViewPopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
              openOnePopup={openOnePopup}></ViewPopupContent>
          }
          {popupData.sort === "stu_view" &&
            <ViewStuLayerPopup
              write_page_data={popupData.write_page_data}
              callback={(inData: any) => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></ViewStuLayerPopup>
          }
        </LayerPopup>
      }
    </CompLayout>
  );
}

export default ListPage;