import { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import strFunc from '@/lib/lyg/string';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';

const TableBodyArea = forwardRef((props: any, ref) => {
  let myProps: any = {
    gridOpt: {},
    colDefs: [],
    onscrollLeft: (left_num: number) => { },
    infoArr: [],
    active_row_arr:[],//[true,false, ...]
    set_active_row_arr:(inData:any)=>{},
    isGrid: false,
    xColumnArr: {},
    "onChangeInput": (params: any) => {
      return params;
    },
    "customCellRenderer": (params: any) => {
      return params.value;
    },
    "customGridCellRenderer": (params: any) => {
      return params.input_tag;
    },
    ...props
  };

  const gridOpt = {
    ...myProps.gridOpt
  };
  const [xColumnArr, setXColumnArrBody] = useState(myProps.xColumnArr);
  const colDefs = myProps.colDefs;
  const listBodyRef = useRef<any>(null);
  const infoArr=myProps.infoArr;
  const active_row_arr = myProps.active_row_arr;
  const isGrid = gridOpt.isGrid;
  const [col_max_width_arr, set_col_max_width_arr] = useState([]);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setColumnMaxWidth,checkAll,
    getRows, getSelectedRows,
  }));

  const setColumnMaxWidth = (in_arr: []) => {
    set_col_max_width_arr(in_arr);
  };

  const getSelectedRows = () => {
    let selectedRows = [];
    for (let i = 0; i < infoArr.length; i++) {
      if (active_row_arr[i]) {
        selectedRows.push({
          ...infoArr[i],
          idx_num: infoArr[i].idx_num ? infoArr[i].idx_num : i
        });
      }
    }
    return selectedRows;
  };

  const getRows = () => {
    let all_rows = [];
    for (let i = 0; i < infoArr.length; i++) {
      all_rows.push({
        ...infoArr[i],
        idx_num: infoArr[i].idx_num ? infoArr[i].idx_num : i
      });
    }
    return all_rows;
  };

  const onChangeInput = (params: any) => {
    let optObj = {
      "rowData": {},
      "row_num": 0,
      "key": "",
      "value": "",
      "pre_value": "",
      "event": null,
      ...params
    };

    if (strFunc.str_in_array(optObj.key, xColumnArr.tel_col_arr) != -1) {
      optObj.value = strFunc.autoHypenPhone(optObj.value);
    }
    if (strFunc.str_in_array(optObj.key, xColumnArr.number_col_arr) != -1) {
      optObj.value = strFunc.comma(optObj.value);
    }
    optObj = myProps.onChangeInput(params);

    let tmpInfoArr = [...infoArr];
    for (let i = 0; i < tmpInfoArr.length; i++) {
      if (i == optObj.row_num) {
        tmpInfoArr[i][optObj.key] = optObj.value;
      }
    }
  };
  const onChangeDatePicker = (params: any) => {
    let optObj: any = {
      "rowData": {},
      "row_num": 0,
      "key": "",
      "value": "",
      "pre_value": "",
      "event": null,
      "date": "",
      ...params
    };
    optObj.value = DateFunc.get_date_format(optObj.date, "Y-m-d");
    onChangeInput(optObj);
  };

  const onSelectChange = (all_info_arr: any, in_active_row_arr: any) => {
    let tmp_select_info_arr = [];
    let all_len = all_info_arr.length;
    for (let i = 0; i < all_len; i++) {
      if (in_active_row_arr[i]) {
        tmp_select_info_arr.push(all_info_arr[i]);
      }
    }
    gridOpt.onSelectChange(tmp_select_info_arr);
  };

  const rowOnClick = (rowData: any, idx: number, e: any) => {
    let tmpInfoArr = [...infoArr];
    let tmp_active_row_arr = [...active_row_arr];
    for (let i = 0; i < tmpInfoArr.length; i++) {
      if (e.target.className != gridOpt.class_name_json.input_check) {
        tmp_active_row_arr[i] = false;
      }
      if (i == idx) {
        if (e.target.className == gridOpt.class_name_json.input_check) {
          tmp_active_row_arr[i] = !tmp_active_row_arr[i];
        } else {
          tmp_active_row_arr[i] = true;
        }
      }
    }
    myProps.set_active_row_arr(tmp_active_row_arr);
    gridOpt.rowOnClick(rowData, idx, e);
    onSelectChange(tmpInfoArr, tmp_active_row_arr);
  };

  const checkAll = (is_checked: boolean) => {
    let is_check = false;
    if (is_checked) {
      is_check = true;
    }
    let tmpInfoArr = [...infoArr];
    let tmp_active_row_arr = [...active_row_arr];
    for (let i = 0; i < tmpInfoArr.length; i++) {
      tmp_active_row_arr[i] = is_check;
    }
    myProps.set_active_row_arr(tmp_active_row_arr);
    onSelectChange(tmpInfoArr, tmp_active_row_arr);
  };

  const getIsGridInputTdTag = (inData: any) => {
    let optObj: any = {
      "rowData": {},
      "idx": 0,
      "key": "",
      "value": "",
      "xColumnArr": null,
      ...inData
    };

    let input_tag = optObj.value;
    let column = null;
    for (let i = 0; i < optObj.xColumnArr.x_column_list_arr.length; i++) {
      if (optObj.xColumnArr.x_column_list_arr[i].key == optObj.key) {
        column = optObj.xColumnArr.x_column_list_arr[i];
      }
    }

    if (column == null) {
      return input_tag;
    }
    //view_col_arr에 있다면 생략
    if (strFunc.str_in_array(optObj.key, optObj.xColumnArr.view_col_arr) != -1) {
      return input_tag;
    }
    if (strFunc.str_in_array(optObj.key, ["checkbox", "idx_num"]) != -1) {
      return input_tag;
    }
    let row_input_style: any = {
      width: "98%"
    };
    if (strFunc.str_in_array(optObj.key, optObj.xColumnArr.tel_col_arr) != -1) {
      optObj.value = strFunc.autoHypenPhone(optObj.value);
    }
    if (strFunc.str_in_array(optObj.key, optObj.xColumnArr.number_col_arr) != -1) {
      optObj.value = strFunc.comma(optObj.value);
      row_input_style["textAlign"] = "right";
    }

    if (optObj.value == undefined || optObj.value == null) {
      optObj.value = "";
    }
    
    input_tag = (
      <input type="text" className={gridOpt.class_name_json.row_input} name={optObj.key} value={optObj.value}
        onChange={(e: any) => {
          onChangeInput({
            "rowData": optObj.rowData,
            "row_num": optObj.idx,
            "key": optObj.key,
            "value": e.target.value,
            "pre_value": optObj.value,
            "event": e,
          });
        }}
        onClick={(e: any) => { e.target.select(); }}
        style={row_input_style} />
    );

    if (strFunc.str_in_array(optObj.key, optObj.xColumnArr.password_col_arr) != -1) {
      input_tag = (
        <input type="password" className={gridOpt.class_name_json.row_input} name={optObj.key} value={optObj.value}
          onChange={(e: any) => {
            onChangeInput({
              "rowData": optObj.rowData,
              "row_num": optObj.idx,
              "key": optObj.key,
              "value": e.target.value,
              "pre_value": optObj.value,
              "event": e,
            });
          }}
          style={row_input_style} />
      );
    } else if (optObj.xColumnArr.select_arr[optObj.key] != undefined) {
      input_tag = (
        <select className={gridOpt.class_name_json.row_input} name={optObj.key} value={optObj.value}
          onChange={(e: any) => {
            onChangeInput({
              "rowData": optObj.rowData,
              "row_num": optObj.idx,
              "key": optObj.key,
              "value": e.target.value,
              "pre_value": optObj.value,
              "event": e,
            });
          }}
          style={{ width: "98%" }}>

          {optObj.xColumnArr.select_arr[optObj.key].map((selectOpt: any, select_i: number) => {
            return (
              <option key={select_i} value={selectOpt.value}>{selectOpt.text}</option>
            );
          })}
        </select>
      );
    } else if (strFunc.str_in_array(optObj.key, optObj.xColumnArr.date_col_arr) != -1) {
      input_tag = (
        <DatePicker
          selected={optObj.value != "" ? new Date(optObj.value) : null}
          onChange={(date: Date) => {
            onChangeDatePicker({
              "rowData": optObj.rowData,
              "row_num": optObj.idx,
              "key": optObj.key,
              "value": "",
              "pre_value": optObj.value,
              "event": null,
              "date": date
            });
          }}
          locale={ko}
          dateFormat="yyyy-MM-dd"
          customInput={<CustomInputWrite />}
          renderCustomHeader={customHeaderFunc}
        />
      );
    }

    input_tag = myProps.customGridCellRenderer({
      "rowData": optObj.rowData,
      "idx": optObj.idx,
      "key": optObj.key,
      "value": optObj.value,
      "input_tag": input_tag,
      "onChangeInput": onChangeInput,
    });

    input_tag = (
      <div style={{ width: column.width }}>
        {input_tag}
      </div>
    );


    return input_tag;
  };

  const getColTags = (inData: any) => {
    let optObj: any = {
      rowData: {},
      rowNum: 0,
      ...inData
    };
    let rowData = optObj.rowData;
    let rowNum = optObj.rowNum;
    let show_idx_num = 0;
    let tdCols = colDefs.map((col_data: any, idx: number) => {
      let key = col_data["key"];
      let tmp_col_width = parseInt(col_data["width"]);
      let origin_val: any = "";
      let row_val: any = "";
      if (rowData[key] != undefined) {
        row_val = rowData[key];
        origin_val = rowData[key];
      }
      let td_className = "";
      if (col_data["is_show"] != "1") {
        return "";
      }
      if (key == "checkbox") {
        let isChecked = false;
        if(active_row_arr.length>=optObj.rowNum+1){
          isChecked = active_row_arr[optObj.rowNum];
        }
        
        row_val = <input type="checkbox" value={isChecked ? "1" : ""} className={gridOpt.class_name_json.input_check} checked={isChecked}
          onChange={() => { }} style={{ width: 15, height: 15 }} />;
        td_className = "text-center";
      } else if (key == "idx_num") {
        td_className = "text-center";
      } else {
        if (gridOpt.td_align == "center") {
          td_className = "text-center";
        } else if (gridOpt.td_align == "right") {
          td_className = "text-right";
        }
      }
      if (isGrid) {
        row_val = getIsGridInputTdTag({
          "rowData": rowData,
          "idx": rowNum,
          "key": key,
          "value": row_val,
          "origin_val": origin_val,
          "xColumnArr": xColumnArr,
        });
      }

      if (strFunc.str_in_array(key, ["checkbox", "idx_num"]) == -1) {
        if (!isGrid) {
          if (xColumnArr.select_arr[key] != undefined) {
            for (let i = 0; i < xColumnArr.select_arr[key].length; i++) {
              let tmp_row_option = xColumnArr.select_arr[key][i];
              if (tmp_row_option["value"] == origin_val) {
                row_val = tmp_row_option["text"];
              }
            }
          }
        }
        row_val = myProps.customCellRenderer({
          "rowData": rowData,
          "idx": rowNum,
          "key": key,
          "value": row_val,
          "origin_val": origin_val,
        });
      }

      let col_td_div_style: any = {
        minWidth: tmp_col_width,
        maxWidth: tmp_col_width * 2,
      };
      if (gridOpt.is_fix_width) {
        col_td_div_style.width = tmp_col_width;
        col_td_div_style.maxWidth = tmp_col_width;
      }
      if (col_max_width_arr[show_idx_num]) {
        col_td_div_style.width = col_max_width_arr[show_idx_num];
      }

      show_idx_num++;

      return (
        <td key={idx} className={td_className} >
          <div className={gridOpt.class_name_json.td_div} style={col_td_div_style}>
            {row_val}
          </div>
        </td>
      );
    });
    return tdCols;
  };

  const getTbodyRows = () => {
    let tBodyRows: any = infoArr.map((rowData: any, rowNum: number) => {
      let trClassName = gridOpt.class_name_json.data_row_tr;
      if (active_row_arr[rowNum]) {
        trClassName = gridOpt.class_name_json.data_row_tr + " " + gridOpt.class_name_json.active_row;
      }
      let tdCols = getColTags({
        rowData: rowData,
        rowNum: rowNum,
      });
      return (
        <tr key={rowNum} className={trClassName} onClick={(e) => { rowOnClick(rowData, rowNum, e); }}>
          {tdCols}
        </tr>
      );
    });
    if (tBodyRows == "") {
      tBodyRows = (
        <tr>
          <td colSpan={99}>
            <div className={gridOpt.class_name_json.empty_con_div}>
              내용이 없습니다.
            </div>
          </td>
        </tr>
      );
    }

    return tBodyRows;
  };


  const tableStyle: any = {
    width: gridOpt.width
  };
  if (gridOpt.table_align == "center") {
    tableStyle["margin"] = "0 auto";
  }

  return (
    <div ref={listBodyRef} style={{ height: gridOpt.height, overflow: "auto" }}
      onScroll={() => {
        myProps.onscrollLeft(listBodyRef.current.scrollLeft);
      }} >
      <table style={tableStyle}>
        <tbody>
          {getTbodyRows()}
        </tbody>
      </table>
    </div>
  );
});

export default TableBodyArea;