import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from "@/lib/lyg/string";
import WriteArea from "./area/write";
import StuStudyUseArea from "./area/study_use";

function WriteTdAndStudyPopup(props: any) {
  let myProps = {
    closePopup: () => { },
    list: (inData: any) => { },
    daily_report: {},
    td_sort: "textbook",//textbook,subtextbook,amount, sub_amount
    ...props
  };

  const pop_wrap_ref=useRef<any>(null);
  const top_con_ref=useRef<any>(null);
  const study_con_ref=useRef<any>(null);
  const [daily_report, set_daily_report] = useState({
    a_ymd: DateFunc.get_date_format(new Date(), "Ymd"),
    a_seq: "",
    a_textbook_study: "",
    a_subtextbook_study: "",
    a_grade_score: 0,
    ...myProps.daily_report
  });

  useEffect(() => {
    
    let total_h=pop_wrap_ref.current.offsetHeight;
    let minus_h=top_con_ref.current.offsetHeight;
    study_con_ref.current.style.height=(total_h-minus_h-10)+"px";
  }, []);

  const select_daily_study_title = (inData: any) => {
    let opt_obj = {
      is_main: false,
      study_title: "",
      amount: 0,
      success_amount: 0,
      score: 0,
      ...inData
    };
    let change_daily_report = { ...daily_report };
    if (opt_obj["is_main"]) {
      change_daily_report["a_textbook_study"] = opt_obj["study_title"];
      change_daily_report["a_todo_amount"] = opt_obj["amount"];
      change_daily_report["a_todo_success_amount"] = opt_obj["success_amount"];
      change_daily_report["a_grade_score"] = opt_obj["score"];
    } else {
      change_daily_report["a_subtextbook_study"] = opt_obj["study_title"];
      change_daily_report["a_todo_sub_amount"] = opt_obj["amount"];
      change_daily_report["a_todo_sub_success_amount"] = opt_obj["success_amount"];
    }
    set_daily_report(change_daily_report);
  };

  const write_daily_report_by_ajax = () => {
    if (!confirm("저장 하시겠습니까?")) {
      return false;
    }

    let form_json_data: any = {
      "data_arr": [daily_report],
    };
    if (strFunc.is_empty(daily_report["a_seq"])) {
      form_json_data["is_default_val"] = "1";
    }

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/daily_report/write', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert(response.data["msg"]);
          myProps.list({});
          myProps.closePopup();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <div style={{position:"relative",height:"100%"}} ref={pop_wrap_ref}>
      <div ref={top_con_ref}>
        <h4 style={{ position: "relative" }}>
          <span className="ml-2">{daily_report.a_lecture_name}</span>
          <span className="ml-2">{daily_report.a_stu_name}</span>
          <span style={{ position: "absolute", right: 10 }}>
            <span className="ml-2">{daily_report.a_writer}</span>
            <span className="ml-2">{daily_report.a_date}</span>
          </span>
        </h4>
        <WriteArea
          daily_report={daily_report}
          set_daily_report={set_daily_report}
        ></WriteArea>
        <div className="text-center" >
          <button className="btn btn-gray" onClick={write_daily_report_by_ajax} >저장</button>
          <button className="btn btn-line-gray ml-1" onClick={() => { myProps.closePopup(); }}>닫기</button>
        </div>
      </div>
      <div ref={study_con_ref} style={{overflow:"auto"}}>
        <StuStudyUseArea
          stu_seq={daily_report.a_stu_seq}
          select_daily_study_title={select_daily_study_title}
          closePopup={myProps.closePopup}
        ></StuStudyUseArea>
      </div>
    </div>
  );
};
export default WriteTdAndStudyPopup;