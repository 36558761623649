import { useState, useRef, useEffect, useMemo, useCallback,forwardRef } from 'react';
import my_axios,{ get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FindStuPopup from "@/pages/comp/basic/push/popup/invite_user";
import ReceiverUserArea from "./area/receiver";
import PushListArea from "./area/push_list";

function SendPushPopupContent(props:any) {
  let myProps:any={
    "receive_user_seq_arr":[],
    closePopup:()=>{},
    callback:(inData:any)=>{},
    ...props
  };
  let user=useSelector((state:any) => state.user);
  const [receive_user_arr,set_receive_user_arr]=useState<any>([]);
  const [iam_info,set_iam_info]=useState<any>({});
  const ReceiverUserAreaRef=useRef<any>(null);
  const PushListAreaRef=useRef<any>(null);

  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"find",
    s_except_user_seq:[],
    title:"팝업",
    width:"700px",
    height:"65%",
  });

  useEffect(()=>{
    
    getIamInfo();
    getInitUserInfoData();
  },[user.user_seq]);

  const [sendPushData,setSendPushData]=useState({
    "a_sort":"msg",
    "a_title":"",
    "a_content":"",
  });

  const getIamInfo=()=>{
    if(strFunc.is_empty(user.user_seq)){
      alert("로그인 필요.");
      myProps.closePopup();
      return false;
    }
    let formJsonData={
      "s_pri_arr":[user.user_seq],
      "s_addon_comp_link":"1",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/list',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        if(response.data["data"]["info_arr"].length>0){
          let tmp_iam_info=response.data["data"]["info_arr"][0];
          set_iam_info(tmp_iam_info);
          if(PushListAreaRef.current){
            PushListAreaRef.current.set_iam_info(tmp_iam_info);
          }
        }else{
          alert("내정보를 얻어오지 못했습니다.");
          myProps.closePopup();
        }
      }else{
        alert(response.data["msg"]);
        myProps.closePopup();
      }
    });
  };

  const getInitUserInfoData=()=>{
    if(strFunc.is_empty(myProps.receive_user_seq_arr)){
      return false;
    }
    let formJsonData={
      "s_pri_arr":myProps.receive_user_seq_arr,
      "s_addon_comp_link":"1",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/list',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        if(response.data["data"]["info_arr"].length>0){
          set_receive_user_arr(response.data["data"]["info_arr"]);
          if(PushListAreaRef.current){
            PushListAreaRef.current.set_receive_user_arr(response.data["data"]["info_arr"]);
            setTimeout(()=>{
              PushListAreaRef.current.list({"now_page":"1"});
            },200);
          }
        }
      }else{
        alert(response.data["msg"]);
        myProps.closePopup();
      }
    });
  };

  const openFindStuPopup=()=>{
    let pop_title="유저 찾기";
    let s_except_user_seq:any=[];
    //s_except_user_seq.push(user.user_seq);
    for(let i=0;i<receive_user_arr.length;i++){
      s_except_user_seq.push(receive_user_arr[i]["a_seq"]);
    }
    
    setPopupData({
      ...popupData,
      "isOpen":true,
      "s_except_user_seq":s_except_user_seq,
      "title":pop_title,
      "sort":"find",
    });
  };

  const callbackFindUser=(inData:any)=>{
    if(ReceiverUserAreaRef.current){
      ReceiverUserAreaRef.current.setInitData({
        "receive_user_arr":[
          ...receive_user_arr,
          ...inData.info_arr
        ]
      });
    }
    set_receive_user_arr([
      ...receive_user_arr,
      ...inData.info_arr
    ]);
    if(PushListAreaRef.current){
      PushListAreaRef.current.set_receive_user_arr(inData.info_arr);
      setTimeout(()=>{
        PushListAreaRef.current.list({"now_page":"1"});
      },100);
    }
   };

  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }
    
    setSendPushData({
      ...sendPushData,
      ...{[name]: value}
    });
  };

  

  const sendPush=() => {
    if(sendPushData.a_title==""){
      alert("제목 입력이 필요합니다.");
      return false;
    }
    if(!confirm("푸시 전송을 하시겠습니까?")){
      return false;
    }

    let tmp_receive_user_arr:any=[];
    for(let i=0;i<receive_user_arr.length;i++){
      if(!strFunc.is_empty(receive_user_arr[i].comp_link_info)){
        tmp_receive_user_arr.push({
          "mcomp_seq":receive_user_arr[i].comp_link_info["a_mcomp_seq"],
          "mcomp_user_seq":receive_user_arr[i].comp_link_info["a_mcomp_user_seq"],
          "main_user_seq":receive_user_arr[i].comp_link_info["a_main_user_seq"],
        });
      }
    }
    if(tmp_receive_user_arr.length==0){
      alert("받는자 정보가 없습니다.");
      return false;
    }

    let formJsonData={
      "push_data":{
        "a_title":sendPushData.a_title,
        "a_content":sendPushData.a_content,
      },
      "receive_user_arr":tmp_receive_user_arr,
      "sender_name":user.user_name,
      "sender_mcomp_seq":user.comp_seq,
      "sender_u_seq":user.user_seq,
      "sender_main_user_seq":"",
    };
    if(!strFunc.is_empty(iam_info.comp_link_info)){
      formJsonData["sender_main_user_seq"]=iam_info.comp_link_info.a_main_user_seq;
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/main/basic/push/send_push',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert(response.data["msg"]);
        if(PushListAreaRef.current){
          PushListAreaRef.current.list({});
        }
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  return (
    <div>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="30%"></col>
            <col width="20%"></col>
            <col width="30%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>
                받는이
                <button className="btn btn-dark ml-1" onClick={()=>{openFindStuPopup();}} >찾기</button>
              </th>
              <td colSpan={3}>
                <ReceiverUserArea
                  ref={ReceiverUserAreaRef}
                  receive_user_arr={receive_user_arr}
                  set_receive_user_arr={(inData:any)=>{
                    set_receive_user_arr(inData);
                    if(PushListAreaRef.current){
                      PushListAreaRef.current.set_receive_user_arr(inData);
                      setTimeout(()=>{
                        PushListAreaRef.current.list({"now_page":"1"});
                      },100);
                    }
                  }}
                  push_list={(inData:any)=>{
                    if(PushListAreaRef.current){
                      setTimeout(()=>{
                        PushListAreaRef.current.list({"now_page":"1"});
                      },100);
                    }
                  }}
                ></ReceiverUserArea>
              </td>
            </tr>
            <tr>
              <th>제목</th>
              <td colSpan={2}>
                <input type="text" className="row-input" name="a_title" value={sendPushData.a_title} 
                  onChange={handleInputChange} placeholder="알림메시지 입력" />
              </td>
            </tr>
            <tr>
              <th>내용</th>
              <td colSpan={3}>
                <textarea className="row-input h-20" name="a_content" value={sendPushData.a_content} 
                  onChange={handleInputChange} placeholder="내용" ></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={()=>{sendPush();}}>보내기</button>
        <button className="btn btn-line-gray" onClick={()=>{myProps.closePopup();}}>닫기</button>
      </div>
      <PushListArea
        ref={PushListAreaRef}
        receive_user_arr={receive_user_arr}
      ></PushListArea>
      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} marginTop={"10%"} >
          {popupData.sort==="find"&&
            <FindStuPopup 
              callBackData={callbackFindUser}
              s_except_user_seq={popupData.s_except_user_seq}
              closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}>
            </FindStuPopup>
          }
      </LayerPopup>
      }
    </div>
  );
}

export default SendPushPopupContent;
