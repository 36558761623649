import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import SearchOrderRadio from "@/pcomponents/common/crud/write/radio/radio_arr";
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';
import SearchArea from '@/pages/comp/basic/student/list/area/search_area';

function FindMultyStudentPopup(props: any) {
  const myProps = {
    closePopup: () => { },
    listOpt: {},
    row_num: 0,
    key: "",
    callBackData: (data: any) => {
      let optObj = {
        info_arr: [],
        row_num: 0,
        key: "",
      };
    },//data = { info_arr:[],row_num:0,key:"" };
    is_show_en_class:false,
    ...props
  };
  let user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  if(myProps.is_show_en_class){
    for(let i=0;i<xColumnArr.x_column_list_arr.length;i++){
      if(xColumnArr.x_column_list_arr[i]["key"]=="row_view_en_class"){
        xColumnArr.x_column_list_arr[i]["is_show"]="1";
      }
    }
  }
  const listAggridRef = useRef<any>();
  const [pageData, setPageData] = useState({
    listOpt: {
      ...xColumnArr.list_opt,
      s_grade: ["student"],
      s_search_text_type:"name",
      s_search_text:"",
      s_stu_bookmark:strFunc.get_storage("s_stu_bookmark"),
      s_stu_lecture_seq:user.user_grade=="master"?"":strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
      s_addon_class_user: "1",
      s_addon_link_user: "1",
      s_except_user_seq: "",
      s_addon_counsel_todo_tot:"",
      s_addon_data:"1",
      ...myProps.listOpt
    }
  });
  //xcolumn 세팅
  
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const en_class_arr_ref=useRef([]);

  useEffect(() => {
    
    get_en_class_by_ajax();
  }, []);

  const setXcolumnArrByListOpt=()=>{
    if(myProps.listOpt["s_addon_counsel_todo_tot"]!="1"){
      return false;
    }
    let x_column_list_arr_len=xColumnArr.x_column_list_arr.length;
    for(let i=0;i<x_column_list_arr_len;i++){
      let x_column=xColumnArr.x_column_list_arr[i];
      if(x_column["key"]=="row_view_counsel_cnt"){
        if(myProps.listOpt["s_addon_counsel_todo_tot"]=="1"){
          xColumnArr.x_column_list_arr[i]["is_show"]="1";
        }
      }
    }
  };
  setXcolumnArrByListOpt();

  const list = (inOptObj: any) => {
    if (inOptObj != undefined) {
      let tmpPageData = pageData;
      inOptObj["sc"] = listAggridRef.current.getListSc();
      for (let key in tmpPageData.listOpt) {
        if (inOptObj[key] != undefined) {
          tmpPageData.listOpt[key] = inOptObj[key];
        }
      }
      setPageData({ ...tmpPageData });
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', pageData.listOpt,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const get_en_class_by_ajax = () => {
    let list_json_form_data = {
      "order_id": "a_order_num",
      "s_is_use": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/class/en_class/list', list_json_form_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          en_class_arr_ref.current=response.data["data"]["info_arr"];
        } else {

        }
      });
  };

  const goCallBackData = () => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }

    myProps.callBackData({
      info_arr: selected_row,
      row_num: myProps.row_num,
      key: myProps.key
    });
    myProps.closePopup();
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;
    if (key == "a_user_name" || key == "a_user_id") {
      //openOnePopup("view",true);
    }
  }, []);

  const cellRenderer = (params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;
    if (key == "row_view_class_name") {
      if (params.data.class_arr) {
        let class_name_arr = [];
        for (let i = 0; i < params.data.class_arr.length; i++) {
          class_name_arr.push(params.data.class_arr[i]["class_name"]);
        }
        render_str = class_name_arr.join(" / ");;
      }
    } else if (key == "row_view_par_phone") {
      let a_user_phone = strFunc.autoHypenPhone(params.data.a_user_phone);
      let par_phone_num = "학부모 미등록";
      if (params.data.link_user_arr && params.data.link_user_arr[0] && params.data.link_user_arr[0]["par_info"]) {
        par_phone_num = params.data.link_user_arr[0]["par_info"]["a_user_phone"];
      }
      render_str = (
        <div>
          <div className="leading-5">{par_phone_num}</div>
          <div className="leading-5">{a_user_phone}</div>
        </div>);
    } else if (key == "a_stu_num") {
      render_str = strFunc.getNumber2(render_str);
    }else if(key=="row_view_counsel_cnt"){
      if(params.data.ctodo_tot_info){
        let tot_span_style:any={
          display:"inline-block",
          textAlign:"center",
          lineHeight:"26px",
          height:"26px",
          padding:"0px 8px",
          background:"gray",
          color:"#fff",
          borderRadius:"5px"
        };
        if(params.data.ctodo_tot_info["tot"]>0){
          tot_span_style["background"]="#db6c58";
          if(params.data.ctodo_tot_info["success_tot"]>0){
            tot_span_style["background"]="#eab358";
            if(params.data.ctodo_tot_info["tot"]==params.data.ctodo_tot_info["success_tot"]){
              tot_span_style["background"]="#a2b854";
            }
          }
        }
        render_str=(
          <div style={tot_span_style}>
            {params.data.ctodo_tot_info["success_tot"]+"/"+params.data.ctodo_tot_info["tot"]}
          </div>
        );
      }
    }else if(key=="row_view_en_class"){
      if(!strFunc.is_empty(params.data.addon_data_info)){
        render_str=params.data.addon_data_info.a_en_class_seq;
        console.log(en_class_arr_ref.current);
        for(let i=0;i<en_class_arr_ref.current.length;i++){
          if(en_class_arr_ref.current[i]["a_seq"]==render_str){
            render_str=en_class_arr_ref.current[i]["a_name"];
          }
        }
      }
    }
    return render_str;
  };

  return (
    <div>
      <SearchArea
        listOpt={{ ...pageData.listOpt }}
        xColumnArr={{ ...xColumnArr }}
        list={list}
      ></SearchArea>
      <div className="btn-box-left relative h-8">
        <b>총 <span className="text-blue-600">{countInfo.tot}</span> 명</b>
        <span className="px-2">|</span>
        <SearchOrderRadio
          valueTextArr={[
            { "text": "등록순", "value": "a_create_date DESC" },
            { "text": "이름순", "value": "a_user_name" },
          ]}
          value={pageData.listOpt.order_id}
          handleInputChange={(e: any) => {
            list({
              "now_page": 1,
              "order_id": e.target.value
            });
          }}
        ></SearchOrderRadio>
        <div className="absolute right-0 top-0 btn-box-right">
          <button className="btn btn-dark" onClick={() => { goCallBackData(); }}>선택</button>
          <button className="btn btn-line-gray" onClick={() => { myProps.closePopup(); }}>닫기</button>
        </div>
      </div>
      <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{ ...xColumnArr }}
        list={list}
        gridOpt={{
          fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num: true,
          is_add_checkbox: true,
          floatingFilter: false,
          onGridReady: () => {
            list({});
          },
          onCellClicked: cellClickedListener,
          cellRenderer: cellRenderer,
        }}
      ></ListAggrid>
      <Paging now_page={pageData.listOpt.now_page}
        num_per_page={pageData.listOpt.num_per_page}
        total_rec={countInfo.tot}
        onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
        onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
    </div>
  );
}

export default FindMultyStudentPopup;