import CalendarArea from "./calendar";

function SelectDateArea(props:any){
   let myProps={
    select_date:"",
    select_date_arr:[],
    set_select_date_arr:()=>{},
    Style:{},
    ...props
  };
  let Style=myProps.Style;

  return (
  <div>
    <CalendarArea
      select_date={myProps.select_date}
      select_date_arr={myProps.select_date_arr}
      set_select_date_arr={myProps.set_select_date_arr}
    ></CalendarArea>
  </div>
  );
};
export default SelectDateArea;